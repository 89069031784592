import { Component, NgModule, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { UserPanelModule } from '../../user-panel/user-panel.component';
import { ApiService } from '../../../services/api.service';
import { DxButtonGroupModule, DxCheckBoxModule, DxFormComponent, DxFormModule, DxPopupModule, DxScrollViewModule, DxValidatorModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { AuthService } from '../../../services';
import { IOdjel } from 'src/app/shared/models/odjel.model';
import notify from 'devextreme/ui/notify';


@Component({
  selector: 'edit-odjel-form',
  templateUrl: 'edit-odjel-form.component.html',
  styleUrls: ['./edit-odjel-form.component.scss']
})

export class EditOdjelFormComponent implements OnInit {
  @ViewChild("editOdjelForm", { static: false }) editOdjelForm: DxFormComponent;

  @Input()
  odjelId!: number;
  @Input()
  isPopupVisible: boolean = false;

  @Output() openModalChange = new EventEmitter<boolean>();
  @Output() odjelUpdated = new EventEmitter<boolean>();

  noviOdjel: IOdjel;

  constructor(
    private _api: ApiService,
    private _authService: AuthService,
  ) {  }

  ngOnInit(): void {
    this.dohvatiOdjel();
  }

  closePopup(): void {
    this.openModalChange.emit(false);
  }
  openPopup(): void {
    this.openModalChange.emit(true);
  }

  form_fieldDataChanged (e) {
    let updatedField = e.dataField;
    let newValue = e.value;
  }

  onFormSubmit(e){
    e.preventDefault();
    this.submitForm();
  }

  submitForm(){
    this.urediOdjel();
  }

  onShown(e){
    this.editOdjelForm.instance.getEditor("od_oznaka").focus();
  }

  dohvatiOdjel(){
    this._api.dohvatiOdjel({
      'idodjel': this.odjelId,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      // console.log(res)
      if(res.success == true){
        const odjel: IOdjel = res.data[0];
        this.noviOdjel = odjel;
      }
    });
  }

  urediOdjel(){
    this._api.urediOdjel({
      'idodjel': this.odjelId,
      'oznaka': this.noviOdjel.od_oznaka,
      'naziv': this.noviOdjel.od_naziv,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res.success == true){
        notify("Uspješno ste uredili odjel!", "success", 1000);
        this.closePopup();
        this.odjelUpdated.emit(true);
      } else {
        notify("Greška prilikom uređivanja odjela!", "error", 1000);
      }
    });
  }

}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxPopupModule,
    DxFormModule,
    DxScrollViewModule,
    DxButtonGroupModule,
    DxCheckBoxModule,
    DxValidatorModule,
  ],
  declarations: [ EditOdjelFormComponent ],
  exports: [ EditOdjelFormComponent ]
})
export class EditOdjelFormComponentModule { }
