export interface IKontakt {
    idkontakt: number,
    ko_naziv: string,
    ko_adresa: string,
    ko_telefon: string,
    ko_mobitel: string,
    ko_email: string,
    kor_ime: string,
    ko_web: string,
    ko_lat: number,
    ko_lon: number,
}

export class Kontakt implements IKontakt {
    constructor(
        public idkontakt: number = null,
        public ko_naziv: string = '',
        public ko_adresa: string = '',
        public ko_telefon: string = '',
        public ko_mobitel: string = '',
        public ko_email: string = '',
        public kor_ime: string = '',
        public ko_web: string = '',
        public ko_lat: number = null,
        public ko_lon: number = null,
    ) {}
}
