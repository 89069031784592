<div class="ppp-page">

    <div class="ppp-page__menu-container">
        <dx-button *ngFor="let item of menuItems" stylingMode="contained" ion-button size="small"
            (click)="handlePPPMenuItemClickBtnClick(item)"
            class="ppp-page__menu-container__btn"
            [ngClass]="item.isActive 
            ? 'ppp-page__menu-container__btn--active' 
            : 'ppp-page__menu-container__btn--inactive'">
            {{item.type}}
        </dx-button>
    </div>

    <prijave-tablica *ngIf="prijave && selectedPPPMenuItem.type === 'PRIJAVE'" [prijave]="prijave"></prijave-tablica>
    <prijedlozi-tablica *ngIf="selectedPPPMenuItem.type === 'PRIJEDLOZI'" [prijedlozi]="prijedlozi"></prijedlozi-tablica>
    <pohvale-tablica *ngIf="selectedPPPMenuItem.type === 'POHVALE'" [pohvale]="pohvale"></pohvale-tablica>
      
</div>
