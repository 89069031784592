import { Component, NgModule, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { UserPanelModule } from '../../user-panel/user-panel.component';
import { ApiService } from '../../../services/api.service';
import { DxButtonGroupModule, DxCheckBoxModule, DxFormComponent, DxFormModule, DxMapModule, DxPopupModule, DxScrollViewModule, DxValidatorModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { AuthService } from '../../../services';
import notify from 'devextreme/ui/notify';
import { IKontakt, Kontakt } from 'src/app/shared/models/kontakt.model';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { GoogleMapComponentComponentModule } from '../../google-map-component/google-map-component.component';

@Component({
  selector: 'add-kontakt-form',
  templateUrl: 'add-kontakt-form.component.html',
  styleUrls: ['./add-kontakt-form.component.scss']
})

export class AddKontaktFormComponent implements OnInit {
  @ViewChild("editPrijavaForm", { static: false }) editPrijavaForm: DxFormComponent;
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  mapMarkers = [];
  keys={};
  
  @ViewChild("addKontaktForm", { static: false }) addKontaktForm: DxFormComponent

  @Input()
  isPopupVisible: boolean = false;
  @Input()
  partnerId!: number;
  
  @Output() openModalChange = new EventEmitter<boolean>();
  @Output() kontaktCreated = new EventEmitter<boolean>();
  
  kontakt: IKontakt = new Kontakt();

  constructor(
    private _api: ApiService,
    private _authService: AuthService,
  ) {
    this.keys["google"]="AIzaSyAInEYFG0_ykzrvxDU8Qmf7c39MRO_qXCk";
  }

  ngOnInit(): void {
    this.initKontaktValues();
  }

  initKontaktValues(){
    this.kontakt = new Kontakt();
  }

  refresh() {
    this.addKontaktForm.instance.resetValues();
  }

  closePopup(): void {
    this.refresh();
    this.initKontaktValues();
    this.openModalChange.emit(false);
  }

  openPopup(): void {
    this.openModalChange.emit(true);
  }

  form_fieldDataChanged (e) {
    let updatedField = e.dataField;
    let newValue = e.value;
  }

  onFormSubmit(e: any){
    e.preventDefault();
    this.submitForm();
  }

  submitForm(){
    this.dodajKontakt();
  }

  onShown(e){
    this.addKontaktForm.instance.getEditor("ko_naziv").focus();
  }

  dodajKontakt(){
    const partnerId = this._authService.getUserPartnerId();

    this._api.dodajKontakt({
      'naziv': this.kontakt.ko_naziv,
      'adresa': this.kontakt.ko_adresa,
      'telefon': this.kontakt.ko_telefon,
      'mobitel': this.kontakt.ko_mobitel,
      'mail': this.kontakt.ko_email,
      'web': this.kontakt.ko_web,
      'lat': this.kontakt.ko_lat,
      'lon': this.kontakt.ko_lon,
      'idpartner': partnerId,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res.success == true){
        notify("Uspješno ste dodali kontakt!", "success", 1000);
        this.closePopup();
        this.kontaktCreated.emit(true);
      } else {
        notify("Greška prilikom dodavanja kontakta!", "error", 1000);
      }
    });
  }

}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxPopupModule,
    DxFormModule,
    DxScrollViewModule,
    DxButtonGroupModule,
    DxCheckBoxModule,
    DxValidatorModule,
    DxMapModule,
    GoogleMapComponentComponentModule
  ],
  declarations: [ AddKontaktFormComponent ],
  exports: [ AddKontaktFormComponent ]
})
export class AddKontaktFormComponentModule { }
