import { CommonModule } from '@angular/common';
import { Component, NgModule, ViewChild, AfterViewInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import { DxTextBoxComponent, DxTextBoxModule } from 'devextreme-angular';

import { AuthService } from '../../../services';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements AfterViewInit {

  @ViewChild("inputName", { static: false }) inputName: DxTextBoxComponent;

  loading = false;
  formData: any = {
    email: '',
    sifra: ''
  };
  passwordMode: string = "password";
  password: string;

  constructor(
    private authService: AuthService, 
    private router: Router,
    public _api: ApiService
  ) {}

  async onSubmit(e) {
    e.preventDefault();  
    // this.loading = true;
    this.authService.logIn(this.formData.email, this.password);
  }

  ngAfterViewInit() {
    this.inputName.instance.focus();
  }

  onEditorEnterKey(e){
    e.component.getEditor('password').focus();
  }

  otkrijLozinku(){
    this.passwordMode = this.passwordMode === "text" ? "password" : "text";
  }

}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule,
    DxButtonModule,
    DxTextBoxModule
  ],
  declarations: [ LoginFormComponent ],
  exports: [ LoginFormComponent ]
})
export class LoginFormModule { }