import { Component, NgModule, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { UserPanelModule } from '../../user-panel/user-panel.component';
import { ApiService } from '../../../services/api.service';
import { DxButtonGroupModule, DxCheckBoxModule, DxFormComponent, DxFormModule, DxMapModule, DxPopupModule, DxScrollViewModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import notify from 'devextreme/ui/notify';
import { AuthService } from '../../../services';
import { IPPP } from 'src/app/shared/models/ppp.model';

@Component({
  selector: 'edit-pohvala-form',
  templateUrl: 'edit-pohvala-form.component.html',
  styleUrls: ['./edit-pohvala-form.component.scss']
})

export class EditPohvalaFormComponent implements OnInit {
  @ViewChild("editPohvalaForm", { static: false }) editPohvalaForm: DxFormComponent;
  
  @Input()
  pohvalaId!: number;
  @Input()
  isPopupVisible: boolean = false;

  @Output() openModalChange = new EventEmitter<boolean>();
  @Output() pohvalaUpdated = new EventEmitter<boolean>();

  novaPohvala: IPPP;

  constructor(
    private _api: ApiService,
    private _authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.dohvatiPohvalu();
  }

  closePopup(): void {
    this.openModalChange.emit(false);
  }
  openPopup(): void {
    this.openModalChange.emit(true);
  }

  form_fieldDataChanged (e) {
    let updatedField = e.dataField;
    let newValue = e.value;
  }

  submitForm(){
    this.urediPohvalu();
  }

  onShown(e){
    this.editPohvalaForm.instance.getEditor("pr_naziv").focus();
  }

  dohvatiPohvalu(){
    this._api.dohvatiJedan3P({
      'idprijava': this.pohvalaId,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      // console.log(res)
      if(res.success == true){
        this.novaPohvala = res.data[0];
      }
    });
  }

  urediPohvalu(){
    this._api.uredi3P({
      'idprijava': this.novaPohvala.idprijava,
      'naziv': this.novaPohvala.pr_naziv,
      'opis': this.novaPohvala.pr_opis,
      'adresa': this.novaPohvala.pr_adresa,
      'lat': this.novaPohvala.pr_lat,
      'lon': this.novaPohvala.pr_lon,
      'status': this.novaPohvala.pr_status,
      'blokiraj': this.novaPohvala.blokiran,
      'idgost': this.novaPohvala.gost_idgost,
      'idkorisnik': this._authService.getUserId(),
      // 'slikaMob': this.novaPohvala.slika,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res.success == true){
        notify("Uspješno ste uredili pohvalu!", "success", 1000);
        this.closePopup();
        this.pohvalaUpdated.emit(true);
      } else {
        notify("Greška prilikom uređivanja pohvale!", "error", 1000);
      }
    });
  }

}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxPopupModule,
    DxFormModule,
    DxScrollViewModule,
    DxButtonGroupModule,
    DxCheckBoxModule,
    DxMapModule
  ],
  declarations: [ EditPohvalaFormComponent ],
  exports: [ EditPohvalaFormComponent ]
})
export class EditPohvalaFormComponentModule { }
