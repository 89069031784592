export enum CRUDAction{
    CREATE = "create",
    READ = "read",
    UPDATE = "update",
    DELETE = "delete",
}

export interface ISlika {
    idslikadogadjaj: number,
    sl_naziv: string,
    putanja: string,
}

export class Slika implements ISlika {
    constructor(
        public idslikadogadjaj: number,
        public sl_naziv: string,
        public putanja: string,
    ) {}
}

export class ImageSnippet{
  pending: boolean;
  status: string;
  constructor(public src:string, public file: File){}
}

export interface IUloga {
    id: number,
    naziv: string
}

export class Uloga implements IUloga {
    constructor(
        public id: number,
        public naziv: string
    ){}
}

export enum TipVrsteProblema {
    DEFAUL_VRSTA_PROBLEMA,
    VRSTA_PROBLEMA
}
