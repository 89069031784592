import { Component, HostBinding } from '@angular/core';
import { AuthService, ScreenService, AppInfoService } from './shared/services';
import { ApiService } from 'src/app/shared/services/api.service';
import { loadMessages, locale } from 'devextreme/localization';
import hr from '../app/localization/hr.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes).filter(cl => this.screen.sizes[cl]).join(' ');
  }

  constructor(
    private authService: AuthService,
    private screen: ScreenService,
    public appInfo: AppInfoService,
    public _api: ApiService
  ){
    loadMessages(hr);
    locale('hr');
  }

  isAuthenticated() {
    return this.authService.loggedIn;
  }

}