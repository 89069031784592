<div class="content-block korisnik-list-container">

  <div class="content-block" class="korisnik-list-container__content-container">
        <dx-data-grid
          class="korisnik-datagrid"
          [rowAlternationEnabled]="true"
          [hoverStateEnabled]="true"
          [dataSource]="korisnici"
          [wordWrapEnabled]="true"
          [showRowLines]="true"
          [showBorders]="false"
          [columnAutoWidth]="false"
          [columnHidingEnabled]="false"
          (onToolbarPreparing)="onToolbarPreparing($event)"
          [height]="824">

          <!-- <dxo-load-panel [enabled]="true"></dxo-load-panel> -->
          <dxo-header-filter [visible]="true"></dxo-header-filter>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-search-panel [visible]="true" [width]="240" placeholder="Pretraži..."></dxo-search-panel>

          
          <div *dxTemplate="let data of 'naslovTablice'">
            <div class="korisnik-list-container__header-content-container">
              <p class="korisnik-list-container__header-content-container__title">Korisnici: {{korisnici && korisnici.length}}</p>
              <dx-button
                icon="add" type="default"
                hint="Dodaj korisnika" stylingMode="contained" 
                (onClick)="dodajKorisnika()">
            </dx-button>
            </div>
          </div>

          <dxi-column dataField="idkorisnik" [visible]="false"></dxi-column>
          <dxi-column [calculateCellValue]="fullName_calculateCellValue" caption="Ime i prezime" [allowFiltering]="true"></dxi-column>
          <dxi-column dataField="od_naziv" caption="Odjel"></dxi-column>
          <dxi-column dataField="kor_email" caption="Mail" cellTemplate="mailTemplate">
            <div *dxTemplate="let data of 'mailTemplate'">
              <a href="mailto:{{data.value}}" style="text-decoration: none;">{{data.value}}</a>
            </div>
          </dxi-column>
          <dxi-column dataField="kor_lozinka" caption="Lozinka" [visible]="false"></dxi-column>
          <dxi-column dataField="kor_telefon" caption="Telefon"></dxi-column>
          <dxi-column [calculateCellValue]="korPrava_calculateCellValue" caption="Prava" [allowFiltering]="true"></dxi-column>
          <dxi-column alignment="center" [calculateCellValue]="status_calculateCellValue" caption="Status" [allowFiltering]="true"></dxi-column>

          <dxi-column alignment="center" cellTemplate="editTemplate" class="korisnik-datagrid__actions-row"></dxi-column>
            <div *dxTemplate="let row of 'editTemplate'" class="korisnik-datagrid__actions-row__actions">
              <dx-button (onClick)="urediKorisnika(row)" icon="edit" stylingMode="contained" type="default"></dx-button>
              <dx-button *ngIf="row.data.kor_aktivan === 1" hint="Deaktiviraj korisnika" (onClick)="onActivateDeactivateClick(row.data.idkorisnik, false)" icon="check" stylingMode="contained" type="success"></dx-button>
              <dx-button *ngIf="row.data.kor_aktivan === 0" hint="Aktiviraj korisnika" (onClick)="onActivateDeactivateClick(row.data.idkorisnik, true)" icon="close" stylingMode="contained" type="danger"></dx-button>
            </div>

          <dxo-paging [pageSize]="15"> </dxo-paging>
          <dxo-pager
            [visible]="true"
            [allowedPageSizes]="[15, 30, 50]"
            [displayMode]="'full'"
            [showPageSizeSelector]="true"
            [showInfo]="true"
            [showNavigationButtons]="true">
          </dxo-pager>
        </dx-data-grid>
      </div>

</div>

<add-korisnik-form
    [partnerId]="partnerId"
    [isPopupVisible]="isAddKorisnikFormVisible"
    (openModalChange)="onAddKorisnikPopupChange($event)"
    (korisnikCreated)="onKorisniciChange($event)">
</add-korisnik-form>

<edit-korisnik-form
    *ngIf="isEditKorisnikFormVisible && odabraniKorisnik && odabraniKorisnik.idkorisnik"
    [partnerId]="partnerId"
    [korisnikId]="odabraniKorisnik.idkorisnik"
    [isPopupVisible]="isEditKorisnikFormVisible"
    (openModalChange)="onEditKorisnikPopupChange($event)"
    (korisnikUpdated)="onKorisniciChange($event)">
</edit-korisnik-form>
