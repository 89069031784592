export interface IOdjel {
    idodjel: number,
    od_oznaka: string,
    od_naziv: string,
}

export class Odjel implements IOdjel {
    constructor(
        public idodjel: number = null,
        public od_oznaka: string = '',
        public od_naziv: string = '',
    ) {}
}
