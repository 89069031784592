import * as moment from "moment";
import { ISlika } from "./common.model";

export interface IDogadjaj {
    iddogadjaj: number,
    do_naslov: string,
    do_opis: string,
    do_datum: string,
    do_web: string,
    do_aktivan: number,
    kor_ime: string,
    slike: ISlika[],
}

export class Dogadjaj implements IDogadjaj {
    constructor(
        public iddogadjaj: number = null,
        public do_naslov: string = '',
        public do_opis: string = '',
        public do_datum: string = moment().toString(),
        public do_web: string = '',
        public do_aktivan: number = 1,
        public kor_ime: string = '',
        public slike: ISlika[] = [],
    ) {}
}
