import { Component, Input, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthService } from '../../../services';
import { ApiService } from '../../../services/api.service';
import { IPPP, PPPVrsta } from '../../../models/ppp.model';

import { DxButtonModule, DxCheckBoxModule, DxDataGridModule } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';

import { KorisnikType } from 'src/app/shared/models/korisnik.model';
import { AddPrijedlogFormComponentModule } from '../../forme/add-prijedlog-form/add-prijedlog-form.component';
import { EditPrijedlogFormComponentModule } from '../../forme/edit-prijedlog-form/edit-prijedlog-form.component';


@Component({
  selector: 'prijedlozi-tablica',
  templateUrl: 'prijedlozi-tablica.component.html',
  styleUrls: ['./prijedlozi-tablica.component.scss']
})

export class PrijedloziTablicaComponent implements OnInit {
  @Input()
  prijedlozi!: IPPP[];
  odabraniPrijedlogId: number;

  isAddPrijedlogFormVisible: boolean = false;
  isEditPrijedlogFormVisible: boolean = false;

  userType: any;

  constructor(
    private _authService: AuthService,
    private _api: ApiService,
  ) {
    this.userType = this._authService.getUserType();
  }

  ngOnInit() {
    this.dohvatiPrijedloge();
  }

  onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift({
    location: 'before',
    template: 'naslovTablice',    
      }
    )
  }

  dodajPrijedlog(){
    this.isAddPrijedlogFormVisible = true;
  }

  onPrijedlogChange(isChanged){
    if(isChanged) this.dohvatiPrijedloge();
  }

  onAddPrijedlogPopupChange(isVisible: boolean){
    this.isAddPrijedlogFormVisible = isVisible;
  }

  onEditPrijedlogPopupChange(isVisible: boolean){
    this.isEditPrijedlogFormVisible = isVisible;
    this.dohvatiPrijedloge();
  }

  urediPrijedlog(row){
    const prijedlog: IPPP = row.data; 
    this.odabraniPrijedlogId = prijedlog.idprijava;
    this.isEditPrijedlogFormVisible = true;
  }

  dohvatiPrijedloge(){
    this._api.dohvati3P(this.generatePrijaveRequest()).subscribe(res=>{
      if(res){
        const ppp = res.data;
        this.initPPPTypes(ppp);
      } else {
        notify("Greška prilikom dohvata prijedloga!", "error", 1000);
      }
    });
  }

  initPPPTypes(pppList: IPPP []){
    this.prijedlozi = [];
    pppList.map(ppp => {
      switch(ppp.pr_vrsta){
        case PPPVrsta.PRIJEDLOG:
          this.prijedlozi.push(ppp);
          break;
      }
    });
  }

  generatePrijaveRequest(){
    const userId = this._authService.getUserId();
    const userToken = this._authService.getToken();
    const userType: KorisnikType = this._authService.getUserType();
    const partnerId = this._authService.getUserPartnerId();
    let requestData: any;

    if(userType === KorisnikType.NACELNIK){
      return requestData = {
        'idgost': false,
        'idpartner': partnerId,
        'access_token': userToken
      }
    }
    else{
      return requestData = {
        'idgost': false,
        'idpartner': partnerId,
        'idkorisnik': userId,
        'access_token': userToken
      }
    }
  }

  odobri(row){
    this._api.urediVidljivost({
      'idprijava': row.data.idprijava,
      'vidljiv': row.value,
      'access_token': this._authService.getToken()
    }).subscribe(res=>{
      if(res){
        notify(res.message, "success", 1000);
      } else {
        notify("Greška!", "error", 1000);
      }
    });
  }

  obrisiPrijedlog(row){
    const userToken = this._authService.getToken();
    let myDialog = custom({
        title: "BRISANJE",
        messageHtml: `<b>Jeste li sigurni da želite obrisati prijedlog?</b>`,
        buttons: [{
            text: "DA",
            onClick: (e) => {
                return { buttonText: e.component.option("text") }
            }
        },{
          text: "ODUSTANI",
          onClick: (e) => {
              return { buttonText: e.component.option("text") }
          }
      }]
    });
    myDialog.show().then((dialogResult) => {
      if(dialogResult.buttonText === 'DA'){
        this._api.obrisi3P({
          'idprijava': row.data.idprijava,
          'access_token': userToken
        }).subscribe(res=>{
          if(res){
            this.dohvatiPrijedloge();
          } else {
            notify(res.message, "error", 1000);
          }
        });
      } 
   });
  }

}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    DxCheckBoxModule,
    AddPrijedlogFormComponentModule,
    EditPrijedlogFormComponentModule
  ],
  declarations: [ PrijedloziTablicaComponent ],
  exports: [ PrijedloziTablicaComponent ]
})
export class PrijedloziTablicaComponentModule { }
