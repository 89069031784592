<dx-popup
    title="DODAJ PRIJEDLOG"
    [(visible)]="isPopupVisible" 
    [closeOnOutsideClick]="true"
    [showCloseButton]="true"
    (onHiding)="closePopup()"
    [height]="500"
    [width]="680"
    (onShown)="onShown($event)">


        <form (submit)="onFormSubmit($event)">

            <dx-form #addPrijedlogForm
                *ngIf="prijedlog"
                id="add-prijedlog-form"
                class="add-prijedlog-form"
                [(formData)]="prijedlog"
                [readOnly]="false"
                [showColonAfterLabel]="true"
                (onFieldDataChanged)="form_fieldDataChanged($event)"
                [colCount]="2"
                [showValidationSummary]="true"
                validationGroup="PrijedlogData">

                

                <!-- Row 1 -->
                <dxi-item itemType="group" [colSpan]="2">
                    
                    <dxi-item dataField="pr_naziv" [label]="{text: 'Naslov'}" editorType="dxTextBox">
                        <dxi-validation-rule type="required" message="Naslov je nužan."></dxi-validation-rule>
                        <dxi-validation-rule type="stringLength" [min]="5" message="Naslov mora sadržavati minimalno 5 znakova"></dxi-validation-rule>
                    </dxi-item>

                    
                    <dxi-item dataField="pr_opis" [label]="{text: 'Opis'}" editorType="dxTextArea" [editorOptions]="{height: 150}">
                        <dxi-validation-rule type="required" message="Opis je nužan."></dxi-validation-rule>
                        <dxi-validation-rule type="stringLength" [min]="15" message="Opis mora sadržavati minimalno 15 znakova"></dxi-validation-rule>
                    </dxi-item>

                </dxi-item>

            </dx-form>
            
            <dxi-item itemType="group" [colSpan]="2" class="add-prijedlog-form__button-group">
                <dx-button
                    text="Spremi"
                    type="success"
                    validationGroup="addPrijedlogData"
                    [useSubmitBehavior]="true">
                </dx-button>
                <dx-button
                    text="Odustani"
                    type="danger"
                    (onClick)="closePopup()">
                </dx-button>
            </dxi-item>
        
        </form>   


</dx-popup>