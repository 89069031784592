<div class="dx-card uredjaji-tablica">

  <div class="content-block uredjaji-tablica-container">

    <p>Tablica uredjaja</p>

    <!-- <dx-data-grid
      id="uredjaji-datagrid"
      class="uredjaji-datagrid"
      [rowAlternationEnabled]="true"
      [hoverStateEnabled]="true"
      [dataSource]="uredjaji"
      keyExpr="idkontakt"
      [wordWrapEnabled]="true"
      [showRowLines]="true"
      [showBorders]="false"
      [columnAutoWidth]="false"
      [columnHidingEnabled]="false"
      (onToolbarPreparing)="onToolbarPreparing($event)">

      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-search-panel [visible]="true" [width]="240" placeholder="Pretraži..."></dxo-search-panel>
      
      <div *dxTemplate="let data of 'naslovTablice'">
        <div class="uredjaji-tablica-container__header-content-container">
          <p class="uredjaji-tablica-container__header-content-container__title">Popis uredjaja: {{uredjaji && uredjaji.length}}</p>
        </div>
      </div>

      <dxi-column dataField="idkontakt" [visible]="false"></dxi-column>
      <dxi-column dataField="ko_naziv" caption="UUID uređaja"></dxi-column>
      <dxi-column dataField="ko_adresa" caption="Naziv"></dxi-column>
      <dxi-column dataField="ko_telefon" caption="Telefon"></dxi-column>
      <dxi-column dataField="ko_mobitel" caption="Mobitel"></dxi-column>
      <dxi-column dataField="ko_email" caption="Mail"></dxi-column>
      <dxi-column dataField="do_web" caption="Web"></dxi-column>

      <dxi-column alignment="center" cellTemplate="editTemplate" class="uredjaji-datagrid__actions-row"></dxi-column>
        <div *dxTemplate="let row of 'editTemplate'" class="uredjaji-datagrid__actions-row__actions">
          <dx-button (onClick)="urediKontakt(row)" icon="edit" stylingMode="contained" type="default"></dx-button>
        </div>

      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[10, 15, 30]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true">
      </dxo-pager>
    </dx-data-grid> -->

</div>

</div>