<dx-popup
    title="UREDI POHVALU"
    [(visible)]="isPopupVisible" 
    [closeOnOutsideClick]="true"
    [showCloseButton]="true"
    (onHiding)="closePopup()"
    [height]="500"
    [width]="680"
    (onShown)="onShown($event)">

    <dx-scroll-view width="100%" height="100%">

        <dx-form
            #editPohvalaForm
            *ngIf="novaPohvala"
            id="edit-pohvala-form"
            class="edit-pohvala-form"
            [(formData)]="novaPohvala"
            [showColonAfterLabel]="true"
            (onFieldDataChanged)="form_fieldDataChanged($event)"
            [colCount]="2">

            <!-- Row 1 -->
            <dxi-item itemType="group" [colSpan]="2">
                    
                <dxi-item dataField="pr_naziv" [label]="{text: 'Naslov'}" editorType="dxTextBox">
                    <dxi-validation-rule type="required" message="Naslov je nužan."></dxi-validation-rule>
                    <dxi-validation-rule type="stringLength" [min]="5" message="Naslov mora sadržavati minimalno 5 znakova"></dxi-validation-rule>
                </dxi-item>

                
                <dxi-item dataField="pr_opis" [label]="{text: 'Opis'}" editorType="dxTextArea" [editorOptions]="{height: 150}">
                    <dxi-validation-rule type="required" message="Opis je nužan."></dxi-validation-rule>
                    <dxi-validation-rule type="stringLength" [min]="15" message="Opis mora sadržavati minimalno 15 znakova"></dxi-validation-rule>
                </dxi-item>

            </dxi-item>

        </dx-form>
        
        <dxi-item [colSpan]="2" class="edit-pohvala-form__button-group">
            <dx-button
                text="Spremi"
                type="success"
                (onClick)="submitForm()">
            </dx-button>
            <dx-button
                text="Odustani"
                type="danger"
                (onClick)="closePopup()">
            </dx-button>
        </dxi-item>
        
    </dx-scroll-view>

</dx-popup>