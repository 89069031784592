import { Component, NgModule, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { UserPanelModule } from '../../user-panel/user-panel.component';
import { ApiService } from '../../../services/api.service';
import { DxButtonGroupModule, DxDataGridModule, DxDropDownBoxModule, DxFormComponent, DxFormModule, DxPopupModule, DxScrollViewModule, DxTreeViewComponent, DxTreeViewModule, DxValidatorModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { IVrstaProblema, VrstaProblema } from '../../../models/vrste-problema.model';
import { AuthService } from '../../../services';
import notify from 'devextreme/ui/notify';
import { ZaduzeneOsobeTablicaComponentModule } from '../../tablice/zaduzene-osobe-tablica/zaduzene-osobe-tablica.component';
import { IKorisnik } from 'src/app/shared/models/korisnik.model';
import { HttpClient } from '@angular/common/http';
import { TipVrsteProblema } from 'src/app/shared/models/common.model';

@Component({
  selector: 'edit-vrste-problema-form',
  templateUrl: 'edit-vrste-problema-form.component.html',
  styleUrls: ['./edit-vrste-problema-form.component.scss']
})

export class EditDefaultVrsteProblemaFormComponent implements OnInit {
  @ViewChild("editVrstaProblemaForm", { static: false }) editVrstaProblemaForm: DxFormComponent;

  @Input()
  type!: TipVrsteProblema;
  @Input()
  vrstaProblemaId!: number;
  @Input()
  isPopupVisible!: boolean;

  @Output() openModalChange = new EventEmitter<boolean>();
  @Output() vrstaProblemaUpdated = new EventEmitter<boolean>();

  title: string;
  novaVrstaProblema: IVrstaProblema;
  zaduzeneOsobeIds: number[] = [];


  constructor(
    private _api: ApiService,
    private _authService: AuthService,
    http: HttpClient
  ) { 
  }

  ngOnInit(): void {
    const problemString: string = this.type === TipVrsteProblema.VRSTA_PROBLEMA ? 'VRSTU' : 'DEFAULT VRSTU';  
    this.title = `UREDI ${problemString} PROBLEMA`;
    this.dohvatiJednuVrstuProblema();
  }

  dohvatiJednuVrstuProblema(){
    if(this.type === TipVrsteProblema.DEFAUL_VRSTA_PROBLEMA){
      this.apiDohvatiJednuDefaultVrstuProblema();
    }
    else{
      this.apiDohvatiJednuVrstuProblema();
    }
  }

  apiDohvatiJednuVrstuProblema(){
    this._api.dohvatiJednuVrstuProblema({
      'idproblem': this.vrstaProblemaId,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res.success == true){
        this.novaVrstaProblema = res.data[0];
        this.zaduzeneOsobeIds = this.novaVrstaProblema.zaduzeni.map(osoba => osoba.idkorisnik);
      } else {
        notify("Greška prilikom dohvata korisnika.", "error", 1000);
      }
    });
  }

  apiDohvatiJednuDefaultVrstuProblema(){
    this._api.dohvatiJednuDefaultVrstuProblema({
      'idproblem': this.vrstaProblemaId,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res.success == true){
      console.log(res)
      this.novaVrstaProblema = res.data;
      console.log(this.novaVrstaProblema)
      } else {
      notify("Greška prilikom dohvata korisnika.", "error", 1000);
    }
  });
}

  onFormSubmit(e: any){
    e.preventDefault();
    this.submitForm();
  }

  closePopup(): void {
    this.openModalChange.emit(false);
  }
  openPopup(): void {
    this.openModalChange.emit(true);
  }

  form_fieldDataChanged(e) {
    let updatedField = e.dataField;
    let newValue = e.value;
  }

  submitForm(){
    this.urediDefaultVrstuProblema();
  }

  onShown(e){
    this.editVrstaProblemaForm.instance.getEditor("pr_naziv").focus();
  }

  urediDefaultVrstuProblema(){
    if(this.type === TipVrsteProblema.DEFAUL_VRSTA_PROBLEMA){
      this.apiUrediDefaultVrstuProblema();
    }
    else{
      this.apiUrediVrstuProblema();
    }
  }

  apiUrediDefaultVrstuProblema(){
    this._api.urediDefaultVrstuProblema({
      'idproblem': this.novaVrstaProblema.idproblem,
      'naziv': this.novaVrstaProblema.pr_naziv,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      // console.log(res)
      if(res.success == true){
        notify("Uspješno ste uredili default vrstu problema!", "success", 1000);
        this.closePopup();
        this.vrstaProblemaUpdated.emit(true);
      } else {
        notify("Greška prilikom uređivanja default vrste problema!", "error", 1000);
      }
    });
  }

  apiUrediVrstuProblema(){
    this._api.urediVrstuProblema({
      'idproblem': this.novaVrstaProblema.idproblem,
      'naziv': this.novaVrstaProblema.pr_naziv,
      // 'korisnici': [this._authService.getUserId()],
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      // console.log(res)
      if(res.success == true){
        notify("Uspješno ste uredili vrstu problema!", "success", 1000);
        this.closePopup();
        this.vrstaProblemaUpdated.emit(true);
      } else {
        notify("Greška prilikom uređivanja vrste problema!", "error", 1000);
      }
    });
  }

  onZaduzeneOsobeChange(changes){
    this.urediZaduzeneOsobe(changes);
  }

  isAdmin(){
    return this.type === TipVrsteProblema.DEFAUL_VRSTA_PROBLEMA;
  }

  urediZaduzeneOsobe(korisniciIDs){   
    this._api.urediVrstuProblema({
      'idproblem': this.novaVrstaProblema.idproblem,
      'naziv': this.novaVrstaProblema.pr_naziv,
      'korisnici': korisniciIDs,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res.success == true){
        notify("Uspješno ste uredili vrstu zadužene osobe!", "success", 1000);
        this.dohvatiJednuVrstuProblema();
        this.vrstaProblemaUpdated.emit(true);
      } else {
        notify("Greška prilikom uređivanja zaduženih osoba!", "error", 1000);
      }
    });
  }

}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxPopupModule,
    DxFormModule,
    DxScrollViewModule,
    DxButtonGroupModule,
    ZaduzeneOsobeTablicaComponentModule,
    DxDropDownBoxModule,
    DxTreeViewModule,
    DxDataGridModule,
    DxValidatorModule,
  ],
  declarations: [ EditDefaultVrsteProblemaFormComponent ],
  exports: [ EditDefaultVrsteProblemaFormComponent ]
})
export class EditDefaultVrsteProblemaFormModule { }
