<dx-popup
    title="UREDI DOGAĐANJE"
    [(visible)]="isPopupVisible" 
    [closeOnOutsideClick]="true"
    [showCloseButton]="true"
    (onHiding)="closePopup()"
    (onShown)="onShown($event)">

    <dx-scroll-view width="100%" height="100%">

        <form (submit)="onFormSubmit($event)">

            <dx-form
                #editDogadjanjeForm
                *ngIf="noviDogadjaj"
                id="edit-dogadjanje-form"
                class="edit-dogadjanje-form"
                [(formData)]="noviDogadjaj"
                [showColonAfterLabel]="true"
                (onFieldDataChanged)="form_fieldDataChanged($event)"
                [colCount]="1"
                [showValidationSummary]="true"
                validationGroup="EditKontaktData">
                        
                <dxi-item itemType="group" [colCount]="3">
                    <dxi-item [colSpan]="1" [label]="{text: 'Datum'}" dataField="do_datum" editorType="dxDateBox" 
                        [editorOptions]="{type: 'date', displayFormat: 'dd.MM.yyyy.'}">
                        <dxi-validation-rule type="required" message="Datum je nužan.">
                        </dxi-validation-rule>
                    </dxi-item>
    
                    <dxi-item [colSpan]="2" [label]="{text: 'Naslov'}" dataField="do_naslov" editorType="dxTextBox">
                        <dxi-validation-rule type="required" message="Naslov je nužan."></dxi-validation-rule>
                        <dxi-validation-rule type="stringLength" [min]="5" message="Naslov mora sadržavati minimalno 5 znakova"></dxi-validation-rule>
                    </dxi-item>
                
                </dxi-item>
    
                <dxi-item [label]="{text: 'Opis'}" dataField="do_opis" editorType="dxTextArea" [editorOptions]="{height: 150}">
                    <dxi-validation-rule type="stringLength" [min]="15" message="Opis mora sadržavati minimalno 15 znakova"></dxi-validation-rule>
                </dxi-item>
    
                <dxi-item [label]="{text: 'Web'}" dataField="do_web" editorType="dxTextBox">
                </dxi-item>
    
                <dxi-item dataField="do_aktivan" [label]="{text: 'Aktivan'}" editorType="dxCheckBox"></dxi-item>
    
                <dxi-item>
                    <label class="image-upload-container btn btn-bwm">
                        <span class="boxSpan">Dodaj sliku: <i class="dx-icon-image dx-icon-custom-style"></i></span>
                        <input #imageInput
                               type="file"
                               accept="image/*"
                               multiple="multiple"
                               (change)="processFile(imageInput)">
                    </label>
                    <div class="img-container">
                        <div *ngIf="triSlike[0]" class="img-preview-container">
                          <div class="img-preview"
                              *ngFor="let slika of triSlike; let i = index"
                               [ngStyle]="{'background-image': 'url('+ slika + ')'}"
                               (click)="openImage()">
                               <i class="dx-icon-remove" style="float:right;cursor: pointer;font-size: 24px;" (click)="remove(slika,i)"></i>
                          </div>
                        </div>
                    </div>
                </dxi-item>
    
                
            </dx-form>
            
            <dxi-item class="edit-dogadjanje-form__button-group">
                <dx-button
                    text="Spremi"
                    type="success"
                    [useSubmitBehavior]="true"
                    validationGroup="EditKontaktData">
                </dx-button>
                <dx-button
                    text="Odustani"
                    type="danger"
                    (onClick)="closePopup()">
                </dx-button>
            </dxi-item>

        </form>


    </dx-scroll-view>

</dx-popup>