import { Component, ElementRef, EventEmitter, Input, NgModule, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleMap, GoogleMapsModule, MapMarker } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { UserPanelModule } from '../user-panel/user-panel.component';
import { DxButtonGroupModule, DxCheckBoxModule, DxFormModule, DxPopupModule, DxRadioGroupModule, DxScrollViewModule } from 'devextreme-angular';

import { EditPrijavaFormComponentModule } from '../forme/edit-prijava-form/edit-prijava-form.component';

import { LatLng } from 'ngx-google-places-autocomplete/objects/latLng';
declare let google: any;
@Component({
    selector: 'google-map-component',
    templateUrl: 'google-map-component.component.html',
    styleUrls: ['./google-map-component.component.scss']
  })

  export class GoogleMapComponentComponent implements OnInit{

    @Input() mapHeight: number;
    @Input() postavljeniLat;
    @Input() postavljeniLng;
    @Output() odabranaAdresaEvent = new EventEmitter<string>();
    @Output() odabraniLngEvent = new EventEmitter<number>();
    @Output() odabraniLatEvent = new EventEmitter<number>();

    zoom: number;
    koordinateIzTrenutneAdrese;
    noveKoordinate;
    odabranaAdresa: any;
    odabraniLat;
    odabraniLng;
    markerLat: number;
    markerLng: number;
    temp;
    initialCoordinates: any;

    @ViewChild('mapSearchField') searchField: ElementRef;
    @ViewChild(GoogleMap) map: GoogleMap;
    
    mapConfigurations = {};

    marker = {
        animation: google.maps.Animation.DROP,
        draggable: true,
    }
    
    constructor() {
        let _this = this;
        google.maps.event.addListener(this.marker, 'dragend', function (evt) {
            this.markerLat = evt.latLng.lat();
            this.markerLng = evt.latLng.lng();
            var temp = {lat: this.markerLat, lng: this.markerLng};
            updateMarkerPosition(temp, this.initialCoordinates);
        });
        
        function updateMarkerAddress(address): string{
            let markerAddress = address;
            return markerAddress;
        }

        function updateMarkerPosition(location, initialCoordinates){
            let markerCoordinates = {lat: location.lat, lng:location.lng }
            reverseGeocode(markerCoordinates, initialCoordinates)
        }

        function reverseGeocode(location, initialCoordinates){
            if (navigator.geolocation) {
                let geocoder = new google.maps.Geocoder();
                let latlng = new google.maps.LatLng(location.lat, location.lng);
                _this.odabraniLat = location.lat;
                _this.odabraniLng = location.lng;
                let request = { latLng: latlng };
                geocoder.geocode(request, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        let result = results[0].formatted_address;
                        updateMarkerAddress(result)
                        _this.initialCoordinates = location;
                        _this.odabranaAdresa = result;
                        console.log(_this.odabranaAdresa);
                    }
                });
            }
        }

        if(this.postavljeniLat == null && this.postavljeniLng == null){
            this.initialCoordinates = {
                lat: 45.475,
                lng: 16.78194,
            };
            this.zoom = 6;
        } else {
            this.initialCoordinates = this.koordinateIzTrenutneAdrese;
            this.zoom = 16;
        }

        this.koordinateIzTrenutneAdrese =  {
            lat: this.postavljeniLat,
            lng: this.postavljeniLng
        } 

    }

    ngOnInit(): void {
        this.zoom = 16;
        this.mapConfigurations = {
            disableDefaultUI: true,
            fullscreenControl: false,
            zoomControl: true,
            mapTypeControl:false,
            streetViewControl:false
        };
    }

    ngAfterViewInit(): void {
        if(this.postavljeniLat){
            this.initialCoordinates = {
                lat: this.postavljeniLat,
                lng: this.postavljeniLng,
            };
        }
        // console.log(this.initialCoordinates);
        const searchBox = new google.maps.places.SearchBox(
            this.searchField.nativeElement,
        );
        this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(
            this.searchField.nativeElement,
        );
        searchBox.addListener('places_changed', () => {
            const places = searchBox.getPlaces();
            this.odabranaAdresa = places[0].formatted_address;
            this.odabraniLat = places[0].geometry.location.lat();
            this.odabraniLng = places[0].geometry.location.lng();
            this.initialCoordinates = {
                lat: this.odabraniLat,
                lng: this.odabraniLng
            };
            if(places.length === 0){
                return;
            }
            const bounds = new google.maps.LatLngBounds();
            places.forEach(place => {
                if(!place.geometry || !place.geometry.location){
                    return;
                }
                if(place.geometry.viewport){
                    bounds.union(place.geometry.viewport);
                }
                else{
                    bounds.extend(place.geometry.location);
                }
            });
            this.map.fitBounds(bounds);
        });

    }

    posaljiAdresu(){
        this.odabranaAdresaEvent.emit(this.odabranaAdresa);
    }
    posaljiLat(){
        this.odabraniLatEvent.emit(this.odabraniLat);
    }
    posaljiLng(){
        this.odabraniLngEvent.emit(this.odabraniLng);
    }
    
}

  @NgModule({
    imports: [
      BrowserModule,
      CommonModule,
      DxButtonModule,
      UserPanelModule,
      DxToolbarModule,
      DxPopupModule,
      DxFormModule,
      DxScrollViewModule,
      DxButtonGroupModule,
      DxCheckBoxModule,
      DxRadioGroupModule,
      GoogleMapsModule,
      FormsModule,
    ],
    declarations: [ GoogleMapComponentComponent ],
    exports: [ GoogleMapComponentComponent ]
  })
  export class GoogleMapComponentComponentModule { }