import { Component, NgModule, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { UserPanelModule } from '../../user-panel/user-panel.component';
import { ApiService } from '../../../services/api.service';
import { DxButtonGroupModule, DxCheckBoxModule, DxFormComponent, DxFormModule, DxPopupModule, DxScrollViewModule, DxValidatorModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import notify from 'devextreme/ui/notify';
import { AuthService } from '../../../services';
import { IDogadjaj } from 'src/app/shared/models/dogadjaj.model';
import * as moment from 'moment';
import { ImageSnippet } from 'src/app/shared/models/common.model';


@Component({
  selector: 'edit-dogadjanje-form',
  templateUrl: 'edit-dogadjanje-form.component.html',
  styleUrls: ['./edit-dogadjanje-form.component.scss']
})

export class EditDogadjanjeFormComponent implements OnInit {
  @ViewChild("editDogadjanjeForm", { static: false }) editDogadjanjeForm: DxFormComponent;

  @Input()
  dogadjajId!: number;
  @Input()
  isPopupVisible: boolean = false;

  @Output() openModalChange = new EventEmitter<boolean>();
  @Output() dogadjajUpdated = new EventEmitter<boolean>();

  noviDogadjaj: IDogadjaj;

  popupImageVisible: boolean=false;
  slikeZaPrikaz: string[];
  slike: any=[];
  triSlike:any=[];
  noveSlike:any=[];
  obslike: any[]=[];
  slikeFiles: any=[];


  constructor(
    private _api: ApiService,
    private _authService: AuthService,
  ) {  }

  ngOnInit(): void {
    this.dohvatiDogadjaj();
  }

  closePopup(): void {
    this.openModalChange.emit(false);
  }
  openPopup(): void {
    this.openModalChange.emit(true);
  }

  form_fieldDataChanged (e) {
    let updatedField = e.dataField;
    let newValue = e.value;

    if(updatedField === "do_aktivan"){
      this.noviDogadjaj.do_aktivan = newValue ? 1 : 0;
    }
  }

  submitForm(){
    this.urediDogadjaj();
  }

  onFormSubmit(e){
    e.preventDefault();
    this.submitForm();
  }

  dohvatiDogadjaj(){
    this._api.dohvatiDogadjanje({
      'iddogadjaj': this.dogadjajId,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res){
        const dogadjaj: IDogadjaj = res.data[0];
        this.noviDogadjaj = dogadjaj;
        for(let i=0;i<res.data[0].slike.length;i++){
          this.triSlike[i]=res.data[0].slike[i].putanja
          this.slikeFiles=res.data[0].slike
        }
      }
    });
  }

  urediDogadjaj(){
    const request = this.buildRequestFormData();
    this._api.urediDogadjanje(request).subscribe(res=>{
      if(res.success == true){
        notify("Uspješno ste uredili događanje!", "success", 1000);
        this.closePopup();
        this.dogadjajUpdated.emit(true);
      } else {
        notify("Greška prilikom uređivanja događanja!", "error", 1000);
      }
    });
  }

  buildRequestFormData(){
    let formData:FormData = new FormData();
    formData.append('iddogadjaj', this.noviDogadjaj.iddogadjaj.toString());
    formData.append('datum', this.formatDate(this.noviDogadjaj.do_datum));
    formData.append('naslov', this.noviDogadjaj.do_naslov);
    formData.append('opis', this.noviDogadjaj.do_opis);
    formData.append('web', this.noviDogadjaj.do_web);
    formData.append('aktivan', this.noviDogadjaj.do_aktivan.toString());
    for(let i=0;i<this.obslike.length;i++){
      formData.append('obslike', this.obslike[i])
    }
    for(let i=0;i<this.noveSlike.length;i++){
      formData.append('datoteke', this.noveSlike[i])
    }
    formData.append('access_token', this._authService.getToken().toString());
    return formData;
  }

  formatDate(dateValue){
    const selectedDate = new Date(dateValue);
    const newDateFormated = moment(selectedDate).format('YYYY-MM-DD HH:mm:ss').toString();
    return newDateFormated;
  }

  processFile(imageInput: any) {
    for(let i=0;i<imageInput.files.length;i++){
      const file: File = imageInput.files[i];
      const reader = new FileReader();


      reader.addEventListener('load', (event: any) => {
        if(this.triSlike.length===3){
          notify("Možete odabrati samo tri fotografije!",'error')
        }else{
          this.triSlike.unshift(new ImageSnippet(event.target.result, file));
          this.noveSlike.push(file);
          this.triSlike[0]=this.triSlike[0].src;
        }
      });

      reader.readAsDataURL(file);
    }
  }

  openImage(){
    this.slike=[];
    this.popupImageVisible=true;
    for(let i=0;i<this.triSlike.length;i++){
      this.slike.push(this.triSlike[i])
      this.slike.join(",");
    }
    this.slikeZaPrikaz=this.slike;
  }

  remove(slika,i){
    for(let j=0;j<this.slikeFiles.length;j++){
      if(slika===this.slikeFiles[j].putanja){
        this.obslike.push(this.slikeFiles[j].idslikadogadjaj);
        this.slikeFiles.splice(j,1);
      }
    }
    this.noveSlike.splice(i,1);
    this.triSlike.splice(i,1);
  }

  onShown(e){
    this.editDogadjanjeForm.instance.getEditor("do_datum").focus();
  }

}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxPopupModule,
    DxFormModule,
    DxScrollViewModule,
    DxButtonGroupModule,
    DxCheckBoxModule,
    DxValidatorModule
  ],
  declarations: [ EditDogadjanjeFormComponent ],
  exports: [ EditDogadjanjeFormComponent ]
})
export class EditDogadjanjeFormComponentModule { }
