import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { IPPPInfo } from 'src/app/shared/models/ppp.model';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from 'src/app/shared/services';
import { ApiService } from 'src/app/shared/services/api.service';
import { MenuItemProps } from 'src/app/shared/models/menu.model';
import { DataService } from 'src/app/shared/services/data.service';
import { Subscription } from 'rxjs';
import { IDogadjaj } from 'src/app/shared/models/dogadjaj.model';
import moment from 'moment';
import { IObavijest } from 'src/app/shared/models/obavijesti.model';
import { custom } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-pocetna',
  templateUrl: './pocetna.component.html',
  styleUrls: ['./pocetna.component.scss']
})
export class PocetnaComponent implements OnInit, OnDestroy {

  pppInfo: IPPPInfo;
  public menuItems: MenuItemProps[];

  isPrijedlog: boolean;
  isPohvala: boolean;
  subscription: Subscription;
  subscription2: Subscription;

  notifikacija;

  dogadjanja: IDogadjaj[];
  odabraniDogadjajId;
  dogadjajPushan;

  obavijesti: IObavijest[];
  odabranaObavijestId;
  obavijestPushana;

  ponovnoTekst = "";

  constructor(
    private _api: ApiService,
    private _authService: AuthService,
    private router: Router,
    private data: DataService
  ) { }

  ngOnInit(): void {
    this.subscription = this.data.currentMessage.subscribe(isPrijedlog => {this.isPrijedlog = isPrijedlog})
    this.subscription2 = this.data.currentMessage2.subscribe(isPohvala => {this.isPohvala = isPohvala})
    this.dohvatiPPPInfo();
  }

  formatDateCellD(row){
    const date = moment(row.do_datum);
    return date.isValid() ? date.format('DD.MM.YYYY.') : "";
  }

  formatDateCellSentD(row){
    const date = moment(row.do_pushVrijeme);
    return date.isValid() ? date.format('DD.MM.YYYY. HH:mm') : "";
  }

  formatDateCellP(row){
    const date = moment(row.ob_datum);
    return date.isValid() ? date.format('DD.MM.YYYY.') : "";
  }

  formatDateCellSentP(row){
    const date = moment(row.ob_pushVrijeme);
    return date.isValid() ? date.format('DD.MM.YYYY. HH:mm') : "";
  }

  newMessage(){
    this.data.changeMessage(true);
    this.openPrijedlozi();
  }

  newMessage2(){
    this.data.changeMessage2(true);
    this.openPohvale();
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
    // this.subscription2.unsubscribe();
  }

  dohvatiPPPInfo(){
    this._api.dohvatiPPPInfo({
      'idkorisnik': this._authService.getUserId(),
      'idpartner': this._authService.getUserPartnerId(),
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res.success == true){
        this.pppInfo = res.data;
        this.dogadjanja = res.dogadjaji;
        this.obavijesti = res.obavijesti;
      }
      else{
        notify("Greška prilikom dohvata informacija!", "error", 1000);
      }
    });
  }

  openDogadjanjePush(row){
    const dogadjaj: IDogadjaj = row.data; 
    this.dogadjajPushan = row.data.do_pushan;
    this.odabraniDogadjajId = dogadjaj.iddogadjaj;
    this.notifikacija = 2;
    if(this.dogadjajPushan == 1){
      this.ponovnoTekst = "ponovno";
    }
    this.showDialog();
    this.ponovnoTekst = "";
  }

  openObavijestPush(row){
    const obavijest: IObavijest = row.data;
    this.odabranaObavijestId = obavijest.idobavijest;
    this.obavijestPushana = row.data.ob_pushana;
    this.notifikacija = 1;
    if(this.obavijestPushana == 1){
      this.ponovnoTekst = "ponovno";
    }
    this.showDialog();
    this.ponovnoTekst = "";
  }

  showDialog(){
    let myDialog = custom({
      title: "SLANJE NOTIFIKACIJE",
      messageHtml: `<b>Jeste li sigurni da želite ${this.ponovnoTekst} poslati notifikaciju?</b>`,
      buttons: [{
          text: "DA", 
          type: 'success',
          onClick: (e) => {
              return { buttonText: e.component.option("text") }
          },
          
      }, 
      {
        text: "NE",
        type: 'default',
        onClick: (e) => {
            return { buttonText: e.component.option("text") }
        }}
      ]
    });

  myDialog.show().then((dialogResult) => {
    const userToken = this._authService.getToken();

    let podaciZaSlanje;
    if(this.notifikacija == 1){
      podaciZaSlanje = {
        'id': this.odabranaObavijestId,
        'vrsta': this.notifikacija,
        'access_token': userToken
      }
    }
    else if(this.notifikacija == 2){
      podaciZaSlanje = {
        'id': this.odabraniDogadjajId,
        'vrsta': this.notifikacija,
        'access_token': userToken
      }
    }

    if(dialogResult.buttonText === "DA"){
      this._api.posaljiPush(podaciZaSlanje).subscribe(res=>{
        if(res.success == true){
          notify(res.message, "success", 1000);
          this.dohvatiPPPInfo();
        } else {
          notify("Greška prilikom slanja notifikacije", "error", 1000);
        }
      });
    }
  });
  }

  openPrijedlozi(){
    this.router.navigateByUrl('/ppp');
  }

  openPohvale(){
    this.router.navigateByUrl('/ppp');
  }

}