import { ModuleType } from "./shared/models/korisnik.model";
import { PPP } from "./shared/models/ppp.model";

export interface IMenuItem {
  text: string,
  path: string,
  moduleId?: ModuleType,
}

export const navigationAdministrator = [
  // {
  //   text: 'Početna',
  //   path: '/pocetna'
  // },
  {
    text: 'Partneri',
    path: '/partneri'
  },
  {
    text: 'Vrste problema',
    path: '/sifarnik-vrste-problema'
  }
];

export const navigationNacelnik: IMenuItem[] = [
  {
    text: 'Početna',
    path: '/pocetna',
  },
  {
    text: 'Prijave, pohvale i prijedlozi',
    path: '/ppp',
    moduleId: ModuleType.PPP,
  },
  {
    text: 'Obavijesti',
    path: '/obavijesti',
    moduleId: ModuleType.OBAVIJESTI,
  },
  {
    text: 'Događanja',
    path: '/dogadjanja',
    moduleId: ModuleType.DOGADJANJA,
  },
  {
    text: 'Kontakti',
    path: '/kontakti',
    moduleId: ModuleType.KONTAKTI,
  },
  {
    text: 'Odjeli',
    path: '/odjeli'
  },
  {
    text: 'Vrste problema',
    path: '/vrste-problema'
  },
  {
    text: 'Korisnici',
    path: '/korisnici'
  },
];

export const navigationZaposlenik = [
  {
    text: 'Početna',
    path: '/pocetna',
  },
  {
    text: 'Prijave, pohvale i prijedlozi',
    path: '/ppp',
  },
];

export const navigationVanjski = [
  {
    text: 'Obavijesti',
    path: '/obavijesti'
  },
];