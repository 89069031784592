import { Component, NgModule, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { UserPanelModule } from '../../user-panel/user-panel.component';
import { ApiService } from '../../../services/api.service';
import { DxButtonGroupModule, DxCheckBoxModule, DxDataGridModule, DxFormComponent, DxFormModule, DxPopupModule, DxScrollViewModule, DxValidatorModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { AuthService } from '../../../services';
import notify from 'devextreme/ui/notify';
import { IOdgovornaOsoba } from 'src/app/shared/models/odgovorna-osoba.model';
import { IKorisnik } from 'src/app/shared/models/korisnik.model';


interface IZaduzenaOsobaOpcija extends IOdgovornaOsoba{
  isSelected: boolean;
}

class ZaduzenaOsobaOpcija implements IZaduzenaOsobaOpcija{
  constructor(
    public idkorisnik: number,
    public kor_ime: string,
    public kor_prezime: string,
    public isSelected: boolean,
  ){}
}

@Component({
  selector: 'edit-zaduzenja-osoba-form',
  templateUrl: 'edit-zaduzenja-osoba-form.component.html',
  styleUrls: ['./edit-zaduzenja-osoba-form.component.scss']
})

export class EditZaduzenjaOsobaFormComponent implements OnInit {
  @ViewChild("editZaduzenjaOsobaForm", { static: false }) editZaduzenjaOsobaForm: DxFormComponent

  @Input()
  isPopupVisible: boolean = false;
  @Input()
  vrstaProblema!: number;
  @Input()
  zaduzeneOsobeIDs!: number[];

  
  @Output() openModalChange = new EventEmitter<boolean>();
  @Output() zaduzeneOsobeChange = new EventEmitter<number[]>();

  zaduzeneOsobeOpcije: IZaduzenaOsobaOpcija[] = [];
  
  constructor(
    private _api: ApiService,
    private _authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.dohvatiKorisnike();
  }

  dohvatiKorisnike(){
    this._api.dohvatiKorisnike({
      'idpartner': this._authService.getUserPartnerId(),
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      // console.log(res)
      if(res){
        const korisnici: IKorisnik[] = res.data;
        this.initZaduzeneOsobeOpcije(korisnici);
      } else {
        notify("Greška prilikom dohvata modula!", "error", 1000);
      }
    });
  }

  initZaduzeneOsobeOpcije(korisnici: IKorisnik[]){
    korisnici.map((korisnik: IKorisnik) => {
      const osobaJeVecZaduzena = this.zaduzeneOsobeIDs.includes(korisnik.idkorisnik);
      const isSelected = osobaJeVecZaduzena ? true : false
      this.zaduzeneOsobeOpcije.push(new ZaduzenaOsobaOpcija(korisnik.idkorisnik, korisnik.kor_ime,  korisnik.kor_prezime, isSelected))
    })
  }

  refresh() {
    this.editZaduzenjaOsobaForm.instance.resetValues();
  }

  closePopup(): void {
    this.refresh();
    this.openModalChange.emit(false);
  }

  openPopup(): void {
    this.openModalChange.emit(true);
  }

  form_fieldDataChanged (e) {
    let updatedField = e.dataField;
    let newValue = e.value;
  }

  onFormSubmit(e: any){
    e.preventDefault();
    this.submitForm();
  }

  submitForm(){
    const zaduzeneOsobeIds = this.zaduzeneOsobeOpcije.filter(osoba => osoba.isSelected).map(osoba => osoba.idkorisnik);
    this.zaduzeneOsobeChange.emit(zaduzeneOsobeIds);
    this.closePopup();
  }

}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxPopupModule,
    DxFormModule,
    DxScrollViewModule,
    DxButtonGroupModule,
    DxCheckBoxModule,
    DxValidatorModule,
    DxDataGridModule,
  ],
  declarations: [ EditZaduzenjaOsobaFormComponent ],
  exports: [ EditZaduzenjaOsobaFormComponent ]
})
export class EditZaduzenjaOsobaFormComponentModule { }
