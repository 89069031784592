<div class="content-block pocetna-page">

    <div *ngIf="pppInfo" class="pocetna-page__menu-container">
        <button [routerLink]="'/ppp'" style="border: none; background: transparent !important;">
            <div class="pocetna-page__menu-container__card dx-card responsive-paddings" style="background-color: #a2c4c9;">
                <h2 style="margin-top: -10px; margin-bottom:50px">Prijave</h2>
                <div class="pocetna-page__menu-container__card__content">
                    <table>
                        <tr style="font-size: 12px;">
                            <th><b>Otvorenih:</b></th>
                            <th><b>U postupku:</b></th>
                            <th><b>Riješenih:</b></th>
                        </tr>
                        <tr style="font-size: 50px;">
                        <td style="color: #ff0000;"><b>{{pppInfo.problOtvoren}}</b></td>
                        <td style="color: #ffff00;"><b>{{pppInfo.problPostupak}}</b></td>
                        <td style="color: #008000;"><b>{{pppInfo.problRijesen}}</b></td>
                        </tr>
                    </table>
                </div>
            </div>
        </button>

        <button (click)="newMessage()" style="border: none; background: transparent !important;">
            <div class="pocetna-page__menu-container__card dx-card responsive-paddings" style="background-color: #f7b34c; padding: 0;">
                <h2>Prijedlozi</h2>
                <p style="font-size: 50px;"><b>{{pppInfo.prijedlog}}</b></p>
            </div>
        </button>

        <button (click)="newMessage2()" style="border: none; background: transparent !important;">
            <div class="pocetna-page__menu-container__card dx-card responsive-paddings" style="background-color: #b6d7a8; padding: 0;">
                <h2>Pohvale</h2>
                <p style="font-size: 50px;"><b>{{pppInfo.pohvala}}</b></p>
            </div>
        </button>

    </div>

    <div class="pocetna-page__dogadjanja-table">
        <dx-data-grid
            id="dogadjanja-notifikacije"
            class="pocetna-page__dogadjanja-table__dogadjanja-notifikacije"
            [dataSource]="dogadjanja"
            [rowAlternationEnabled]="true"
            [hoverStateEnabled]="true"
            keyExpr="iddogadjaj"
            [showRowLines]="true"
            [columnAutoWidth]="false"
            [columnHidingEnabled]="false"
            [height]="260">

            <dxi-column dataField="do_datum" caption="Datum" [calculateCellValue]="formatDateCellD" [width]="90"></dxi-column>
            <dxi-column dataField="do_naslov" caption="Događanje"></dxi-column>
            <dxi-column dataField="ob_pushVrijeme" [calculateCellValue]="formatDateCellSentD" caption="Notifikacija poslana" [width]="180"></dxi-column>
            <dxi-column alignment="center" cellTemplate="editTemplate" class="obavijesti-datagrid__actions-row"></dxi-column>
                <div *dxTemplate="let row of 'editTemplate'" class="obavijesti-datagrid__actions-row__actions">
                    <dx-button (click)="openDogadjanjePush(row)" text="Pošalji notifikaciju" stylingMode="contained" type="default"></dx-button>
                </div>

            <dxo-paging [pageSize]="4"></dxo-paging>
            <dxo-pager
              [visible]="true"
              [allowedPageSizes]="[4, 8, 12]"
              [displayMode]="'full'"
              [showPageSizeSelector]="true"
              [showInfo]="true"
              [showNavigationButtons]="true">
            </dxo-pager>
        </dx-data-grid>
    </div>

    <div class="pocetna-page__obavijesti-table">
        <dx-data-grid
            id="obavijesti-notifikacije"
            class="pocetna-page__obavijesti-table__obavijesti-notifikacije"
            [dataSource]="obavijesti"
            [rowAlternationEnabled]="true"
            [hoverStateEnabled]="true"
            keyExpr="idobavijest"
            [showRowLines]="true"
            [columnAutoWidth]="false"
            [columnHidingEnabled]="false"
            [height]="260">

            <dxi-column dataField="ob_datum" caption="Datum" [calculateCellValue]="formatDateCellP" [width]="90"></dxi-column>
            <dxi-column dataField="ob_tekst" caption="Obavijest"></dxi-column>
            <dxi-column dataField="ob_pushVrijeme" [calculateCellValue]="formatDateCellSentP" caption="Notifikacija poslana" [width]="180"></dxi-column>
            <dxi-column alignment="center" cellTemplate="cellTemplate"></dxi-column>
                <div *dxTemplate="let row of 'cellTemplate'">
                    <dx-button (click)="openObavijestPush(row)" text="Pošalji notifikaciju" stylingMode="contained" type="default"></dx-button>
                </div>
            <dxo-paging [pageSize]="4"></dxo-paging>
            <dxo-pager
              [visible]="true"
              [allowedPageSizes]="[4, 8, 12]"
              [displayMode]="'full'"
              [showPageSizeSelector]="true"
              [showInfo]="true"
              [showNavigationButtons]="true">
            </dxo-pager>
        </dx-data-grid>
    </div>

</div>