export enum PPPVrsta{
    PRIJAVA = 1,
    PRIJEDLOG = 2,
    POHVALA = 3,
}

export interface IPPP {
    idprijava: number,
    pr_naziv: string,
    pr_opis: string,
    pr_adresa: string,
    pr_status: number,
    pr_lat: number,
    pr_lon: number,
    problem_idproblem: number,
    gost_idgost: number,
    pr_vrsta: PPPVrsta,
    pr_datum: string,
    pr_vrijemeizrade: string,
    vrpr_naziv: string,
    datoteka: File,
    blokiran: number,
    pr_prikaziMob: number
}

export class PPP implements IPPP {
    constructor(
        public idprijava: number,
        public pr_naziv: string,
        public pr_opis: string,
        public pr_adresa: string,
        public pr_status: number,
        public pr_lat: number,
        public pr_lon: number,
        public problem_idproblem: number,
        public gost_idgost: number,
        public pr_vrsta: number,
        public pr_datum: string,
        public pr_vrijemeizrade: string,
        public vrpr_naziv: string,
        public datoteka: File,
        public blokiran: number,
        public pr_prikaziMob: number
    ) {}
}

export interface IPPPInfo {
    problOtvoren: number,
    problPostupak: number,
    problRijesen: number,
    prijedlog: number,
    pohvala: number,
}

export class PPPInfo implements IPPPInfo {
    constructor(
        public problOtvoren: number,
        public problPostupak: number,
        public problRijesen: number,
        public prijedlog: number,
        public pohvala: number,
    ) {}
}

export class PPPPrijava implements IPPP {
    constructor(
        public idprijava: number = null,
        public pr_naziv: string = '',
        public pr_opis: string = '',
        public pr_adresa: string = '',
        public pr_status: number = 0,
        public pr_lat: number = null,
        public pr_lon: number  = null,
        public problem_idproblem: number = null,
        public gost_idgost: number = null,
        public pr_vrsta: number = PPPVrsta.PRIJAVA,
        public pr_datum: string = '',
        public pr_vrijemeizrade: string = '',
        public vrpr_naziv: string = '',
        public datoteka: File = null,
        public blokiran: number = 0,
        public pr_prikaziMob: number = 0
    ) {}
}

export class PPPPrijedlog implements IPPP {
    constructor(
        public idprijava: number = null,
        public pr_naziv: string = '',
        public pr_opis: string = '',
        public pr_adresa: string = '',
        public pr_status: number = 0,
        public pr_lat: number = null,
        public pr_lon: number  = null,
        public problem_idproblem: number = null,
        public gost_idgost: number = null,
        public pr_vrsta: number = PPPVrsta.PRIJEDLOG,
        public pr_datum: string = '',
        public pr_vrijemeizrade: string = '',
        public vrpr_naziv: string = '',
        public datoteka: File = null,
        public blokiran: number = 0,
        public pr_prikaziMob: number = 0
    ) {}
}

export class PPPPohvala implements IPPP {
    constructor(
        public idprijava: number = null,
        public pr_naziv: string = '',
        public pr_opis: string = '',
        public pr_adresa: string = '',
        public pr_status: number = 0,
        public pr_lat: number = null,
        public pr_lon: number  = null,
        public problem_idproblem: number = null,
        public gost_idgost: number = null,
        public pr_vrsta: number = PPPVrsta.POHVALA,
        public pr_datum: string = '',
        public pr_vrijemeizrade: string = '',
        public vrpr_naziv: string = '',
        public datoteka: File = null,
        public blokiran: number = 0,
        public pr_prikaziMob: number = 0
    ) {}
}

export enum PrijavaStatusId{
    ZAPRIMLJENO = 1,
    U_POSTUPKU = 2,
    RIJESENO = 3,
}

export enum PrijavaStatusText{
    ZAPRIMLJENO = 'Zaprimljeno',
    U_POSTUPKU = 'U postupku',
    RIJESENO = 'Riješeno',
}

export interface IPrijavaOption{
    id: PrijavaStatusId,
    text: PrijavaStatusText,
}

export class PrijavaOption implements IPrijavaOption{

    constructor(
        public id: PrijavaStatusId,
        public text: PrijavaStatusText
    ){}
}

export const DEFAULT_PRIJAVA_OPTONS: IPrijavaOption[] = [
    new PrijavaOption(PrijavaStatusId.ZAPRIMLJENO, PrijavaStatusText.ZAPRIMLJENO),
    new PrijavaOption(PrijavaStatusId.U_POSTUPKU, PrijavaStatusText.U_POSTUPKU),
    new PrijavaOption(PrijavaStatusId.RIJESENO, PrijavaStatusText.RIJESENO),
]
