<div class="content-block obavijesti-tablica-container">

  <dx-data-grid
      id="obavijesti-datagrid"
      class="obavijesti-datagrid"
      [rowAlternationEnabled]="true"
      [hoverStateEnabled]="true"
      [dataSource]="obavijesti"
      keyExpr="idobavijest"
      [wordWrapEnabled]="true"
      [showRowLines]="true"
      [showBorders]="false"
      [columnAutoWidth]="false"
      [columnHidingEnabled]="false"
      (onToolbarPreparing)="onToolbarPreparing($event)"
      [height]="824">

      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-search-panel [visible]="true" [width]="240" placeholder="Pretraži..."></dxo-search-panel>
      
      <div *dxTemplate="let data of 'naslovTablice'">
        <div class="obavijesti-tablica-container__header-content-container">
          <p class="obavijesti-tablica-container__header-content-container__title">Popis obavijesti: {{obavijesti && obavijesti.length}}</p>
          <dx-button
            icon="add" type="default"
            hint="Dodaj obavijst" stylingMode="contained" 
            (onClick)="dodajObavijest()">
        </dx-button>
        </div>
      </div>

      <dxi-column dataField="idobavijest" [visible]="false"></dxi-column>
      <dxi-column dataField="ob_datum" caption="Datum" [calculateCellValue]="formatDateCell" [width]="90"></dxi-column>
      <dxi-column dataField="ob_tekst" caption="Opis" [allowFiltering]="false"></dxi-column>
      <dxi-column dataField="ob_web" caption="Web" cellTemplate="linkTemplate" [allowFiltering]="false">
        <div *dxTemplate="let data of 'linkTemplate'">
          <a href="{{data.value}}" target="_blank" style="text-decoration: none;">{{data.value}}</a>
        </div>
      </dxi-column>
      <dxi-column dataField="kor_ime" caption="Vijest dodao"></dxi-column>
      <dxi-column dataField="ob_aktivna" caption="Aktivna" alignment="center" cellTemplate="aktivna"> 
        <div *dxTemplate="let data of 'aktivna'" >                    
          <dx-check-box [(value)]="data.value" readOnly="true"></dx-check-box>
        </div>
      </dxi-column>

      <dxi-column alignment="center" cellTemplate="editTemplate" class="obavijesti-datagrid__actions-row"></dxi-column>
        <div *dxTemplate="let row of 'editTemplate'" class="obavijesti-datagrid__actions-row__actions">
          <dx-button (onClick)="urediObavijest(row)" icon="edit" stylingMode="contained" type="default"></dx-button>
          <dx-button (onClick)="openObrisiObavijest(row)" icon="trash" stylingMode="contained" type="danger"></dx-button>
        </div>

      <dxo-paging [pageSize]="15"> </dxo-paging>
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[15, 30, 50]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true">
      </dxo-pager>
  
    </dx-data-grid>

</div>

<add-obavijest-form
    [isPopupVisible]="isAddObavijestFormVisible"
    (openModalChange)="onAddObavijestPopupChange($event)"
    (obavijestCreated)="onObavijestChange($event)">
</add-obavijest-form>

<edit-obavijest-form
    *ngIf="isEditObavijestFormVisible && odabranaObavijestId"
    [obavijestId]="odabranaObavijestId"
    [isPopupVisible]="isEditObavijestFormVisible"
    (openModalChange)="onEditObavijestPopupChange($event)"
    (obavijestUpdated)="onObavijestChange($event)">
</edit-obavijest-form>