import { Component, OnInit } from '@angular/core';
import { IOdjel } from 'src/app/shared/models/odjel.model';
import { AuthService } from 'src/app/shared/services';
import { ApiService } from 'src/app/shared/services/api.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-odjeli',
  templateUrl: './odjeli.component.html',
  styleUrls: ['./odjeli.component.scss']
})
export class OdjeliComponent implements OnInit {
  public odjeli: IOdjel[];

  constructor(
    private _api: ApiService,
    private _authService: AuthService
  ) { }

  ngOnInit(): void {
    this.dohvatiOdjele();
  }

  dohvatiOdjele(){
    const partnerId = this._authService.getUserPartnerId();
    const userToken = this._authService.getToken();

    this._api.dohvatiOdjele({
      'idpartner': partnerId,
      'access_token': userToken
    }).subscribe(res=>{
      // console.log(res)
      if(res){
        this.odjeli = res.data;
      } else {
        notify("Greška prilikom dohvata odjela!", "error", 1000);
      }
    });
  };

}
