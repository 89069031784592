<dx-popup
    title="UREDI PRIJAVU"
    [(visible)]="isPopupVisible" 
    [closeOnOutsideClick]="false"
    [showCloseButton]="true"
    (onHiding)="closePopup()"
    (onShown)="onShown($event)">

    <dx-scroll-view width="100%" height="100%">

        <form (submit)="onFormSubmit($event)">
        <dx-form
            #editPrijavaForm
            *ngIf="novaPrijava"
            id="edit-prijava-form"
            class="edit-prijava-form"
            [(formData)]="novaPrijava"
            [showColonAfterLabel]="true"
            (onFieldDataChanged)="form_fieldDataChanged($event)"
            [colCount]="2">

            <dxi-item itemType="group" [colSpan]="1">

                
                <dxi-item dataField="pr_naziv" [label]="{text: 'Naslov'}" editorType="dxTextBox">
                    <dxi-validation-rule type="required" message="Naslov je nužan."></dxi-validation-rule>
                    <dxi-validation-rule type="stringLength" [min]="2" message="Naslov ne može biti jedan znak"></dxi-validation-rule>
                </dxi-item>
                
                <dxi-item [colSpan]="1" dataField="problem_idproblem" [label]="{text: 'Vrsta'}" editorType="dxSelectBox" 
                    [editorOptions]="{ 
                        searchEnabled:'true', dataSource: problemiSelectOptions, 
                        valueExpr:'idproblem', displayExpr:'pr_naziv', width:200
                    }">
                    <dxi-validation-rule  type="required"  message="Vrsta je nužna!"></dxi-validation-rule>
                </dxi-item>
            </dxi-item>

            <!-- Row 1 -->
            <dxi-item itemType="group" [colSpan]="1">

                <dxi-item dataField="pr_opis" [label]="{text: 'Opis'}" editorType="dxTextArea" [editorOptions]="{height: 150}">
                    <dxi-validation-rule type="required" message="Opis je nužan.">
                    </dxi-validation-rule>
                    <dxi-validation-rule type="stringLength" [min]="15" message="Opis mora sadržavati minimalno 15 znakova"></dxi-validation-rule>
                </dxi-item>

            </dxi-item>

            <!-- Row 2 -->
            <!-- Row 2, Col 1 -->
            <dxi-item itemType="group" [colSpan]="1" caption="Slika">

                <dxi-item>
                    <label class="image-upload-container btn btn-bwm">
                        <span class="boxSpan">Dodaj sliku: <i class="dx-icon-image dx-icon-custom-style"></i></span>
                        <input #imageInput
                               type="file"
                               accept="image/*"
                               multiple="multiple"
                               (change)="processFile(imageInput)">
                    </label>
                    <div class="img-container">
                        <div *ngIf="triSlike[0]" class="img-preview-container">
                          <div class="img-preview"
                              *ngFor="let slika of triSlike; let i = index"
                               [ngStyle]="{'background-image': 'url('+ slika + ')'}"
                               (click)="openImage()">
                               <i class="dx-icon-remove" style="float:right;cursor: pointer;font-size: 24px;" (click)="remove(slika,i)"></i>
                          </div>
                        </div>
                    </div>
                    
                </dxi-item>

            </dxi-item>

            <!-- Row 2 -->
            <!-- Row 2, Col 2 -->

            <dxi-item itemType="group" [colSpan]="1" caption="Adresa">

                <dxi-item>
                    <div class="google-map-component-container">
                        <google-map-component [mapHeight]="430" (odabranaAdresaEvent)="prihvatiAdresu($event)" (odabraniLatEvent)="prihvatiLat($event)" (odabraniLngEvent)="prihvatiLng($event)" [postavljeniLat]="latIzAdrese" [postavljeniLng]="lngIzAdrese"></google-map-component>           
                    </div>    
                </dxi-item>

            </dxi-item>

            <dxi-item dataField="pr_status" [label]="{text: 'Status'}" editorType="dxSelectBox" 
                        [editorOptions]="{ searchEnabled:'true', dataSource: prijavaStatusOptions, valueExpr:'id', displayExpr:'text', width:200}">
                        <dxi-validation-rule  type="required"  message="Status je nužan!"></dxi-validation-rule>
            </dxi-item>

            <!-- Row 3 -->
            <!-- <dxi-item itemType="group" [colSpan]="2">

                <dxi-item dataField="pr_status" [label]="{text: 'Status'}" editorType="dxSelectBox" 
                        [editorOptions]="{ searchEnabled:'true', dataSource: prijavaStatusOptions, valueExpr:'id', displayExpr:'text', width:200}">
                        <dxi-validation-rule  type="required"  message="Status je nužan!"></dxi-validation-rule>
                </dxi-item>

            </dxi-item> -->

            <!-- Row 4 -->
            <!-- <dxi-item itemType="group" [colSpan]="1">

                <dxi-item dataField="Korisnik UUID" editorType="dxTextBox"></dxi-item>

            </dxi-item> -->

            <!-- <dxi-item itemType="group" [colSpan]="2">

                <dxi-item dataField="blokiran" [label]="{text: 'Blokiraj/odblokiraj korisnika'}" editorType="dxCheckBox"></dxi-item>

            </dxi-item> -->

        </dx-form>

            <dxi-item itemType="group" [colSpan]="2" class="edit-prijava-form__button-group">
                <dx-button
                    text="Spremi"
                    type="success"
                    validationGroup="editPrijavaData"
                    [useSubmitBehavior]="true">
                </dx-button>
                <dx-button
                    text="Odustani"
                    type="danger"
                    (onClick)="closePopup()">
                </dx-button>
            </dxi-item>

        </form>

    </dx-scroll-view>

</dx-popup>