<div class="content-block kontakti-tablica-container">

  <dx-data-grid
      id="kontakti-datagrid"
      class="kontakti-datagrid"
      [rowAlternationEnabled]="true"
      [hoverStateEnabled]="true"
      [dataSource]="kontakti"
      keyExpr="idkontakt"
      [wordWrapEnabled]="true"
      [showRowLines]="true"
      [showBorders]="false"
      [columnAutoWidth]="false"
      [columnHidingEnabled]="false"
      (onToolbarPreparing)="onToolbarPreparing($event)"
      [height]="824">

      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-search-panel [visible]="true" [width]="240" placeholder="Pretraži..."></dxo-search-panel>
      
      <div *dxTemplate="let data of 'naslovTablice'">
        <div class="kontakti-tablica-container__header-content-container">
          <p class="kontakti-tablica-container__header-content-container__title">Popis kontakata: {{kontakti && kontakti.length}}</p>
          <dx-button
            icon="add" type="default"
            hint="Dodaj kontakt" stylingMode="contained" 
            (onClick)="dodajKontakt()">
        </dx-button>
        </div>
      </div>

      <dxi-column dataField="idkontakt" [visible]="false"></dxi-column>
      <dxi-column dataField="ko_naziv" caption="Naziv"></dxi-column>
      <dxi-column dataField="ko_adresa" caption="Adresa"></dxi-column>
      <dxi-column dataField="ko_telefon" caption="Telefon"></dxi-column>
      <dxi-column dataField="ko_mobitel" caption="Mobitel"></dxi-column>
      <dxi-column dataField="ko_email" caption="Mail" cellTemplate="mailTemplate">
            <div *dxTemplate="let data of 'mailTemplate'">
              <a href="mailto:{{data.value}}" style="text-decoration: none;">{{data.value}}</a>
            </div>
          </dxi-column>
          <dxi-column dataField="ko_web" caption="Web" cellTemplate="linkTemplate">
            <div *dxTemplate="let data of 'linkTemplate'">
              <a href="{{data.value}}" target="_blank" style="text-decoration: none;">{{data.value}}</a>
            </div>
          </dxi-column>

      <dxi-column alignment="center" cellTemplate="editTemplate" class="kontakti-datagrid__actions-row"></dxi-column>
        <div *dxTemplate="let row of 'editTemplate'" class="kontakti-datagrid__actions-row__actions">
          <dx-button (onClick)="urediKontakt(row)" icon="edit" stylingMode="contained" type="default"></dx-button>
          <dx-button (onClick)="openObrisiKontakt(row)" icon="trash" stylingMode="contained" type="danger"></dx-button>
        </div>

      <dxo-paging [pageSize]="15"></dxo-paging>
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[15, 30, 50]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true">
      </dxo-pager>
      s
  </dx-data-grid>

</div>

<add-kontakt-form
    [isPopupVisible]="isAddKontaktFormVisible"
    (openModalChange)="onAddKontaktPopupChange($event)"
    (kontaktCreated)="onKontaktChange($event)">
</add-kontakt-form>

<edit-kontakt-form
    *ngIf="isEditKontaktFormVisible && odabraniKontaktId"
    [kontaktId]="odabraniKontaktId"
    [isPopupVisible]="isEditKontaktFormVisible"
    (openModalChange)="onEditKontaktPopupChange($event)"
    (kontaktUpdated)="onKontaktChange($event)">
</edit-kontakt-form>
