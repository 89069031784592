export enum KorisnikType{
    ZAPOSLENIK,
    NACELNIK,
    ADMIN,
    VANJSKI = -1
}
export enum ModuleType{
    OBAVIJESTI=1,
    PPP=2,
    DOGADJANJA=3,
    KONTAKTI=4
}

export interface IKorisnik {
    idkorisnik: number,
    imeprezime: string,
    kor_admin: number,
    kor_aktivan: number,
    kor_email: string,
    kor_lozinka: string,
    kor_prava: number,
    od_naziv: string,
    odjel_idodjel: number,
    kor_telefon: string,
    kor_ime?: string,
    kor_prezime?: string,
}

export class Korisnik implements IKorisnik {
    constructor(
        public idkorisnik: number,
        public imeprezime: string,
        public kor_admin: number,
        public kor_aktivan: number,
        public kor_email: string,
        public kor_lozinka: string,
        public kor_prava: number,
        public od_naziv: string,
        public kor_ime: string,
        public kor_telefon: string,
        public odjel_idodjel: number,
        public kor_prezime: string,
    ) {}
}

export class KorisnikDefault implements IKorisnik {
    constructor(
        public idkorisnik: number = undefined,
        public imeprezime: string = '',
        public kor_admin: number = 0,
        public kor_aktivan: number = undefined,
        public kor_email: string = '',
        public kor_lozinka: string = '',
        public kor_prava: number = 0,
        public od_naziv: string = '',
        public odjel_idodjel: number = null,
        public kor_ime: string  = '',
        public kor_prezime: string  = '',
        public kor_telefon: string = '',
    ) {}
}
