import { Component, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { IKorisnik } from 'src/app/shared/models/korisnik.model';
import { AuthService } from 'src/app/shared/services';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-korisnici',
  templateUrl: './korisnici.component.html',
  styleUrls: ['./korisnici.component.scss']
})
export class KorisniciComponent implements OnInit {
  public korisnici: IKorisnik[];

  constructor(
    private _api: ApiService,
    private _authService: AuthService
  ) { }

  ngOnInit(): void {
    this.dohvatiKorisnike();
  }

  dohvatiKorisnike(){
    const partnerId = this._authService.getUserPartnerId();
    const userToken = this._authService.getToken();

    this._api.dohvatiKorisnike({
      'idpartner': partnerId,
      'access_token': userToken
    }).subscribe(res=>{
      // console.log(res)
      if(res){
        this.korisnici = res.data;
        this.korisnici = this.korisnici.filter(korisnik => korisnik.kor_prava <= this._authService.getUserNivoPrava());
      } else {
        notify("Greška prilikom korisnika!", "error", 1000);
      }
    });
  };

}
