<input #mapSearchField class="my-map-search-field" type="text"/>
<google-map
      width="100%"
      [options]="mapConfigurations"
      [height]="mapHeight"
      [center]="initialCoordinates"
      [zoom]="zoom"
      (boundsChanged)="posaljiAdresu()"
      (boundsChanged)="posaljiLat()"
      (boundsChanged)="posaljiLng()">
      <map-marker
            [position]="initialCoordinates"
            [options]="marker">
      </map-marker>
</google-map>