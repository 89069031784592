<div class="content-block vrste-problema-tablica-container">

    <dx-data-grid
      id="vrste-problema-datagrid"
      class="vrste-problema-datagrid"
      [rowAlternationEnabled]="true"
      [hoverStateEnabled]="true"
      [dataSource]="vrsteProblema"
      keyExpr="idproblem"
      [wordWrapEnabled]="true"
      [showRowLines]="true"
      [showBorders]="false"
      [columnAutoWidth]="false"
      [columnHidingEnabled]="false"
      (onToolbarPreparing)="onToolbarPreparing($event)"
      [height]="820">

      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-header-filter [visible]="!isAdmin"></dxo-header-filter>
      <dxo-filter-row [visible]="!isAdmin"></dxo-filter-row>
      <dxo-search-panel [visible]="true" [width]="240" placeholder="Pretraži..."></dxo-search-panel>
      
      <div *dxTemplate="let data of 'naslovTablice'">
        <div class="vrste-problema-tablica-container__header-content-container">
          <p class="vrste-problema-tablica-container__header-content-container__title">Popis vrsta problema: {{vrsteProblema && vrsteProblema.length}}</p>
          <dx-button
            icon="add" type="default"
            hint="Dodaj kontakt" stylingMode="contained" 
            (onClick)="dodajVrstuProblema()">
        </dx-button>
        </div>
      </div>

      <dxi-column dataField="idproblem" [visible]="false"></dxi-column>
      <dxi-column dataField="pr_naziv" caption="Naziv"></dxi-column>
      <dxi-column dataField="zaduzeni" caption="Zadužene osobe" [calculateCellValue]="popisZaduzenihOsoba_calculateCellValue" [visible]="isZaduzeneOsobeVisible"></dxi-column>

      <dxi-column alignment="center" cellTemplate="editTemplate" class="vrste-problema-datagrid__actions-row"></dxi-column>
        <div *dxTemplate="let row of 'editTemplate'" class="vrste-problema-datagrid__actions-row__actions">
          <dx-button (onClick)="urediVrstuProblema(row)" icon="edit" stylingMode="contained" type="default"></dx-button>
          <dx-button (onClick)="openObrisiVrstuProblema(row)" icon="trash" stylingMode="contained" type="danger"></dx-button>
        </div>

      <dxo-paging [pageSize]="15"></dxo-paging>
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[15, 30, 50]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true">
      </dxo-pager>
    </dx-data-grid>

</div>


<add-vrste-problema-form
    [type]="type"
    [isPopupVisible]="isAddVrstaProblemaVisible"
    (openModalChange)="onAddVrstaProblemaPopupChange($event)"
    (vrstaProblemaCreated)="onVrstaProblemaChange($event)">
</add-vrste-problema-form>

<edit-vrste-problema-form
    *ngIf="isEditVrstaProblemaVisible && odabranaVrstaProblemaId"
    [type]="type"
    [vrstaProblemaId]="odabranaVrstaProblemaId"
    [isPopupVisible]="isEditVrstaProblemaVisible"
    (openModalChange)="onEditVrstaProblemaPopupChange($event)"
    (vrstaProblemaUpdated)="onVrstaProblemaChange($event)">
</edit-vrste-problema-form>