import * as moment from "moment";

export interface IObavijest {
    idobavijest: number,
    ob_tekst: string,
    ob_datum: string,
    ob_web: string,
    ob_aktivna: number,
    kor_ime: string,
}

export class Obavijest implements IObavijest {
    constructor(
        public idobavijest: number = null,
        public ob_tekst: string = '',
        public ob_datum: string = moment().toString(),
        public ob_web: string = '',
        public ob_aktivna: number = 1,
        public kor_ime: string = '',
    ) {}
}
