import { Component, Input, NgModule, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthService } from '../../../services';
import { ApiService } from '../../../services/api.service';
import { DxButtonModule, DxDataGridModule } from 'devextreme-angular';
import { IVrstaProblema } from '../../../models/vrste-problema.model';
import { AddDefaultVrsteProblemaFormModule } from '../../forme/add-vrste-problema-form/add-vrste-problema-form.component';
import { EditDefaultVrsteProblemaFormModule } from '../../forme/edit-vrste-problema-form/edit-vrste-problema-form.component';
import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';
import { TipVrsteProblema } from 'src/app/shared/models/common.model';
import { DxoHeaderFilterModule } from 'devextreme-angular/ui/nested';

@Component({
  selector: 'vrste-problema-tablica',
  templateUrl: 'vrste-problema-tablica.component.html',
  styleUrls: ['./vrste-problema-tablica.component.scss']
})

export class VrsteProblemaTablicaComponent implements OnInit {
  @Input()
  type!: TipVrsteProblema;
  @Output()
  defaultVrsteProblema: IVrstaProblema[];

  vrsteProblema: IVrstaProblema[];
  odabranaVrstaProblema: IVrstaProblema;
  odabranaVrstaProblemaId: number;

  isEditVrstaProblemaVisible: boolean = false;
  isAddVrstaProblemaVisible: boolean = false;
  isZaduzeneOsobeVisible: boolean = false;

  isAdmin: boolean = false;

  constructor(
    private _authService: AuthService,
    private _api: ApiService,
  ) {}

  ngOnInit() {
    if(this._authService.getUserNivoPrava() === 2) this.isAdmin = true;
    this.dohvatiVrsteProblema();
    this.isZaduzeneOsobeVisible = this.type === TipVrsteProblema.VRSTA_PROBLEMA ? true : false;
  }

  onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift({
    location: 'before',
    template: 'naslovTablice',    
      }
    )
  }

  onVrstaProblemaChange(isChanged){
    if(isChanged) this.dohvatiVrsteProblema();
  }

  onAddVrstaProblemaPopupChange(isVisible: boolean){
    this.isAddVrstaProblemaVisible = isVisible;
  }

  onEditVrstaProblemaPopupChange(isVisible: boolean){
    this.isEditVrstaProblemaVisible = isVisible;
  }

  dodajVrstuProblema(){
    this.isAddVrstaProblemaVisible = true;
  }

  urediVrstuProblema(row){
    this.odabranaVrstaProblema = row.data;
    this.odabranaVrstaProblemaId = row.data.idproblem;
    this.isEditVrstaProblemaVisible = true;
  }

  openObrisiVrstuProblema(row){
    const vrstaProblema: IVrstaProblema = row.data; 
    this.odabranaVrstaProblema = vrstaProblema;
    this.odabranaVrstaProblemaId = vrstaProblema.idproblem;
    this.showDialog();
  }

  dohvatiVrsteProblema(){
    if(this.type === TipVrsteProblema.VRSTA_PROBLEMA){
      this.apiDohvatiVrsteProblema();
    }
    else {
      this.apiDohvatiDefaultVrsteProblema();
    }
  }

  apiDohvatiVrsteProblema(){
    this._api.dohvatiVrsteProblema({
      'idpartner': this._authService.getUserPartnerId(),
      'access_token': this._authService.getToken()
    }).subscribe(res=>{
      //console.log(res);
      if(res){
        this.vrsteProblema = res.data;
      } 
    });
  }

  apiDohvatiDefaultVrsteProblema(){
    this._api.dohvatiDefaultVrsteProblema({
      'access_token': this._authService.getToken()
    }).subscribe(res=>{
      //console.log(res);
      if(res){
        this.vrsteProblema = res.data;
        this.defaultVrsteProblema = this.vrsteProblema;
      } 
    });
  }

  obrisiVrstuProblema(){
    if(this.type === TipVrsteProblema.VRSTA_PROBLEMA){
      if(this.odabranaVrstaProblema.zaduzeni.length > 0){
        notify({
          message: "Greška prilikom brisanja, na ovom problemu postoje zadužene osobe, odznačite ih kako biste obrisali problem.",
          position: 'center',
        }, "error", 6000);
      } else this.apiObrisiVrstuProblema();
    }
    else {
      this.apiObrisiDefaultVrstuProblema();
    }
  }

  apiObrisiVrstuProblema(){
      this._api.obrisiVrstuProblema({
        'idproblem': this.odabranaVrstaProblemaId,
        'access_token': this._authService.getToken()
      }).subscribe(res=>{
        //console.log(res);
        if(res.success == true){
          notify("Uspješno ste obrisali vrstu problema!", "success", 1000);
          this.dohvatiVrsteProblema();
        } else {
          notify("Greška prilikom brisanja vrste problema!", "error", 1000);
        }
      });
  }

  apiObrisiDefaultVrstuProblema(){
    this._api.obrisiDefaultVrstuProblema({
      'idkorisnik': this._authService.getUserId(),
      'idproblem': this.odabranaVrstaProblemaId,
      'access_token': this._authService.getToken()
    }).subscribe(res=>{
      //console.log(res);
      if(res.success == true){
        notify("Uspješno ste obrisali vrstu problema!", "success", 1000);
        this.dohvatiVrsteProblema();
      } else {
        notify("Greška prilikom brisanja vrste problema!", "error", 1000);
      }
    });
  }

  showDialog(){
    let myDialog = custom({
      title: "BRISANJE VRSTE PROBLEMA",
      messageHtml: "<b>Jeste li sigurni da želite obrisati vrstu problema?</b>",
      buttons: [{
          text: "DA", 
          type: 'danger',
          onClick: (e) => {
              return { buttonText: e.component.option("text") }
          },
          
      }, 
      {
        text: "NE",
        type: 'default',
        onClick: (e) => {
            return { buttonText: e.component.option("text") }
        }}
      ]
    });

    myDialog.show().then((dialogResult) => {
        if(dialogResult.buttonText === "DA"){
          this.obrisiVrstuProblema();
        }
    });
  } 

  popisZaduzenihOsoba_calculateCellValue(rowData: IVrstaProblema) {
    const zaduzeneOsobe = rowData.zaduzeni;
    let zaduzeneOsobeText = "";
    if(zaduzeneOsobe){
      zaduzeneOsobe.map((zaduzenaOsoba, trenutniIndex) => {
        zaduzeneOsobeText = zaduzeneOsobeText.concat(`${zaduzenaOsoba.kor_ime} ${zaduzenaOsoba.kor_prezime}`);
        if(zaduzeneOsobe.length > 1 && !(zaduzeneOsobe.length-1===trenutniIndex)){
          zaduzeneOsobeText +=  zaduzeneOsobe.length-2 !== trenutniIndex ? ", " : " i ";
        }
      })
    }
    return zaduzeneOsobeText;
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    AddDefaultVrsteProblemaFormModule,
    EditDefaultVrsteProblemaFormModule,
  ],
  declarations: [ VrsteProblemaTablicaComponent ],
  exports: [ VrsteProblemaTablicaComponent ]
})
export class VrsteProblemaTablicaComponentModule { }
