import { Component, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { TipVrsteProblema } from 'src/app/shared/models/common.model';
import { IVrstaProblema } from 'src/app/shared/models/vrste-problema.model';
import { AuthService } from 'src/app/shared/services';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-vrste-problema',
  templateUrl: './vrste-problema.component.html',
  styleUrls: ['./vrste-problema.component.scss']
})
export class VrsteProblemaComponent implements OnInit {
  tipVrsteProblema: TipVrsteProblema = TipVrsteProblema.VRSTA_PROBLEMA;

  constructor( ) { }

  ngOnInit(): void { }

}
