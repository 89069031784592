import { Component, NgModule, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { UserPanelModule } from '../../user-panel/user-panel.component';
import { ApiService } from '../../../services/api.service';
import { DxButtonGroupModule, DxCheckBoxModule, DxFormComponent, DxFormModule, DxPopupModule, DxScrollViewModule, DxValidatorModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { AuthService } from '../../../services';
import { IKorisnik } from '../../../models/korisnik.model';
import notify from 'devextreme/ui/notify';
import { OdabirVrsteKorisnikaModule } from '../../odabir-vrste-korisnika/odabir-vrste-korisnika.component';
import { IOdjel } from 'src/app/shared/models/odjel.model';



@Component({
  selector: 'edit-korisnik-form',
  templateUrl: 'edit-korisnik-form.component.html',
  styleUrls: ['./edit-korisnik-form.component.scss']
})

export class EditKorisnikFormComponent implements OnInit {
  @ViewChild("editKorisnikForm", { static: false }) editKorisnikForm: DxFormComponent
  
  @Input()
  korisnikId!: number;
  @Input()
  partnerId!: number;
  @Input()
  isPopupVisible: boolean = false;

  @Output() openModalChange = new EventEmitter<boolean>();
  @Output() korisnikUpdated = new EventEmitter<boolean>();

  noviKorisnik: IKorisnik;
  odjeliSelectOptions: IOdjel[];

  constructor(
    private _api: ApiService,
    private _authService: AuthService,
  ) {  }

  ngOnInit(): void {
    this.dohvatiKorisnika();
    this.dohvatiOdjele();
  }

  closePopup(): void {
    this.openModalChange.emit(false);
  }
  openPopup(): void {
    this.openModalChange.emit(true);
  }

  form_fieldDataChanged (e) {
    let updatedField = e.dataField;
    let newValue = e.value;

  }

  onFormSubmit(e: any){
    e.preventDefault();
    this.submitForm();
  }

  submitForm(){
    this.urediKorisnika();
  }

  onShown(e){
    this.editKorisnikForm.instance.getEditor("kor_ime").focus();
  }

  dohvatiKorisnika(){
    this._api.dohvatiKorisnika({
      'idkorisnik': this.korisnikId,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      // console.log(res)
      if(res.success == true){
        const korisnik: IKorisnik = res.data[0];
        this.noviKorisnik = korisnik;
        this.noviKorisnik.kor_admin = korisnik.kor_prava;
      }
    });
  }

  urediKorisnika(){
    this._api.urediKorisnika({
      'idkorisnik': this.korisnikId,
      'ime': this.noviKorisnik.kor_ime,
      'prezime': this.noviKorisnik.kor_prezime,
      'lozinka': this.noviKorisnik.kor_lozinka,
      'idodjel': this.noviKorisnik.odjel_idodjel,
      'email': this.noviKorisnik.kor_email,
      'telefon': this.noviKorisnik.kor_telefon,
      'prava': this.noviKorisnik.kor_prava,
      'aktivan': 1,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res.success == true){
        notify("Uspješno ste uredili korisnika!", "success", 1000);
        this.closePopup();
        this.korisnikUpdated.emit(true);
      } else {
        notify("Greška prilikom uređivanja korisnika!", "error", 1000);
      }
    });
  }

  onRadioButtonPravaPromjena(korisnikPrava){
      this.noviKorisnik.kor_prava = korisnikPrava;
  }

  dohvatiOdjele(){
    const partnerId = this._authService.getUserPartnerId();
    const userToken = this._authService.getToken();

    this._api.dohvatiOdjele({
      'idpartner': partnerId,
      'access_token': userToken
    }).subscribe(res=>{
      // console.log(res)
      if(res.success == true){
        this.odjeliSelectOptions = res.data;
      } else {
        notify("Greška prilikom dohvata odjela!", "error", 1000);
      }
    });
  };

}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxPopupModule,
    DxFormModule,
    DxScrollViewModule,
    DxButtonGroupModule,
    DxCheckBoxModule,
    DxValidatorModule,
    OdabirVrsteKorisnikaModule
  ],
  declarations: [ EditKorisnikFormComponent ],
  exports: [ EditKorisnikFormComponent ]
})
export class EditKorisnikModule { }
