import { Component, NgModule, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { 
  DxButtonGroupModule,
  DxCheckBoxModule,
  DxFormComponent,
  DxFormModule,
  DxPopupModule,
  DxScrollViewModule,
  DxValidatorModule
} from 'devextreme-angular';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import notify from 'devextreme/ui/notify';

import { UserPanelModule } from '../../user-panel/user-panel.component';

import { ApiService } from '../../../services/api.service';
import { AuthService } from '../../../services';

import { IPartner, Partner } from '../../../models/partneri.model';
import { IModul } from '../../../models/modul.model';

import { ImageSnippet } from 'src/app/shared/models/common.model';

@Component({
  selector: 'add-partner-form',
  templateUrl: 'add-partner-form.component.html',
  styleUrls: ['./add-partner-form.component.scss']
})

export class AddPartnerFormComponent implements OnInit {

  @ViewChild("addPartnerForm", { static: false }) addPartnerForm: DxFormComponent

  @Input()
  isPopupVisible: boolean = false;
  
  @Output() openModalChange = new EventEmitter<boolean>();
  @Output() partnerCreated = new EventEmitter<boolean>();
  
  partner: IPartner;

  popupImageVisible: boolean=false;
  slikeZaPrikaz: string[];
  slike: any=[];
  triSlike:any=[];
  noveSlike:any=[];
  obslike: any[]=[];
  slikeFiles: any=[];
  moduli: number[] = [];
  disabledSecondClick: boolean = false;

  constructor(
    private _api: ApiService,
    private _authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.dohvatiModule();
  }

  onFormSubmit(e: any){
    e.preventDefault();
    this.submitForm();
  }

  submitForm(){
    if(this.disabledSecondClick != true){
      this.dodajPartnera();
    }
  }

  refresh() {
      this.addPartnerForm.instance.resetValues();
  }

  closePopup(): void {
    this.refresh();
    this.resetModules();
    this.openModalChange.emit(false);
  }

  openPopup(): void {
    this.openModalChange.emit(true);
  }

  form_fieldDataChanged (e) {
    let updatedField = e.dataField;
    let newValue = e.value;

    // if(updatedField === "Naziv"){
    //   this.partner.pa_naziv = newValue;
    // }
    // if(updatedField === "Adresa"){
    //   this.partner.pa_adresa = newValue;
    // }
    // if(updatedField === "Odgovorna osoba"){
    //   this.partner.pa_odgosoba = newValue;
    // }
    // if(updatedField === "Telefon"){
    //   this.partner.pa_telefon = newValue;
    // }
    // if(updatedField === "Mail"){
    //   this.partner.pa_email = newValue;
    // }
    // if(updatedField === "Web"){
    //   this.partner.pa_web = newValue;
    // }
  }

  dodajPartnera(){
    this.disabledSecondClick = true;
    const request = this.buildRequestFormData();
    this._api.dodajPartnera(request).subscribe(res=>{
      if(res.success == true){
        notify("Uspješno ste dodali partnera!", "success", 1000);
        this.closePopup();
        this.partnerCreated.emit(true);
        this.disabledSecondClick = false;
        this.triSlike.splice(0,1);
        this.noveSlike.splice(0,1);
      } else {
        notify("Greška prilikom dodavanja partnera!", "error", 1000);
      }
    });
  }

  buildRequestFormData(){
    const aktivniModuliIds: number[] = this.partner.moduli.filter(modul => modul.aktivan === 1).map(modul => modul.idmodul);
    this.moduli = aktivniModuliIds;
    let formData:FormData = new FormData();
    formData.append('naziv', this.partner.pa_naziv);
    formData.append('adresa', this.partner.pa_adresa);
    formData.append('odgovorna', this.partner.pa_odgosoba);
    formData.append('telefon', this.partner.pa_telefon);
    formData.append('mail', this.partner.pa_email);
    formData.append('web', this.partner.pa_web);
    formData.append('aktivan', this.partner.pa_aktivan.toString());
    formData.append('moduli', `[${this.moduli}]`)
    for(let i=0;i<this.noveSlike.length;i++){
      formData.append('datoteka', this.noveSlike[i])
    }
    formData.append('access_token', this._authService.getToken().toString());
    return formData;
  }

  resetModules(){
    if(this.partner){
      this.partner.moduli = this.partner.moduli.map((modul: IModul) => { return {...modul, aktivan: 0}});
    }
  }

  dohvatiModule(){
    this._api.dohvatiModule({
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res){
        const moduli = res.data.map((modul: IModul) => { return {...modul, aktivan: 0}});
        this.partner = new Partner(undefined,'','','','','','','','',undefined,moduli);
      } else {
        notify("Greška prilikom dohvata modula!", "error", 1000);
      }
    });
  }

  onModuleCheckboxChange(modul: IModul){
    const newValue = modul.aktivan === 0 ? 1 : 0;
    this.partner.moduli.map(item => {
      if(item.idmodul === modul.idmodul){
        item.aktivan = newValue;
      } 
    })
  };

  processFile(imageInput: any) {
    for(let i=0;i<imageInput.files.length;i++){
      const file: File = imageInput.files[i];
      const reader = new FileReader();

      reader.addEventListener('load', (event: any) => {
        if(this.triSlike.length===3){
          notify("Možete odabrati samo tri fotografije!",'error');
        }else{
          this.triSlike.unshift(new ImageSnippet(event.target.result, file));
          this.noveSlike.push(file);
          this.triSlike[0]=this.triSlike[0].src;
        }
      });

      reader.readAsDataURL(file);
    }
  }

  openImage(){
    this.slike=[];
    this.popupImageVisible=true;
    for(let i=0;i<this.triSlike.length;i++){
      this.slike.push(this.triSlike[i]);
      this.slike.join(",");
    }
    this.slikeZaPrikaz=this.slike;
  }

  remove(slika,i){
    for(let j=0;j<this.slikeFiles.length;j++){
      if(slika===this.slikeFiles[j].putanja){
        this.obslike.push(this.slikeFiles[j].idslikadogadjaj);
        this.slikeFiles.splice(j,1);
      }
    }
    this.noveSlike.splice(i,1);
    this.triSlike.splice(i,1);
  }

  onShown(e){
    this.addPartnerForm.instance.getEditor("pa_naziv").focus();
  }

}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxPopupModule,
    DxFormModule,
    DxScrollViewModule,
    DxButtonGroupModule,
    DxCheckBoxModule,
    DxValidatorModule,
  ],
  declarations: [ AddPartnerFormComponent ],
  exports: [ AddPartnerFormComponent ]
})
export class AddPartnerModule { }
