import { BrowserModule } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';
import { HttpClientModule, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule } from './layouts';
import { AddPartnerModule, FooterModule, KorisniciTablicaComponentModule, LoginFormModule } from './shared/components';
import { AuthService, ScreenService, AppInfoService } from './shared/services';
import { UnauthenticatedContentModule } from './unauthenticated-content';
import { AppRoutingModule } from './app-routing.module';

import { DevExtremeModule } from 'devextreme-angular';
import { KorisnikComponent } from './pages/korisnik/korisnik.component';
import { AdministratorComponent } from './pages/administrator/administrator.component';
import { PocetnaComponent } from './pages/pocetna/pocetna.component';
import { OdjavaComponent } from './pages/odjava/odjava.component';

import { locale } from 'devextreme/localization';
import { PartneriComponent } from './pages/administrator/partneri/partneri.component';
import { SifarnikVrsteProblemaComponent } from './pages/administrator/sifarnik-vrste-problema/sifarnik-vrste-problema.component';
import { VrsteProblemaComponent } from './pages/korisnik/vrste-problema/vrste-problema.component';
import { PppComponent } from './pages/korisnik/ppp/ppp.component';
import { ObavijestiComponent } from './pages/korisnik/obavijesti/obavijesti.component';
import { DogadjanjaComponent } from './pages/korisnik/dogadjanja/dogadjanja.component';
import { KontaktiComponent } from './pages/korisnik/kontakti/kontakti.component';
import { OdjeliComponent } from './pages/korisnik/odjeli/odjeli.component';
import { KorisniciComponent } from './pages/korisnik/korisnici/korisnici.component';
import { UredjajiComponent } from './pages/korisnik/uredjaji/uredjaji.component';
import { EditPartnerModule } from './shared/components/forme/edit-partner-form/edit-partner-form.component';
import { AddDefaultVrsteProblemaFormModule } from './shared/components/forme/add-vrste-problema-form/add-vrste-problema-form.component';
import { EditDefaultVrsteProblemaFormModule } from './shared/components/forme/edit-vrste-problema-form/edit-vrste-problema-form.component';
import { PrijaveTablicaComponentModule } from './shared/components/tablice/prijave-tablica/prijave-tablica.component';
import { PohvaleTablicaComponentModule } from './shared/components/tablice/pohvale-tablica/pohvale-tablica.component';
import { PrijedloziTablicaComponentModule } from './shared/components/tablice/prijedlozi-tablica/prijedlozi-tablica.component';
import { ObavijestiTablicaComponentModule } from './shared/components/tablice/obavijesti-tablica/obavijesti-tablica.component';
import { DogadjanjaTablicaComponentModule } from './shared/components/tablice/dogadjanja-tablica/dogadjanja-tablica.component';
import { KontaktiTablicaComponentModule } from './shared/components/tablice/kontakti-tablica/kontakti-tablica.component';
import { OdjeliTablicaComponentModule } from './shared/components/tablice/odjeli-tablica/odjeli-tablica.component';
import { VrsteProblemaTablicaComponentModule } from './shared/components/tablice/vrste-problema-tablica/vrste-problema-tablica.component';
import { UredjajiTablicaComponentModule } from './shared/components/tablice/uredjaji-tablica/uredjaji-tablica.component';
import { ZaduzeneOsobeTablicaComponentModule } from './shared/components/tablice/zaduzene-osobe-tablica/zaduzene-osobe-tablica.component';
import { OdabirVrsteKorisnikaModule } from './shared/components/odabir-vrste-korisnika/odabir-vrste-korisnika.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapComponentComponentModule } from './shared/components/google-map-component/google-map-component.component';
import { DataService } from './shared/services/data.service';

locale("hr-HR");
@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authReq = req.clone({
            setHeaders: {
                'Cache-Control': 'no-cache',
                 Pragma: 'no-cache'
            }
        });
        return next.handle(authReq);    
    }
}

@NgModule({
  declarations: [
    AppComponent,
    KorisnikComponent,
    AdministratorComponent,
    PocetnaComponent,
    OdjavaComponent,
    PartneriComponent,
    SifarnikVrsteProblemaComponent,
    VrsteProblemaComponent,
    PppComponent,
    ObavijestiComponent,
    DogadjanjaComponent,
    KontaktiComponent,
    OdjeliComponent,
    KorisniciComponent,
    UredjajiComponent,
  ],
  imports: [
    BrowserModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    LoginFormModule,
    UnauthenticatedContentModule,
    AppRoutingModule,
    HttpClientModule,
    DevExtremeModule,
    AddPartnerModule,
    EditPartnerModule,
    AddDefaultVrsteProblemaFormModule,
    EditDefaultVrsteProblemaFormModule,
    KorisniciTablicaComponentModule,
    PrijaveTablicaComponentModule,
    PohvaleTablicaComponentModule,
    PrijedloziTablicaComponentModule,
    ObavijestiTablicaComponentModule,
    DogadjanjaTablicaComponentModule,
    KontaktiTablicaComponentModule,
    OdjeliTablicaComponentModule,
    VrsteProblemaTablicaComponentModule,
    UredjajiTablicaComponentModule,
    ZaduzeneOsobeTablicaComponentModule,
    GoogleMapsModule,
    GoogleMapComponentComponentModule
  ],
  providers: [
    AuthService, 
    ScreenService, 
    AppInfoService,
    DataService,
    {
    provide: HTTP_INTERCEPTORS,
    useClass: NoCacheHeadersInterceptor,
    multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
