<dx-popup
    title="UREDI ZADUŽENJA OSOBA"
    [(visible)]="isPopupVisible" 
    [closeOnOutsideClick]="true"
    [showCloseButton]="true"
    (onHiding)="closePopup()">

    <dx-scroll-view width="100%" height="100%">

        <form (submit)="onFormSubmit($event)">

            <dx-form 
                #editZaduzenjaOsobaForm
                *ngIf="zaduzeneOsobeOpcije"
                id="edit-zaduzenja-osoba-form"
                class="edit-zaduzenja-osoba-form"
                [(formData)]="zaduzeneOsobeOpcije"
                [readOnly]="false"
                [showColonAfterLabel]="true"
                (onFieldDataChanged)="form_fieldDataChanged($event)"
                [colCount]="1"
                [showValidationSummary]="true"
                validationGroup="AddZaduzenaOsobaData">
                    
                <dxi-item>

                    <dx-data-grid
                        id="zaduzene-osobe-datagrid"
                        class="zaduzene-osobe-datagrid"
                        [rowAlternationEnabled]="true"
                        [hoverStateEnabled]="true"
                        [dataSource]="zaduzeneOsobeOpcije"
                        keyExpr="idkorisnik"
                        [wordWrapEnabled]="true"
                        [showRowLines]="true"
                        [showBorders]="false"
                        [columnAutoWidth]="false"
                        [columnHidingEnabled]="false"
                        [height]="520">

                        <dxo-load-panel [enabled]="true"></dxo-load-panel>
                        <dxo-header-filter [visible]="true"></dxo-header-filter>
                        <dxo-filter-row [visible]="true"></dxo-filter-row>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Pretraži..."></dxo-search-panel>

                        <dxi-column dataField="idkorisnik" [visible]="false"></dxi-column>
                        <dxi-column caption="Ime" dataField="kor_ime"></dxi-column>
                        <dxi-column caption="Prezime" dataField="kor_prezime"></dxi-column>

                        <dxi-column caption="Zaduži osobu" alignment="center" cellTemplate="editTemplate" class="zaduzene-osobe-datagrid__actions-row"></dxi-column>
                            <div *dxTemplate="let row of 'editTemplate'" class="zaduzene-osobe-datagrid__actions-row__actions">
                                <dx-check-box [(value)]="row.data.isSelected"></dx-check-box>
                            </div>

                        <dxo-paging [pageSize]="10"> </dxo-paging>
                        <dxo-pager
                            [visible]="true"
                            [allowedPageSizes]="[10, 15, 20]"
                            [displayMode]="'full'"
                            [showPageSizeSelector]="true"
                            [showInfo]="true"
                            [showNavigationButtons]="true">
                        </dxo-pager>

                    </dx-data-grid>

                </dxi-item>


            </dx-form>

            <dxi-item class="edit-zaduzenja-osoba-form__button-group">
                <dx-button
                    text="Spremi"
                    type="success"
                    validationGroup="AddZaduzenaOsobaData"
                    [useSubmitBehavior]="true">
                </dx-button>
                <dx-button
                    text="Odustani"
                    type="danger"
                    (onClick)="closePopup()">
                </dx-button>
            </dxi-item>
        
        </form>   

    </dx-scroll-view>

</dx-popup>