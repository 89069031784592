import { Component, Input, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DxButtonModule, DxCheckBoxModule, DxDataGridModule } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';

import { AuthService } from '../../../services';
import { ApiService } from '../../../services/api.service';
import { IPPP, PPPVrsta } from '../../../models/ppp.model';
import { KorisnikType } from 'src/app/shared/models/korisnik.model';
import { AddPohvalaFormComponentModule } from '../../forme/add-pohvala-form/add-pohvala-form.component';
import { EditPohvalaFormComponentModule } from '../../forme/edit-pohvala-form/edit-pohvala-form.component';


@Component({
  selector: 'pohvale-tablica',
  templateUrl: 'pohvale-tablica.component.html',
  styleUrls: ['./pohvale-tablica.component.scss']
})

export class PohvaleTablicaComponent implements OnInit {
  @Input()
  pohvale!: IPPP[];
  odabranaPohvalaId: number;

  isAddPohvalaFormVisible: boolean = false;
  isEditPohvalaFormVisible: boolean = false;

  userType: any;

  constructor(
    private _authService: AuthService,
    private _api: ApiService,
  ) {
    this.userType = this._authService.getUserType();
  }

  ngOnInit() {
    this.dohvatiPohvale();
  }

  onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift({
    location: 'before',
    template: 'naslovTablice',    
      }
    )
  }

  dodajPohvalu(){
    this.isAddPohvalaFormVisible = true;
  }

  onPohvalaChange(isChanged){
    if(isChanged) this.dohvatiPohvale();
  }

  onAddPohvalaPopupChange(isVisible: boolean){
    this.isAddPohvalaFormVisible = isVisible;
  }

  onEditPohvalaPopupChange(isVisible: boolean){
    this.isEditPohvalaFormVisible = isVisible;
    this.dohvatiPohvale();
  }

  urediPohvalu(row){
    const pohvala: IPPP = row.data; 
    this.odabranaPohvalaId = pohvala.idprijava;
    this.isEditPohvalaFormVisible = true;
  }

  dohvatiPohvale(){
    this._api.dohvati3P(this.generatePrijaveRequest()).subscribe(res=>{
      if(res){
        const ppp = res.data;
        this.initPPPTypes(ppp);
      } else {
        notify("Greška prilikom dohvata pohvala!", "error", 1000);
      }
    });
  }

  initPPPTypes(pppList: IPPP []){
    this.pohvale = [];
    pppList.map(ppp => {
      switch(ppp.pr_vrsta){
        case PPPVrsta.POHVALA:
          this.pohvale.push(ppp);
          break;
      }
    });
  }

  generatePrijaveRequest(){
    const userId = this._authService.getUserId();
    const userToken = this._authService.getToken();
    const userType: KorisnikType = this._authService.getUserType();
    const partnerId = this._authService.getUserPartnerId();
    let requestData: any;

    if(userType === KorisnikType.NACELNIK){
      return requestData = {
        'idgost': false,
        'idpartner': partnerId,
        'access_token': userToken
      }
    }
    else{
      return requestData = {
        'idgost': false,
        'idpartner': partnerId,
        'idkorisnik': userId,
        'access_token': userToken
      }
    }
  }

  odobri(row){
    this._api.urediVidljivost({
      'idprijava': row.data.idprijava,
      'vidljiv': row.value,
      'access_token': this._authService.getToken()
    }).subscribe(res=>{
      if(res){
        notify(res.message, "success", 1000);
      } else {
        notify("Greška!", "error", 1000);
      }
    });
  }

  obrisiPohvalu(row){
    const userToken = this._authService.getToken();
    let myDialog = custom({
      title: "BRISANJE",
      messageHtml: `<b>Jeste li sigurni da želite obrisati pohvalu?</b>`,
      buttons: [{
            text: "DA",
            onClick: (e) => {
                return { buttonText: e.component.option("text") }
            }
        },{
          text: "ODUSTANI",
          onClick: (e) => {
              return { buttonText: e.component.option("text") }
          }
      }]
    });
    myDialog.show().then((dialogResult) => {
      if(dialogResult.buttonText === 'DA'){
        this._api.obrisi3P({
          'idprijava': row.data.idprijava,
          'access_token': userToken
        }).subscribe(res=>{
          if(res){
            this.dohvatiPohvale();
          } else {
            notify(res.message, "error", 1000);
          }
        });
      } 
    });
  }

}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    DxCheckBoxModule,
    AddPohvalaFormComponentModule,
    EditPohvalaFormComponentModule
  ],
  declarations: [ PohvaleTablicaComponent ],
  exports: [ PohvaleTablicaComponent ]
})
export class PohvaleTablicaComponentModule { }
