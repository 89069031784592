import { Component, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { IDogadjaj } from 'src/app/shared/models/dogadjaj.model';
import { AuthService } from 'src/app/shared/services';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-dogadjanja',
  templateUrl: './dogadjanja.component.html',
  styleUrls: ['./dogadjanja.component.scss']
})
export class DogadjanjaComponent implements OnInit {
  public dogadjanja: IDogadjaj[];

  constructor(
    private _api: ApiService,
    private _authService: AuthService
  ) { }

  ngOnInit(): void {
    this.dohvatiDogadjanja();
  }

  dohvatiDogadjanja(){
    const partnerId = this._authService.getUserPartnerId();
    const userToken = this._authService.getToken();

    this._api.dohvatiDogadjanja({
      'idpartner': partnerId,
      'access_token': userToken
    }).subscribe(res=>{
      if(res){
        this.dogadjanja = res.data;
      } else {
        notify("Greška prilikom dohvata dogadjanja!", "error", 1000);
      }
    });
  };

}
