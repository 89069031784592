<div class="content-block prijave-tablica-container" *ngIf="prijave">

        <dx-data-grid
          #prijaveTablica
          id="prijave-datagrid"
          class="prijave-datagrid"
          [rowAlternationEnabled]="true"
          [hoverStateEnabled]="true"
          [dataSource]="prijave"
          keyExpr="idprijava"
          [wordWrapEnabled]="true"
          [showRowLines]="true"
          [showBorders]="true"
          [columnAutoWidth]="false"
          [columnHidingEnabled]="false"
          (onToolbarPreparing)="onToolbarPreparing($event)"
          [height]="774">

          <dxo-load-panel [enabled]="true"></dxo-load-panel>
          <dxo-header-filter [visible]="true"></dxo-header-filter>
          <dxo-filter-row [visible]="false"></dxo-filter-row>
          <dxo-search-panel [visible]="true" [width]="240" placeholder="Pretraži..."></dxo-search-panel>
          
          <div *dxTemplate="let data of 'naslovTablice'">
            <div class="prijave-tablica-container__header-content-container">
              <p class="prijave-tablica-container__header-content-container__title">Popis prijava: {{prijave && prijave.length}}</p>
              <dx-button
                icon="add" type="default"
                hint="Dodaj prijavu" stylingMode="contained" 
                (onClick)="dodajPrijavu()">
              </dx-button>
              <dx-check-box text="Samo otvorene prijave" 
                [value]="prijaveFilterChecked" (onValueChanged)="onPrijveFilterChange($event)">
              </dx-check-box>
            </div>
          </div>

          <dxi-column dataField="idprijava" [visible]="false"></dxi-column>
          <dxi-column dataField="pr_naziv" caption="Naslov" [allowFiltering]="true"></dxi-column>
          <dxi-column dataField="pr_opis" caption="Opis" [allowFiltering]="true"></dxi-column>
          <dxi-column dataField="vrpr_naziv" caption="Vrsta" [allowFiltering]="true"></dxi-column>
          <dxi-column dataField="pr_adresa" caption="Lokacija" [allowFiltering]="true"></dxi-column>

          <dxi-column caption="Fotografija" type="buttons" name="photo" id="photo-icon-container"><dxi-button template="fotografije">
          </dxi-button></dxi-column>
            <div *dxTemplate="let buttonInfo of 'fotografije'" class="dx-command-edit-with-icons"> 
              <dx-button icon="image" id="btn" hint="Prikaži fotografije" (click)="openImage(buttonInfo)" class="dx-command-edit-with-icons"></dx-button>
            </div>
            
          <dxi-column dataField="pr_status" caption="Status" alignment="center" cellTemplate="status" [allowFiltering]="false"></dxi-column>
            <div *dxTemplate="let data of 'status'">
              <div *ngIf="data.data.pr_status === 1">
                  Zaprimljeno
              </div>
              <div *ngIf="data.data.pr_status === 2">
                  U postupku
              </div>
              <div *ngIf="data.data.pr_status === 3">
                  Riješeno
              </div>
            </div>

          <dxi-column dataField="pr_prikaziMob" caption="Prikaz" alignment="center" cellTemplate="approveTemplate" class="partners-datagrid__actions-row"  width="110"  [allowHeaderFiltering]="false"></dxi-column>
            <div *dxTemplate="let row of 'approveTemplate'">
              <dx-check-box [(value)]="row.value" (onValueChanged)="odobri(row)"></dx-check-box>
            </div>

          <dxi-column alignment="center" cellTemplate="editTemplate" class="partners-datagrid__actions-row"></dxi-column>
            <div *dxTemplate="let row of 'editTemplate'" class="partners-datagrid__actions-row__actions">
              <dx-button (onClick)="urediPrijavu(row)" icon="edit" stylingMode="contained" type="default"></dx-button>
              <dx-button *ngIf="userType != 0" (onClick)="obrisiPrijavu(row)" icon="trash" stylingMode="contained" type="danger"></dx-button>
            </div>

          <dxo-paging [pageSize]="10"> </dxo-paging>
          <dxo-pager
            [visible]="true"
            [allowedPageSizes]="[10, 30, 50]"
            [displayMode]="'full'"
            [showPageSizeSelector]="true"
            [showInfo]="true"
            [showNavigationButtons]="true">
          </dxo-pager>
        </dx-data-grid>

</div>

<dx-popup
  [closeOnOutsideClick]="false"
  [showCloseButton]="true"
  [(visible)]="popupImageVisible"
  [height]="700"
  [width]="900"
  title="Fotografije prijave">
  <dx-gallery
    #gallery
    id="gallery"
    [dataSource]="slikaZaPrikaz"
    [initialItemWidth]="800"
    [stretchImages]="true"
    [wrapAround]="false"
  ></dx-gallery>
</dx-popup>

<add-prijava-form
    [isPopupVisible]="isAddPrijavaFormVisible"
    (openModalChange)="onAddPrijavaPopupChange($event)"
    (prijavaCreated)="onPrijavaChange($event)">
</add-prijava-form>

<edit-prijava-form
    *ngIf="isEditPrijavaFormVisible && odabranaPrijavaId"
    [prijavaId]="odabranaPrijavaId"
    [isPopupVisible]="isEditPrijavaFormVisible"
    (openModalChange)="onEditPrijavaPopupChange($event)"
    (prijavaUpdated)="onPrijavaChange($event)">
</edit-prijava-form>
