<dx-popup
    title="UREDI ODJEL"
    [width]="500"
    [height]="400"
    [(visible)]="isPopupVisible" 
    [closeOnOutsideClick]="true"
    [showCloseButton]="true"
    (onHiding)="closePopup()"
    (onShown)="onShown($event)">

    <dx-scroll-view width="100%" height="100%">

        <form (submit)="onFormSubmit($event)">

                <dx-form
                    #editOdjelForm
                    *ngIf="noviOdjel"
                    id="edit-odjel-form"
                    class="edit-odjel-form"
                    [(formData)]="noviOdjel"
                    [showColonAfterLabel]="true"
                    (onFieldDataChanged)="form_fieldDataChanged($event)"
                    [colCount]="1"
                    [showValidationSummary]="true"
                    validationGroup="EditOdjelData">
        
                    <dxi-item [label]="{text: 'Oznaka'}" dataField="od_oznaka" editorType="dxTextBox">
                        <dxi-validation-rule type="required" message="Oznaka je nužna.">
                        </dxi-validation-rule>
                    </dxi-item>
    
                    <dxi-item [label]="{text: 'Naziv odjela'}" dataField="od_naziv" editorType="dxTextBox">
                        <dxi-validation-rule type="required" message="Naziv odjela je nužan."></dxi-validation-rule>
                        <dxi-validation-rule type="stringLength" [min]="5" message="Naziv mora sadržavati minimalno 5 znakova"></dxi-validation-rule>
                    </dxi-item>
        
                </dx-form>

                <dxi-item class="edit-odjel-form__button-group">
                    <dx-button
                        text="Spremi"
                        type="success"
                        validationGroup="EditOdjelData"
                        [useSubmitBehavior]="true">
                    </dx-button>
                    <dx-button
                        text="Odustani"
                        type="danger"
                        (onClick)="closePopup()">
                    </dx-button>
                </dxi-item>

        </form>

    </dx-scroll-view>

</dx-popup>