export enum PPPMenuItemType{
    PRIJAVE = "PRIJAVE",
    PRIJEDLOZI = "PRIJEDLOZI",
    POHVALE = "POHVALE",
}

export interface IMenuItemProps {
  type: PPPMenuItemType;
  isActive: boolean;
}

export class MenuItemProps implements IMenuItemProps {
    constructor(
        public type: PPPMenuItemType,
        public isActive: boolean,
    ) {}
}


export class MenuItems {    
    public items: IMenuItemProps[] = [];
    public defaultPPPMenuItems: IMenuItemProps[] = [
        {
            type: PPPMenuItemType.PRIJAVE,
            isActive: true,
        },
        {
            type: PPPMenuItemType.PRIJEDLOZI,
            isActive: false,
        },
        {
            type: PPPMenuItemType.POHVALE,
            isActive: false,
        }
    ];
  
  
    constructor() {
      let items = this.defaultPPPMenuItems;
  
      for (let item of items) {
        this.items.push(new MenuItemProps(item.type, item.isActive));
      }
    }
}
