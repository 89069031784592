import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';

import { ApiService } from 'src/app/shared/services/api.service';
import notify from 'devextreme/ui/notify';

const notificationText = 'Poslali smo poveznicu za poništavanje vaše lozinke. Provjerite svoj inbox.';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent {

  loading = false;
  formData: any = {};

  constructor(
    public _api: ApiService,
    private router: Router
  ) { }

  async onSubmit(e: Event) {

    e.preventDefault();
    const { email } = this.formData;
    this.loading = true;

    this._api.zatraziSifru({'email': email}).subscribe(res=>{
      if(res.success == true){
        this.loading = false;
        this.router.navigate(['/login']);
        notify(notificationText, 'success', 2500);
      } else {
        this.loading = false;
        notify("Unesena e-mail adresa ne postoji!", "error", 1000);
      }
    });

  }
}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule
  ],
  declarations: [ResetPasswordFormComponent],
  exports: [ResetPasswordFormComponent]
})
export class ResetPasswordFormModule { }
