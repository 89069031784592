import { Component, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DxButtonModule, DxCheckBoxModule, DxDataGridModule, DxGalleryModule, DxPopupModule } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';
import dxDataGrid from 'devextreme/ui/data_grid';

import { AuthService } from '../../../services';
import { ApiService } from '../../../services/api.service';
import { IPPP, PPPVrsta, PrijavaStatusId } from '../../../models/ppp.model';
import { AddPrijavaFormComponentModule } from '../../forme/add-prijava-form/add-prijava-form.component';
import { EditPrijavaFormComponentModule } from '../../forme/edit-prijava-form/edit-prijava-form.component';

import { KorisnikType } from 'src/app/shared/models/korisnik.model';


@Component({
  selector: 'prijave-tablica',
  templateUrl: 'prijave-tablica.component.html',
  styleUrls: ['./prijave-tablica.component.scss']
})

export class PrijaveTablicaComponent implements OnInit {
  @Input()
  prijave!: IPPP[];
  @ViewChild("prijaveTablica") prijaveTablica: dxDataGrid
  odabranaPrijavaId: number;

  isAddPrijavaFormVisible: boolean = false;
  isEditPrijavaFormVisible: boolean = false;
  
  prijaveFilterChecked: boolean = false;
  slikaZaPrikaz: any;
  popupImageVisible: boolean = false;

  userType: any;

  constructor(
    private _authService: AuthService,
    private _api: ApiService,
  ) {
    this.userType = this._authService.getUserType();
  }

  ngOnInit() {
    this.dohvatiPrijave();
  }

  onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift({
    location: 'before',
    template: 'naslovTablice',    
      }
    )
  }

  onPrijveFilterChange(e){
    const filtrirajPoOtvorenimPrijavama = e.value;
    this.prijaveFilterChecked = filtrirajPoOtvorenimPrijavama;

    if(filtrirajPoOtvorenimPrijavama){
      this.prijave = this.prijave.filter(item => item.pr_status != PrijavaStatusId.RIJESENO);
    } else{ 
      this.dohvatiPrijave(); 
    }
  };

  onPrijavaChange(isChanged){
    if(isChanged) {
      this.prijaveFilterChecked = false;
      this.dohvatiPrijave();
    }
  }

  onAddPrijavaPopupChange(isVisible: boolean){
    this.isAddPrijavaFormVisible = isVisible;
  }

  onEditPrijavaPopupChange(isVisible: boolean){
    this.isEditPrijavaFormVisible = isVisible;
    this.dohvatiPrijave();
  }

  dodajPrijavu(){
    this.isAddPrijavaFormVisible = true;
  }

  urediPrijavu(row){
    const prijava: IPPP = row.data; 
    this.odabranaPrijavaId = prijava.idprijava;
    this.isEditPrijavaFormVisible = true;
  }

  openImage(e){
    this.slikaZaPrikaz=[]
    for(let i=0;i<e.data.slike.length;i++){
      this.slikaZaPrikaz.push(e.data.slike[i].putanja)
      this.slikaZaPrikaz.join(",")
    }
    if(this.slikaZaPrikaz.length == 0){
      this.popupImageVisible = false;
      notify("Ne postoji slika događanja!", "info", 1000);
    }else this.popupImageVisible=true;
  }

  dohvatiPrijave(){
    this._api.dohvati3P(this.generatePrijaveRequest()).subscribe(res=>{
      if(res){
        const ppp = res.data;
        this.initPPPTypes(ppp);
      } else {
        notify("Greška prilikom dohvata prijava!", "error", 1000);
      }
    });
  }

  initPPPTypes(pppList: IPPP []){
    this.prijave = [];
    pppList.map(ppp => {
      switch(ppp.pr_vrsta){
        case PPPVrsta.PRIJAVA:
          this.prijave.push(ppp);
          break;
      }
    });
  }

  generatePrijaveRequest(){
    const userId = this._authService.getUserId();
    const userToken = this._authService.getToken();
    const userType: KorisnikType = this._authService.getUserType();
    const partnerId = this._authService.getUserPartnerId();
    let requestData: any;

    if(userType === KorisnikType.NACELNIK){
      return requestData = {
        'idgost': false,
        'idpartner': partnerId,
        'access_token': userToken
      }
    }
    else{
      return requestData = {
        'idgost': false,
        'idpartner': partnerId,
        'idkorisnik': userId,
        'access_token': userToken
      }
    }
  }

  odobri(row){
    this._api.urediVidljivost({
      'idprijava': row.data.idprijava,
      'vidljiv': row.value,
      'access_token': this._authService.getToken()
    }).subscribe(res=>{
      if(res){
        notify(res.message, "success", 1000);
      } else {
        notify("Greška!", "error", 1000);
      }
    });
  }

  obrisiPrijavu(row){
    const userToken = this._authService.getToken();
    let myDialog = custom({
      title: "BRISANJE",
      messageHtml: `<b>Jeste li sigurni da želite obrisati prijavu?</b>`,
      buttons: [{
            text: "DA",
            onClick: (e) => {
                return { buttonText: e.component.option("text") }
            }
        },{
          text: "ODUSTANI",
          onClick: (e) => {
              return { buttonText: e.component.option("text") }
          }
      }]
    });
    myDialog.show().then((dialogResult) => {
      if(dialogResult.buttonText === 'DA'){
        this._api.obrisi3P({
          'idprijava': row.data.idprijava,
          'access_token': userToken
        }).subscribe(res=>{
          if(res){
            this.dohvatiPrijave();
          } else {
            notify(res.message, "error", 1000);
          }
        });
      } 
    });
  }
  
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    DxCheckBoxModule,
    AddPrijavaFormComponentModule,
    EditPrijavaFormComponentModule,
    DxPopupModule,
    DxGalleryModule
  ],
  declarations: [ PrijaveTablicaComponent ],
  exports: [ PrijaveTablicaComponent ]
})
export class PrijaveTablicaComponentModule { }
