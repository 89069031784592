import { Component, NgModule, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { UserPanelModule } from '../../user-panel/user-panel.component';
import { ApiService } from '../../../services/api.service';
import { DxButtonGroupModule, DxCheckBoxModule, DxDateBoxModule, DxFormComponent, DxFormModule, DxPopupModule, DxScrollViewModule, DxTextAreaModule, DxValidatorModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { AuthService } from '../../../services';
import notify from 'devextreme/ui/notify';
import { Dogadjaj, IDogadjaj } from 'src/app/shared/models/dogadjaj.model';
import * as moment from 'moment';
import { ImageSnippet } from 'src/app/shared/models/common.model';

@Component({
  selector: 'add-dogadjanje-form',
  templateUrl: 'add-dogadjanje-form.component.html',
  styleUrls: ['./add-dogadjanje-form.component.scss']
})

export class AddDogadjanjeFormComponent implements OnInit {
  @ViewChild("addDogadjajForm", { static: false }) addDogadjajForm: DxFormComponent

  @Input()
  isPopupVisible: boolean = false;
  @Input()
  partnerId!: number;
  
  @Output() openModalChange = new EventEmitter<boolean>();
  @Output() dogadjajCreated = new EventEmitter<boolean>();
  
  dogadjaj: IDogadjaj;

  popupImageVisible: boolean=false;
  slikeZaPrikaz: string[];
  slike: any=[];
  triSlike:any=[];
  noveSlike:any=[];
  obslike: any[]=[];
  slikeFiles: any=[];

  constructor(
    private _api: ApiService,
    private _authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.initDogadjajValues();
  }

  refresh() {
    this.addDogadjajForm.instance.resetValues();
  }

  closePopup(): void {
    this.refresh();
    this.initDogadjajValues();
    this.openModalChange.emit(false);
  }

  openPopup(): void {
    this.openModalChange.emit(true);
  }

  onShown(e){
    this.addDogadjajForm.instance.getEditor("do_datum").focus();
  }

  form_fieldDataChanged (e) {
    let updatedField = e.dataField;
    let newValue = e.value;

    if(updatedField === "do_aktivan"){
      this.dogadjaj.do_aktivan = newValue ? 1 : 0;
    }
  }

  initDogadjajValues(){
    this.dogadjaj = new Dogadjaj();
  }

  onFormSubmit(e: any){
    e.preventDefault();
    this.submitForm();
  }

  submitForm(){
    this.dodajDogadjaj();
  }

  dodajDogadjaj(){
    const request = this.buildRequestFormData();

    this._api.dodajDogadjaj(request).subscribe(res=>{
      // console.log(res)
      if(res.success == true){
        // console.log(res);
        notify("Uspješno ste dodali događaj!", "success", 1000);
        this.closePopup();
        this.dogadjajCreated.emit(true);
        this.triSlike.splice(0,1);
        this.noveSlike.splice(0,1);
      } else {
        notify("Greška prilikom dodavanja događaja!", "error", 1000);
      }
    });
  }

  formatDate(dateValue){
    const selectedDate = new Date(dateValue);
    const newDateFormated = moment(selectedDate).format('YYYY-MM-DD HH:mm:ss').toString();
    return newDateFormated;
  }

  buildRequestFormData(){
    let formData:FormData = new FormData();
    formData.append('datum', this.formatDate(this.dogadjaj.do_datum));
    formData.append('naslov', this.dogadjaj.do_naslov);
    formData.append('opis', this.dogadjaj.do_opis);
    formData.append('web', this.dogadjaj.do_web);
    formData.append('aktivan', this.dogadjaj.do_aktivan.toString());
    formData.append('idkorisnik', this._authService.getUserId().toString());
    formData.append('idpartner', this._authService.getUserPartnerId().toString());
    for(let i=0;i<this.noveSlike.length;i++){
      formData.append('datoteke', this.noveSlike[i])
    }
    formData.append('access_token', this._authService.getToken().toString());
    return formData;
  }

  processFile(imageInput: any) {
    for(let i=0;i<imageInput.files.length;i++){
      const file: File = imageInput.files[i];
      const reader = new FileReader();


      reader.addEventListener('load', (event: any) => {
        if(this.triSlike.length===3){
          notify("Možete odabrati samo tri fotografije!",'error')
        }else{
          this.triSlike.unshift(new ImageSnippet(event.target.result, file));
          this.noveSlike.push(file);
          this.triSlike[0]=this.triSlike[0].src;
        }
      });

      reader.readAsDataURL(file);
    }
  }

  openImage(){
    this.slike=[];
    this.popupImageVisible=true;
    for(let i=0;i<this.triSlike.length;i++){
      this.slike.push(this.triSlike[i])
      this.slike.join(",");
    }
    this.slikeZaPrikaz=this.slike;
  }

  remove(slika,i){
    for(let j=0;j<this.slikeFiles.length;j++){
      if(slika===this.slikeFiles[j].putanja){
        this.obslike.push(this.slikeFiles[j].idslikadogadjaj);
        this.slikeFiles.splice(j,1);
      }
    }
    this.noveSlike.splice(i,1);
    this.triSlike.splice(i,1);
  }

}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxPopupModule,
    DxFormModule,
    DxScrollViewModule,
    DxButtonGroupModule,
    DxCheckBoxModule,
    DxValidatorModule,
    DxTextAreaModule,
    DxDateBoxModule,
  ],
  declarations: [ AddDogadjanjeFormComponent ],
  exports: [ AddDogadjanjeFormComponent ]
})
export class AddDogadjanjeFormComponentModule { }
