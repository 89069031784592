import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/shared/services';

import { custom } from 'devextreme/ui/dialog';
import { encodeHtml } from 'devextreme/core/utils/string';

@Component({
  selector: 'app-odjava',
  templateUrl: './odjava.component.html',
  styleUrls: ['./odjava.component.scss']
})
export class OdjavaComponent implements OnInit {

  constructor(
    private _authService: AuthService
  ) { }

  ngOnInit(): void {
  //   let encodedMessage = encodeHtml("Jeste li sigurni da se želite odjaviti?");
  //   let myDialog = custom({
  //       title: "ODJAVA",
  //       messageHtml: encodedMessage,
  //       buttons: [{
  //           text: "DA",
  //           onClick: (e) => {
  //               return { buttonText: e.component.option("text") }
  //           }
  //       },{
  //         text: "ODUSTANI",
  //         onClick: (e) => {
  //             return { buttonText: e.component.option("text") }
  //         }
  //     }]
  //   });
  //   myDialog.show().then((dialogResult) => {
  //      if(dialogResult.buttonText === 'DA'){
  //       this._authService.logOut();
  //      } 
  //  });
    this._authService.logOut();
  }

}