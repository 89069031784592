<div class="content-block dogadjanja-tablica-container">

  <dx-data-grid
    
      id="dogadjanja-datagrid"
      class="dogadjanja-datagrid"
      [rowAlternationEnabled]="true"
      [hoverStateEnabled]="true"
      [dataSource]="dogadjanja"
      keyExpr="iddogadjaj"
      [wordWrapEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      [columnAutoWidth]="false"
      [columnHidingEnabled]="false"
      [height]="824"
      (onToolbarPreparing)="onToolbarPreparing($event)">

      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-search-panel [visible]="true" [width]="240" placeholder="Pretraži..."></dxo-search-panel>
      
      <div *dxTemplate="let data of 'naslovTablice'">
        <div class="dogadjanja-tablica-container__header-content-container">
          <p class="dogadjanja-tablica-container__header-content-container__title">Popis događanja: {{dogadjanja && dogadjanja.length}}</p>
          <dx-button
            icon="add" type="default"
            hint="Dodaj događaj" stylingMode="contained" 
            (onClick)="dodajDogadjaj()">
        </dx-button>
        </div>
      </div>

      <dxi-column dataField="iddogadjaj" [visible]="false"></dxi-column>
      <dxi-column dataField="do_datum" caption="Datum" [calculateCellValue]="formatDateCell" [width]="90"></dxi-column>
      <dxi-column dataField="do_naslov" caption="Naziv" [allowFiltering]="false"></dxi-column>
      <dxi-column dataField="do_opis" caption="Opis" [allowFiltering]="false"></dxi-column>
      <dxi-column dataField="do_web" caption="Web" cellTemplate="linkTemplate">
        <div *dxTemplate="let data of 'linkTemplate'">
          <a href="{{data.value}}" target="_blank" style="text-decoration: none;">{{data.value}}</a>
        </div>
      </dxi-column>
      <dxi-column dataField="kor_ime" caption="Dodao"></dxi-column>

      <dxi-column caption="Fotografija" type="buttons" name="photo" id="photo-icon-container"><dxi-button template="fotografije">
      </dxi-button>
        <div *dxTemplate="let buttonInfo of 'fotografije'" class="dx-command-edit-with-icons"> 
          <dx-button icon="image" id="btn" hint="Prikaži fotografije" (click)="openImage(buttonInfo)" class="dx-command-edit-with-icons"></dx-button>
        </div>
      </dxi-column>

      <dxi-column dataField="do_aktivan" caption="AKTIVAN" alignment="center" cellTemplate="aktivan" [allowFiltering]="false"> 
        <div *dxTemplate="let data of 'aktivan'" >                    
          <dx-check-box [(value)]="data.value" readOnly="true"></dx-check-box>
        </div>
      </dxi-column>

      <dxi-column alignment="center" cellTemplate="editTemplate" class="dogadjanja-datagrid__actions-row"></dxi-column>
        <div *dxTemplate="let row of 'editTemplate'" class="dogadjanja-datagrid__actions-row__actions">
          <dx-button (onClick)="urediDogadjaj(row)" icon="edit" stylingMode="contained" type="default"></dx-button>
          <dx-button (onClick)="openObrisiDogadjaj(row)" icon="trash" stylingMode="contained" type="danger"></dx-button>
        </div>

      <dxo-paging [pageSize]="15"></dxo-paging>
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[15, 30, 50]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true">
      </dxo-pager>
  </dx-data-grid>

</div>

<dx-popup
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="popupImageVisible"
  [height]="700"
  [width]="900"
  title="Fotografije prijave">
  <dx-gallery
    #gallery
    id="gallery"
    [dataSource]="slikaZaPrikaz"
    [initialItemWidth]="800"
    [stretchImages]="true"
    [wrapAround]="false"
  ></dx-gallery>
</dx-popup>

<add-dogadjanje-form
    [isPopupVisible]="isAddDogadjajFormVisible"
    (openModalChange)="onAddDogadjajPopupChange($event)"
    (dogadjajCreated)="onDogadjajChange($event)">
</add-dogadjanje-form>

<edit-dogadjanje-form
    *ngIf="isEditDogadjajFormVisible && odabraniDogadjajId"
    [dogadjajId]="odabraniDogadjajId"
    [isPopupVisible]="isEditDogadjajFormVisible"
    (openModalChange)="onEditDogadjajPopupChange($event)"
    (dogadjajUpdated)="onDogadjajChange($event)">
</edit-dogadjanje-form>
