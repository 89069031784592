import { Component, OnInit } from '@angular/core';
import { TipVrsteProblema } from 'src/app/shared/models/common.model';


@Component({
  selector: 'app-sifarnik-vrste-problema',
  templateUrl: './sifarnik-vrste-problema.component.html',
  styleUrls: ['./sifarnik-vrste-problema.component.scss']
})
export class SifarnikVrsteProblemaComponent implements OnInit {
  tipVrsteProblema: TipVrsteProblema = TipVrsteProblema.DEFAUL_VRSTA_PROBLEMA;

  constructor( ) { }

  ngOnInit(): void { }

}
