import { Component, NgModule, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { UserPanelModule } from '../../user-panel/user-panel.component';
import { ApiService } from '../../../services/api.service';
import { DxButtonGroupModule, DxCheckBoxModule, DxDateBoxModule, DxFormComponent, DxFormModule, DxPopupModule, DxScrollViewModule, DxTextAreaModule, DxValidatorModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import notify from 'devextreme/ui/notify';
import { AuthService } from '../../../services';
import { IPPP } from 'src/app/shared/models/ppp.model';
import { IObavijest, Obavijest } from 'src/app/shared/models/obavijesti.model';
import * as moment from 'moment';


@Component({
  selector: 'edit-obavijest-form',
  templateUrl: 'edit-obavijest-form.component.html',
  styleUrls: ['./edit-obavijest-form.component.scss']
})

export class EditObavijestFormComponent implements OnInit {
  @ViewChild("editObavijestForm", { static: false }) editObavijestForm: DxFormComponent;

  @Input()
  obavijestId!: number;
  @Input()
  isPopupVisible: boolean = false;

  @Output() openModalChange = new EventEmitter<boolean>();
  @Output() obavijestUpdated = new EventEmitter<boolean>();

  novaObavijest: IObavijest;

  constructor(
    private _api: ApiService,
    private _authService: AuthService,
  ) {  }

  ngOnInit(): void {
    this.dohvatiObavijest();
  }

  onFormSubmit(e){
    e.preventDefault();
    this.submitForm();
  }

  closePopup(): void {
    this.openModalChange.emit(false);
  }
  openPopup(): void {
    this.openModalChange.emit(true);
  }

  form_fieldDataChanged (e) {
    let updatedField = e.dataField;
    let newValue = e.value;

    if(updatedField === "ob_aktivna"){
      this.novaObavijest.ob_aktivna = newValue ? 1 : 0;
    }
  }

  submitForm(){
    this.urediObavijest();
  }

  onShown(e){
    this.editObavijestForm.instance.getEditor("ob_datum").focus();
  }

  dohvatiObavijest(){
    this._api.dohvatiObavijest({
      'idobavijest': this.obavijestId,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      // console.log(res)
      if(res.success == true){
        const obavijest: IObavijest = res.data[0];
        this.novaObavijest = obavijest;
      }
    });
  }

  urediObavijest(){
    this._api.urediObavijest({
      'idobavijest': this.novaObavijest.idobavijest,
      'datum': this.formatDate(this.novaObavijest.ob_datum),
      'tekst': this.novaObavijest.ob_tekst,
      'web': this.novaObavijest.ob_web,
      'aktivna': this.novaObavijest.ob_aktivna,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res.success == true){
        notify("Uspješno ste uredili obavijest!", "success", 1000);
        this.closePopup();
        this.obavijestUpdated.emit(true);
      } else {
        notify("Greška prilikom uređivanja obavijesti!", "error", 1000);
      }
    });
  }

  formatDate(dateValue){
    const selectedDate = new Date(dateValue);
    const newDateFormated = moment(selectedDate).format('YYYY-MM-DD HH:mm:ss').toString();
    return newDateFormated;
  }

}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxPopupModule,
    DxFormModule,
    DxScrollViewModule,
    DxButtonGroupModule,
    DxCheckBoxModule,
    DxDateBoxModule,
    DxTextAreaModule,
    DxValidatorModule
  ],
  declarations: [ EditObavijestFormComponent ],
  exports: [ EditObavijestFormComponent ]
})
export class EditObavijestFormComponentModule { }
