<div *ngIf="pravaAdministrator || pravaNacelnik">
<div class="dx-field">
   <div class="dx-field-value">
      <dx-radio-group
        [items]="popisUloga"
        valueExpr="id"
        displayExpr="naziv"
        layout="horizontal"
        [(value)]="trenutnaVrijednost"
        (onValueChanged)="onValueChanged($event)"
      >
      </dx-radio-group>
    </div>
  </div>
</div>