import { Component, NgModule, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { UserPanelModule } from '../user-panel/user-panel.component';
import { DxButtonGroupModule, DxCheckBoxModule, DxFormModule, DxPopupModule, DxRadioGroupModule, DxScrollViewModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { AuthService } from '../../services';
import { KorisnikType } from '../../models/korisnik.model';
import { Uloga } from 'src/app/shared/models/common.model';

@Component({
    selector: 'odabir-vrste-korisnika',
    templateUrl: 'odabir-vrste-korisnika.component.html',
    styleUrls: ['./odabir-vrste-korisnika.component.scss']
  })


  export class OdabirVrsteKorisnikaComponent implements OnInit{
    @Input() trenutnaVrijednost!: number;
    @Output() trenutnaVrijednostPromjena = new EventEmitter<KorisnikType>();

    popisUloga: Uloga[];
    pravaAdministrator: boolean = false;
    pravaNacelnik: boolean = false;

    constructor(
        private _authService: AuthService,
    ) {  }

    ngOnInit(): void {
        this.provjeriPrava();
    }
    
    onValueChanged(event){
        this.trenutnaVrijednostPromjena.emit(this.trenutnaVrijednost);
    }

    provjeriPrava(){
        const prava: KorisnikType = this._authService.getUserNivoPrava();
        switch(prava){
        case KorisnikType.ZAPOSLENIK: 
            break;
        case KorisnikType.NACELNIK: 
            this.pravaNacelnik = true;
            this.popisUloga = [new Uloga(0,"Zaposlenik"), new Uloga(1, "Načelnik"), new Uloga(-1, "Vanjski")];
            break;
        case KorisnikType.ADMIN: 
            this.pravaAdministrator = true;
            this.popisUloga = [new Uloga(0,"Zaposlenik"), new Uloga(1, "Načelnik"), new Uloga(2, "Administrator"), new Uloga(-1, "Vanjski")];
            break;
        default: 
            break;
        }
    }

  }

  @NgModule({
    imports: [
      BrowserModule,
      CommonModule,
      DxButtonModule,
      UserPanelModule,
      DxToolbarModule,
      DxPopupModule,
      DxFormModule,
      DxScrollViewModule,
      DxButtonGroupModule,
      DxCheckBoxModule,
      DxRadioGroupModule
    ],
    declarations: [ OdabirVrsteKorisnikaComponent ],
    exports: [ OdabirVrsteKorisnikaComponent ]
  })
  export class OdabirVrsteKorisnikaModule { }