  <div class="content-block zaduzene-osobe-tablica-container">

    <dx-data-grid
      id="zaduzene-osobe-datagrid"
      class="zaduzene-osobe-datagrid"
      [rowAlternationEnabled]="true"
      [hoverStateEnabled]="true"
      [dataSource]="zaduzeneOsobe"
      keyExpr="idkorisnik"
      [wordWrapEnabled]="true"
      [showRowLines]="true"
      [showBorders]="false"
      [columnAutoWidth]="false"
      [columnHidingEnabled]="false"
      (onToolbarPreparing)="onToolbarPreparing($event)"
      [height]="300">

      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-search-panel [visible]="true" [width]="240" placeholder="Pretraži..."></dxo-search-panel>
      
      <div *dxTemplate="let data of 'naslovTablice'">
        <div class="zaduzene-osobe-tablica-container__header-content-container">
          <p class="zaduzene-osobe-tablica-container__header-content-container__title">Zadužene osobe: {{zaduzeneOsobe && zaduzeneOsobe.length}}</p>
          <dx-button
            icon="edit" type="default"
            hint="Uredi zaduženja" stylingMode="contained" 
            text="Uredi zaduženja"
            (onClick)="onUrediZaduzenuOsobu()">
        </dx-button>
        </div>
      </div>

      <dxi-column dataField="idkorisnik" [visible]="false"></dxi-column>
      <dxi-column dataField="kor_ime" caption="Ime"></dxi-column>
      <dxi-column dataField="kor_prezime" caption="Prezime"></dxi-column>

      <dxo-paging [pageSize]="5"></dxo-paging>
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[5, 10, 15]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true">
      </dxo-pager>

    </dx-data-grid>

</div>

 <edit-zaduzenja-osoba-form
    *ngIf="zaduzeneOsobeIds && isAddZaduzenaOsobaFormVisible"
    [zaduzeneOsobeIDs]="zaduzeneOsobeIds"
    [isPopupVisible]="isAddZaduzenaOsobaFormVisible"
    (openModalChange)="onAddZaduzenaOsobaPopupChange($event)"
    (zaduzeneOsobeChange)="onZaduzeneOsobeChange($event)">
</edit-zaduzenja-osoba-form>
