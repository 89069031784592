import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {

  private prijedlog = new BehaviorSubject(false);
  currentMessage = this.prijedlog.asObservable();

  private pohvala = new BehaviorSubject(false);
  currentMessage2 = this.pohvala.asObservable();

  constructor() { }

  changeMessage(isPrijedlog: boolean) {
    this.prijedlog.next(isPrijedlog)
  }

  changeMessage2(isPohvala: boolean){
    this.pohvala.next(isPohvala)
  }

}