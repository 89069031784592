import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DxButtonModule, DxDataGridModule } from 'devextreme-angular';
import { AuthService } from 'src/app/shared/services';
import { ApiService } from 'src/app/shared/services/api.service';
import { IKorisnik, KorisnikType } from '../../../models/korisnik.model';
import { AddKorisnikModule } from '../../forme/add-korisnik-form/add-korisnik-form.component';
import { EditKorisnikModule } from '../../forme/edit-korisnik-form/edit-korisnik-form.component';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'korisnici-tablica',
  templateUrl: './korisnici-tablica.component.html',
  styleUrls: ['./korisnici-tablica.component.scss']
})
export class KorisniciTablicaComponent implements OnInit {
  @Input()
  partnerId!: number;
  @Input()
  korisnici: IKorisnik[];

  odabraniKorisnik: IKorisnik;

  isAddKorisnikFormVisible: boolean = false;
  isEditKorisnikFormVisible: boolean = false;

  constructor(
    private _api: ApiService,
    private _authService: AuthService
  ) { }

  ngOnInit(): void { }

  onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift({
    location: 'before',
    template: 'naslovTablice',    
      }
    )
  }

  onKorisniciChange(isChanged){
    if(isChanged) this.dohvatiKorisnike();
  }

  dohvatiKorisnike(){
    this._api.dohvatiKorisnike({
      'idpartner': this.partnerId || this._authService.getUserPartnerId(),
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      // console.log(res)
      if(res){
        this.korisnici = res.data;
        this.korisnici = this.korisnici.map(korisnik => {return {...korisnik, kor_admin: korisnik.kor_prava}});
        this.korisnici = this.korisnici.filter(korisnik => korisnik.kor_prava <= this._authService.getUserNivoPrava());
      } else {
        notify("Greška prilikom dohvata modula!", "error", 1000);
      }
    });
  }

  onAddKorisnikPopupChange(isVisible: boolean){
    this.isAddKorisnikFormVisible = isVisible;
  }

  onEditKorisnikPopupChange(isVisible: boolean){
    this.isEditKorisnikFormVisible = isVisible;
  }

  dodajKorisnika(){
    this.isAddKorisnikFormVisible = true;
  }

  urediKorisnika(row){
    const korisnik: IKorisnik = row.data;
    this.odabraniKorisnik = korisnik;
    this.isEditKorisnikFormVisible = true;
  }

  // onDeleteBtnClick(e: any):void {}

  korPrava_calculateCellValue (rowData: IKorisnik) {
    const prava: KorisnikType = rowData.kor_prava;
    let nazivPrava = "";
    switch(prava){
      case KorisnikType.ZAPOSLENIK: 
        nazivPrava = "Zaposlenik";
        break;
      case KorisnikType.NACELNIK: 
        nazivPrava = "Načelnik";
        break;
      case KorisnikType.ADMIN: 
        nazivPrava = "Administrator";
        break;
      case KorisnikType.VANJSKI: 
        nazivPrava = "Vanjski";
        break;
      default: 
        break;
    }
    return nazivPrava;
  }

  fullName_calculateCellValue (rowData: IKorisnik) {
    return `${rowData.kor_ime} ${rowData.kor_prezime}`;
  }

  onActivateDeactivateClick(korisnik: IKorisnik, aktiviraj){
    this.aktivirajDeaktivirajKorisnika(korisnik, aktiviraj);
  }

  aktivirajDeaktivirajKorisnika(korisnikId, aktiviraj){
    this._api.aktivitajDeaktivirakKorisnika({
      'idkorisnik': korisnikId,
      'aktivan': aktiviraj ? 1 : 0,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res.success == true){
        notify(`Uspješno ste ${aktiviraj ? 'aktivirali' : 'deaktivirali'} korinika!`, "success", 1000);
        this.onKorisniciChange(true);
      } else {
        notify(`Greška prilikom ${aktiviraj ? 'aktivacije' : 'deaktivacije'} korinika!`, "error", 1000);
      }
    });
  }

  status_calculateCellValue (rowData: IKorisnik) {
    if(rowData.kor_aktivan === 1){
      return `AKTIVAN`;
    }
    else{
      return `NEAKTIVAN`;
    }
  }

}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    AddKorisnikModule,
    EditKorisnikModule,
  ],
  declarations: [ KorisniciTablicaComponent ],
  exports: [ KorisniciTablicaComponent ]
})
export class KorisniciTablicaComponentModule { }
