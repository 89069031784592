import { Component, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { IObavijest } from 'src/app/shared/models/obavijesti.model';
import { AuthService } from 'src/app/shared/services';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-obavijesti',
  templateUrl: './obavijesti.component.html',
  styleUrls: ['./obavijesti.component.scss']
})
export class ObavijestiComponent implements OnInit {
  public obavijesti: IObavijest[];

  constructor(
    private _api: ApiService,
    private _authService: AuthService
  ) { }

  ngOnInit(): void {
    this.dohvatiObavjesti();
  }

  dohvatiObavjesti(){
    const partnerId = this._authService.getUserPartnerId();
    const korisnikId = this._authService.getUserId();
    const userPrava = this._authService.getUserNivoPrava();
    const userToken = this._authService.getToken();

    this._api.dohvatiObavjesti({
      'idpartner': partnerId,
      'idkorisnik': userPrava == -1 ? korisnikId : null,
      'access_token': userToken
    }).subscribe(res=>{
      if(res){
        this.obavijesti = res.data;
      } else {
        notify("Greška prilikom dohvata obavijesti!", "error", 1000);
      }
    });
  };

}
