import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule, DxDataGridModule } from 'devextreme-angular';
import { IOdgovornaOsoba, OdgovornaOsoba } from 'src/app/shared/models/odgovorna-osoba.model';
import { EditZaduzenjaOsobaFormComponentModule } from '../../forme/edit-zaduzenja-osoba-form/edit-zaduzenja-osoba-form.component';

@Component({
  selector: 'zaduzene-osobe-tablica',
  templateUrl: 'zaduzene-osobe-tablica.component.html',
  styleUrls: ['./zaduzene-osobe-tablica.component.scss']
})

export class ZaduzeneOsobeTablicaComponent implements OnInit {
   @Input()
   zaduzeneOsobe!: IOdgovornaOsoba[];
   @Input()
   zaduzeneOsobeIds!: IOdgovornaOsoba[];

   @Output() zaduzeneOsobeChange = new EventEmitter<number[]>();

   isAddZaduzenaOsobaFormVisible: boolean = false;

  constructor() { }

  ngOnInit() { }

  fullName_calculateCellValue (rowData: IOdgovornaOsoba) {
    return `${rowData.kor_ime} ${rowData.kor_prezime}`;
  }

  onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift({
    location: 'before',
    template: 'naslovTablice',    
      }
    )
  }

  onZaduzeneOsobeChange(changes){
    this.zaduzeneOsobeChange.emit(changes);
  }

  onAddZaduzenaOsobaPopupChange(isVisible: boolean){
    this.isAddZaduzenaOsobaFormVisible = isVisible;
  }

  onUrediZaduzenuOsobu(){
     this.isAddZaduzenaOsobaFormVisible = true;
  }

}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    EditZaduzenjaOsobaFormComponentModule,
  ],
  declarations: [ ZaduzeneOsobeTablicaComponent ],
  exports: [ ZaduzeneOsobeTablicaComponent ]
})
export class ZaduzeneOsobeTablicaComponentModule { }
