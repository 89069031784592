import { KorisniciTablicaComponentModule } from './../../tablice/korisnici-tablica/korisnici-tablica.component';
import { Component, NgModule, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { UserPanelModule } from '../../user-panel/user-panel.component';
import { IPartner, Partner } from '../../../models/partneri.model';
import { ApiService } from '../../../services/api.service';
import { DxButtonGroupModule, DxCheckBoxModule, DxFormComponent, DxFormModule, DxPopupModule, DxScrollViewModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import notify from 'devextreme/ui/notify';
import { AuthService } from '../../../services';
import { IModul } from '../../../models/modul.model';
import { ImageSnippet } from 'src/app/shared/models/common.model';


@Component({
  selector: 'edit-partner-form',
  templateUrl: 'edit-partner-form.component.html',
  styleUrls: ['./edit-partner-form.component.scss']
})

export class EditPartnerFormComponent implements OnInit {
  @ViewChild("editPartnerForm", { static: false }) editPartnerForm: DxFormComponent;

  @Input()
  partnerId!: number;
  @Input()
  isPopupVisible: boolean = false;

  @Output() openModalChange = new EventEmitter<boolean>();
  @Output() partnerUpdated = new EventEmitter<boolean>();

  noviPartner: IPartner;
  initialActiveModuleIds: number[];

  popupImageVisible: boolean=false;
  slikeZaPrikaz: string[];
  slike: any=[];
  triSlike:any=[];
  noveSlike:any=[];
  obslike: any[]=[];
  slikeFiles: any=[];
  isDeleted: boolean = false;
  moduli: number[] = [];
  moduli2 = [];

  constructor(
    private _api: ApiService,
    private _authService: AuthService,
  ) {  }

  ngOnInit(): void {
    this.dohvatiPartnera();
  }

  closePopup(): void {
    this.openModalChange.emit(false);
  }
  openPopup(): void {
    this.openModalChange.emit(true);
  }

  form_fieldDataChanged (e) {
    let updatedField = e.dataField;
    let newValue = e.value;
  }

  onFormSubmit(e){
    e.preventDefault();
    this.submitForm();
  }

  submitForm(){
    this.urediPartnera();
  }

  onShown(e){
    this.editPartnerForm.instance.getEditor("pa_naziv").focus();
  }

  onModuleCheckboxChange(modul: IModul){
    const newValue = modul.aktivan === 0 ? 1 : 0;
    this.noviPartner.moduli.map(item => {
      if(item.idmodul === modul.idmodul){
        item.aktivan = newValue;
      } 
    })
  };

  dohvatiPartnera(){
    this._api.dohvatiPartnera({
      'idpartner': this.partnerId,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res.success == true){
        const partner: IPartner = res.data;
        this.initialActiveModuleIds =  partner.moduli.filter(modul => modul.aktivan === 1).map(modul => modul.idmodul);
        const korisnici = partner.korisnici.map(korisnik => {return {...korisnik, kor_admin: korisnik.kor_prava}});
        this.noviPartner = new Partner(
          partner.idpartner, 
          partner.pa_naziv, 
          partner.pa_adresa, 
          partner.pa_odgosoba, 
          partner.pa_telefon,
          partner.pa_email,
          partner.pa_web,
          partner.kor_ime,
          partner.kor_prezime,
          partner.pa_aktivan,
          partner.moduli,
          korisnici);
      }
        this.triSlike[0]=res.data.slika?.putanja
        this.slikeFiles=res.data.slika
    });
  }

  // 'moduli': this.areArraysEqual(this.initialActiveModuleIds, odabraniModuliIds) ? null : odabraniModuliIds,
  urediPartnera(){
    const request = this.buildRequestFormData();
    this._api.urediPartnera(request).subscribe(res=>{
      if(res.success == true){
        notify("Uspješno ste uredili partnera!", "success", 1000);
        this.closePopup();
        this.partnerUpdated.emit(true);
      } else {
        notify("Greška prilikom uređivanja partnera!", "error", 1000);
      }
    });
  }

  buildRequestFormData(){
    let formData:FormData = new FormData();
    const odabraniModuliIds: number[] = this.noviPartner.moduli.filter(modul => modul.aktivan === 1).map(modul => modul.idmodul);
    if(this.areArraysEqual(this.initialActiveModuleIds,odabraniModuliIds)){
      this.moduli = this.initialActiveModuleIds;
    } else {
      this.moduli = odabraniModuliIds;
    }
    // this.moduli = this.areArraysEqual(this.initialActiveModuleIds, odabraniModuliIds) ? null : odabraniModuliIds;
    console.log(this.moduli.toString())
    formData.append('idpartner', this.noviPartner.idpartner.toString());
    formData.append('naziv', this.noviPartner.pa_naziv);
    formData.append('adresa', this.noviPartner.pa_adresa);
    formData.append('odgovorna', this.noviPartner.pa_odgosoba);
    formData.append('telefon', this.noviPartner.pa_telefon);
    formData.append('mail', this.noviPartner.pa_email);
    formData.append('web', this.noviPartner.pa_web);
    formData.append('aktivan', this.noviPartner.pa_aktivan.toString());
    formData.append('moduli', `[${this.moduli}]`)
    formData.append('obgrb', this.isDeleted.toString());
    for(let i=0;i<this.noveSlike.length;i++){
      formData.append('datoteka', this.noveSlike[i])
    }
    formData.append('access_token', this._authService.getToken().toString());
    return formData;
  }

  areArraysEqual(array1, array2) {
    if (array1.length === array2.length) {
      return array1.every((element, index) => {
        if (element === array2[index]) {
          return true;
        }
  
        return false;
      });
    }
  
    return false;
  }

  processFile(imageInput: any) {
    for(let i=0;i<imageInput.files.length;i++){
      const file: File = imageInput.files[i];
      const reader = new FileReader();


      reader.addEventListener('load', (event: any) => {
        if(this.triSlike.length===3){
          notify("Možete odabrati samo tri fotografije!",'error')
        }else{
          this.triSlike.unshift(new ImageSnippet(event.target.result, file));
          this.noveSlike.push(file);
          this.triSlike[0]=this.triSlike[0].src;
        }
      });

      reader.readAsDataURL(file);
    }
  }

  openImage(){
    this.slike=[];
    this.popupImageVisible=true;
    for(let i=0;i<this.triSlike.length;i++){
      this.slike.push(this.triSlike[i])
      this.slike.join(",");
    }
    this.slikeZaPrikaz=this.slike;
  }

  remove(slika,i){
    for(let j=0;j<this.slikeFiles.length;j++){
      if(slika===this.slikeFiles[j].putanja){
        this.obslike.push(this.slikeFiles[j].idslikadogadjaj);
        this.slikeFiles.splice(j,1);
      }
    }
    this.noveSlike.splice(i,1);
    this.triSlike.splice(i,1);
    this.isDeleted = true;
  }

}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxPopupModule,
    DxFormModule,
    DxScrollViewModule,
    DxButtonGroupModule,
    DxCheckBoxModule,
    KorisniciTablicaComponentModule
  ],
  declarations: [ EditPartnerFormComponent ],
  exports: [ EditPartnerFormComponent ]
})
export class EditPartnerModule { }
