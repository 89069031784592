<div class="content-block">
    <div class="partner-list-container">

      <div class="content-block" class="partner-list-container__content-container">
        <dx-data-grid
          class="partners-datagrid"
          [rowAlternationEnabled]="true"
          [hoverStateEnabled]="true"
          [dataSource]="partneri"
          keyExpr="idpartner"
          [wordWrapEnabled]="true"
          [showRowLines]="true"
          [showBorders]="false"
          [columnAutoWidth]="false"
          [columnHidingEnabled]="false"
          (onToolbarPreparing)="onToolbarPreparing($event)"
          [height]="824">

          <dxo-load-panel [enabled]="true"></dxo-load-panel>
          <dxo-header-filter [visible]="true"></dxo-header-filter>
          <dxo-filter-row [visible]="true"></dxo-filter-row>
          <dxo-search-panel [visible]="true" [width]="240" placeholder="Pretraži..."></dxo-search-panel>
          
          <div *dxTemplate="let data of 'naslovTablice'">
            <div class="partner-list-container__header-content-container">
              <p class="partner-list-container__header-content-container__title">Popis partnera: {{partneri && partneri.length}}</p>
              <dx-button
                icon="add" type="default"
                hint="Dodaj partnera" stylingMode="contained" 
                (onClick)="dodajPartnera()">
            </dx-button>
            </div>
          </div>

          <dxi-column dataField="idpartner" caption="Id" [visible]="false"></dxi-column>
          <dxi-column dataField="pa_naziv" caption="Naziv"></dxi-column>
          <dxi-column dataField="pa_adresa" caption="Adresa"></dxi-column>
          <dxi-column dataField="pa_odgosoba" caption="Odgovorna osoba" width="150px"></dxi-column>
          <dxi-column dataField="pa_telefon" caption="Telefon"></dxi-column>
          <dxi-column dataField="pa_email" caption="Mail" cellTemplate="mailTemplate">
            <div *dxTemplate="let data of 'mailTemplate'">
              <a href="mailto:{{data.value}}" style="text-decoration: none;">{{data.value}}</a>
            </div>
          </dxi-column>
          <dxi-column dataField="pa_web" caption="Web" cellTemplate="linkTemplate">
            <div *dxTemplate="let data of 'linkTemplate'">
              <a href="{{data.value}}" target="_blank" style="text-decoration: none;">{{data.value}}</a>
            </div>
          </dxi-column>
          <dxi-column dataField="moduli" caption="Moduli" [visible]="false"></dxi-column>
          <dxi-column caption="Fotografija" type="buttons" name="photo" id="photo-icon-container"><dxi-button template="fotografije">
          </dxi-button>
            <div *dxTemplate="let buttonInfo of 'fotografije'" class="dx-command-edit-with-icons"> 
              <dx-button icon="image" id="btn" hint="Prikaži fotografije" (click)="openImage(buttonInfo)" class="dx-command-edit-with-icons"></dx-button>
            </div>
          </dxi-column>
          <dxi-column dataField="pa_aktivan" caption="Status" [calculateCellValue]="status_calculateCellValue" alignment="center" sortOrder="asc"></dxi-column>


          <dxi-column alignment="center" cellTemplate="editTemplate" class="partners-datagrid__actions-row"></dxi-column>
            <div *dxTemplate="let row of 'editTemplate'" class="partners-datagrid__actions-row__actions">
              <dx-button (onClick)="urediPartnera(row)" icon="edit" stylingMode="contained" type="default"></dx-button>
              <dx-button *ngIf="row.data.pa_aktivan === 1" hint="Deaktiviraj partnera" (onClick)="onActivateDeactivateClick(row.data.idpartner, true)" icon="check" stylingMode="contained" type="success"></dx-button>
              <dx-button *ngIf="row.data.pa_aktivan === 0" hint="Aktiviraj partnera" (onClick)="onActivateDeactivateClick(row.data.idpartner, false)" icon="close" stylingMode="contained" type="danger"></dx-button>
            </div>

          <dxo-paging [pageSize]="15"> </dxo-paging>
          <dxo-pager
            [visible]="true"
            [allowedPageSizes]="[15, 30, 50]"
            [displayMode]="'full'"
            [showPageSizeSelector]="true"
            [showInfo]="true"
            [showNavigationButtons]="true">
          </dxo-pager>
        </dx-data-grid>
      </div>
    </div>
</div>

<dx-popup
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="popupImageVisible"
  [height]="700"
  [width]="900"
  title="Fotografije prijave">
  <dx-gallery
    #gallery
    id="gallery"
    [dataSource]="slikaZaPrikaz"
    [initialItemWidth]="800"
    [stretchImages]="true"
    [wrapAround]="false"
  ></dx-gallery>
</dx-popup>

<add-partner-form
    [isPopupVisible]="isAddPartnerFormVisible"
    (openModalChange)="onAddPartnerPopupChange($event)"
    (partnerCreated)="onPartnerChange($event)">
</add-partner-form>

<edit-partner-form
    *ngIf="isEditPartnerFormVisible && odabraniPartnerId"
    [partnerId]="odabraniPartnerId"
    [isPopupVisible]="isEditPartnerFormVisible"
    (openModalChange)="onEditPartnerPopupChange($event)"
    (partnerUpdated)="onPartnerChange($event)">
</edit-partner-form>
