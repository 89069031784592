import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // LAB \\
  // apiUrl: string = "https://lab.fortuno.hr:3042/api/";

  // PRODUKCIJA \\
  apiUrl: string = "https://app.my-place.info:3001/api/";

  constructor(private _http: HttpClient) { }

  login(data:any){
    return this._http.post(this.apiUrl + 'login', data)
    .pipe(map((res: any) => res ));
  }

  zatraziSifru(data:any){
    return this._http.post(this.apiUrl + 'zatraziSifru', data)
    .pipe(map((res: any) => res ));
  }

  resetSifru(data:any){
    return this._http.post(this.apiUrl + 'resetSifru', data)
    .pipe(map((res: any) => res ));
  }

  // PARTNER
  dohvatiPartnere (data:any){
    return this._http.post(this.apiUrl + 'dohvatiPartnere', data)
    .pipe(map((res: any) => res ));
  }
  dohvatiPartnera (data:any){
    return this._http.post(this.apiUrl + 'dohvatiJednogPartnera', data)
    .pipe(map((res: any) => res ));
  }
  dodajPartnera (data:any){
    return this._http.post(this.apiUrl + 'dodajPartnera', data)
    .pipe(map((res: any) => res ));
  }
  aktivitajDeaktivirakPartnera (data:any){
    return this._http.post(this.apiUrl + 'urediAktivan', data)
    .pipe(map((res: any) => res ));
  }


  urediPartnera (data:any){
    return this._http.post(this.apiUrl + 'urediPartnera', data)
    .pipe(map((res: any) => res ));
  }

  // DEFAULT VRSTE PROBLEMA
  dohvatiDefaultVrsteProblema (data:any){
    return this._http.post(this.apiUrl + 'dohvatiDefaultVrsteProblema', data)
    .pipe(map((res: any) => res ));
  }
  dodajDefaultVrstuProblema (data:any){
    return this._http.post(this.apiUrl + 'dodajDefaultVrstuProblema', data)
    .pipe(map((res: any) => res ));
  }
  urediDefaultVrstuProblema (data:any){
    return this._http.post(this.apiUrl + 'urediDefaultVrstuProblema', data)
    .pipe(map((res: any) => res ));
  }
  obrisiDefaultVrstuProblema (data:any){
    return this._http.post(this.apiUrl + 'obrisiDefaultVrstuProblema', data)
    .pipe(map((res: any) => res ));
  }
  dohvatiModule (data:any){
    return this._http.post(this.apiUrl + 'dohvatiModule', data)
    .pipe(map((res: any) => res ));
  }
  dohvatiJednuDefaultVrstuProblema (data:any){
    return this._http.post(this.apiUrl + 'dohvatiJednuDefaultVrstuProblema', data)
    .pipe(map((res: any) => res ));
  }


  // KORISNIK
  dohvatiKorisnike (data:any){
    return this._http.post(this.apiUrl + 'dohvatiKorisnike', data)
    .pipe(map((res: any) => res ));
  }
  dohvatiKorisnika (data:any){
    return this._http.post(this.apiUrl + 'dohvatiJednogKorisnika', data)
    .pipe(map((res: any) => res ));
  }
  dodajKorisnika (data:any){
    return this._http.post(this.apiUrl + 'dodajKorisnika', data)
    .pipe(map((res: any) => res ));
  }
  aktivitajDeaktivirakKorisnika (data:any){
    return this._http.post(this.apiUrl + 'korAktivan', data)
    .pipe(map((res: any) => res ));
  }

  urediKorisnika (data:any){
    return this._http.post(this.apiUrl + 'urediKorisnika', data)
    .pipe(map((res: any) => res ));
  }
  
  // 3P 
  dohvati3P (data:any){
    return this._http.post(this.apiUrl + 'dohvati3P', data)
    .pipe(map((res: any) => res ));
  }
  dohvatiJedan3P (data:any){
    return this._http.post(this.apiUrl + 'dohvatiJedan3P', data)
    .pipe(map((res: any) => res ));
  }
  dodaj3P (data:any){
    return this._http.post(this.apiUrl + 'dodaj3P', data)
    .pipe(map((res: any) => res ));
  }
  uredi3P (data:any){
    return this._http.post(this.apiUrl + 'uredi3P', data)
    .pipe(map((res: any) => res ));
  }
  dohvatiPPPInfo (data:any){
    return this._http.post(this.apiUrl + 'dohvatiInfo', data)
    .pipe(map((res: any) => res ));
  }
  urediVidljivost (data:any){
    return this._http.post(this.apiUrl + 'urediVidljivost', data)
    .pipe(map((res: any) => res ));
  }
  obrisi3P(data:any){
    return this._http.post(this.apiUrl + 'obrisi3P', data)
    .pipe(map((res: any) => res ));
  }

  // OBAVIJESTI
  dohvatiObavjesti (data:any){
    return this._http.post(this.apiUrl + 'dohvatiObavijesti', data)
    .pipe(map((res: any) => res ));
  }
  dohvatiObavijest (data:any){
    return this._http.post(this.apiUrl + 'dohvatiJednuObavijest', data)
    .pipe(map((res: any) => res ));
  }
  dodajObavijest (data:any){
    return this._http.post(this.apiUrl + 'dodajObavijest', data)
    .pipe(map((res: any) => res ));
  }
  urediObavijest (data:any){
    return this._http.post(this.apiUrl + 'urediObavijest', data)
    .pipe(map((res: any) => res ));
  }
  obrisiObavijest (data:any){
    return this._http.post(this.apiUrl + 'obrisiObavijest', data)
    .pipe(map((res: any) => res ));
  }

  // DOGADJANJA
  dohvatiDogadjanja (data:any){
    return this._http.post(this.apiUrl + 'dohvatiDogadjaje', data)
    .pipe(map((res: any) => res ));
  }
  dohvatiDogadjanje (data:any){
    return this._http.post(this.apiUrl + 'dohvatiJedanDogadjaj', data)
    .pipe(map((res: any) => res ));
  }
  dodajDogadjaj (data:any){
    return this._http.post(this.apiUrl + 'dodajDogadjaj', data)
    .pipe(map((res: any) => res ));
  }
  urediDogadjanje (data:any){
    return this._http.post(this.apiUrl + 'urediDogadjaj', data)
    .pipe(map((res: any) => res ));
  }
  obrisiDogadjanje (data:any){
    return this._http.post(this.apiUrl + 'obrisiDogadjaj', data)
    .pipe(map((res: any) => res ));
  }

  // KONTAKTI
  dohvatiKontakte (data:any){
    return this._http.post(this.apiUrl + 'dohvatiKontakte', data)
    .pipe(map((res: any) => res ));
  }
  dohvatiKontakt (data:any){
    return this._http.post(this.apiUrl + 'dohvatiJedanKontakt', data)
    .pipe(map((res: any) => res ));
  }
  dodajKontakt (data:any){
    return this._http.post(this.apiUrl + 'dodajKontakt', data)
    .pipe(map((res: any) => res ));
  }
  urediKontakt (data:any){
    return this._http.post(this.apiUrl + 'urediKontakt', data)
    .pipe(map((res: any) => res ));
  }
  obrisiKontakt (data:any){
    return this._http.post(this.apiUrl + 'obrisiKontakt', data)
    .pipe(map((res: any) => res ));
  }

  // ODJELI 
  dohvatiOdjele (data:any){
    return this._http.post(this.apiUrl + 'dohvatiOdjele', data)
    .pipe(map((res: any) => res ));
  }
  dohvatiOdjel (data:any){
    return this._http.post(this.apiUrl + 'dohvatiJedanOdjel', data)
    .pipe(map((res: any) => res ));
  }
  dodajOdjel (data:any){
    return this._http.post(this.apiUrl + 'dodajOdjel', data)
    .pipe(map((res: any) => res ));
  }
  urediOdjel (data:any){
    return this._http.post(this.apiUrl + 'urediOdjel', data)
    .pipe(map((res: any) => res ));
  }
  obrisiOdjel (data:any){
    return this._http.post(this.apiUrl + 'obrisiOdjel', data)
    .pipe(map((res: any) => res ));
  }

  //VRSTE PROBLEMA
  dohvatiVrsteProblema (data:any){
    return this._http.post(this.apiUrl + 'dohvatiVrsteProblema', data)
    .pipe(map((res: any) => res ));
  }
  dohvatiJednuVrstuProblema (data:any){
    return this._http.post(this.apiUrl + 'dohvatiJednuVrstuProblema', data)
    .pipe(map((res: any) => res ));
  }
  dodajVrstuProblema (data:any){
    return this._http.post(this.apiUrl + 'dodajVrstuProblema', data)
    .pipe(map((res: any) => res ));
  }
  obrisiVrstuProblema (data:any){
    return this._http.post(this.apiUrl + 'obrisiVrstuProblema', data)
    .pipe(map((res: any) => res ));
  }
  urediVrstuProblema (data:any){
    return this._http.post(this.apiUrl + 'urediVrstuProblema', data)
    .pipe(map((res: any) => res ));
  }

  //PUSH
  posaljiPush(data:any){
    return this._http.post(this.apiUrl + 'posaljiPush', data)
    .pipe(map((res: any) => res ));
  }

}