import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ChangePasswordFormComponent, LoginFormComponent, ResetPasswordFormComponent } from './shared/components';
import { AuthGuardService } from './shared/services';

import { DevExtremeModule } from 'devextreme-angular';

import { ProfileComponent } from './pages/profile/profile.component';
import { PocetnaComponent } from './pages/pocetna/pocetna.component';
import { OdjavaComponent } from './pages/odjava/odjava.component';
import { AdministratorComponent } from './pages/administrator/administrator.component';
import { KorisnikComponent } from './pages/korisnik/korisnik.component';
import { PartneriComponent } from './pages/administrator/partneri/partneri.component';
import { SifarnikVrsteProblemaComponent } from './pages/administrator/sifarnik-vrste-problema/sifarnik-vrste-problema.component';
import { VrsteProblemaComponent } from './pages/korisnik/vrste-problema/vrste-problema.component';
import { PppComponent } from './pages/korisnik/ppp/ppp.component';
import { ObavijestiComponent } from './pages/korisnik/obavijesti/obavijesti.component';
import { DogadjanjaComponent } from './pages/korisnik/dogadjanja/dogadjanja.component';
import { KontaktiComponent } from './pages/korisnik/kontakti/kontakti.component';
import { OdjeliComponent } from './pages/korisnik/odjeli/odjeli.component';
import { KorisniciComponent } from './pages/korisnik/korisnici/korisnici.component';
import { UredjajiComponent } from './pages/korisnik/uredjaji/uredjaji.component';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'login',
    component: LoginFormComponent,
    //canActivate: [ AuthGuardService ]
  },
  {
    path: 'odjava',
    component: OdjavaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'reset-password',
    component: ResetPasswordFormComponent,
    // canActivate: [ AuthGuardService ]
  },
  {
    path: 'change-password/:recoveryCode',
    component: ChangePasswordFormComponent,
    // canActivate: [ AuthGuardService ]
  },
  {
    path: 'administrator',
    component: AdministratorComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'partneri',
    component: PartneriComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'sifarnik-vrste-problema',
    component: SifarnikVrsteProblemaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'ppp',
    component: PppComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'korisnik',
    component: KorisnikComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'vrste-problema',
    component: VrsteProblemaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'obavijesti',
    component: ObavijestiComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'dogadjanja',
    component: DogadjanjaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'kontakti',
    component: KontaktiComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'odjeli',
    component: OdjeliComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'korisnici',
    component: KorisniciComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'uredjaji',
    component: UredjajiComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'pocetna',
    component: PocetnaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: '**',
    redirectTo: 'login',
    canActivate: [ AuthGuardService ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }), DevExtremeModule],
  providers: [AuthGuardService],
  exports: [RouterModule],
  declarations: [ProfileComponent]
})
export class AppRoutingModule { }
