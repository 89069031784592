<dx-popup
    title="UREDI KORISNIKA"
    [height]="600"
    [(visible)]="isPopupVisible" 
    [closeOnOutsideClick]="true"
    [showCloseButton]="true"
    (onHiding)="closePopup()"
    (onShown)="onShown($event)">

    <dx-scroll-view width="100%" height="100%">

        <form (submit)="onFormSubmit($event)">

            <dx-form #editKorisnikForm
                *ngIf="noviKorisnik"
                id="edit-korisnik-form"
                class="edit-korisnik-form"
                [(formData)]="noviKorisnik"
                [readOnly]="false"
                [showColonAfterLabel]="true"
                (onFieldDataChanged)="form_fieldDataChanged($event)"
                [showValidationSummary]="true"
                validationGroup="editKorisnikData">

                <!-- 1 Row: Firstname, Lastname -->
                <dxi-item itemType="group" [colCount]="2">
                    <dxi-item [colSpan]="1" [label]="{text: 'Ime'}" dataField="kor_ime" editorType="dxTextBox">
                        <dxi-validation-rule type="required" message="Ime je nužno."></dxi-validation-rule>
                    <dxi-validation-rule type="stringLength" [min]="2" message="Ime ne može biti jedan znak"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item [colSpan]="1"[label]="{text: 'Prezime'}" dataField="kor_prezime" editorType="dxTextBox">
                        <dxi-validation-rule type="required" message="Prezime je nužno."></dxi-validation-rule>
                    <dxi-validation-rule type="stringLength" [min]="2" message="Prezime ne može biti jedan znak"></dxi-validation-rule>
                    </dxi-item>
                </dxi-item>

                <!-- 2 Row: Email, Password -->
                <dxi-item itemType="group" [colCount]="2">
                    <dxi-item [colSpan]="1" [label]="{text: 'Mail'}" dataField="kor_email" editorType="dxTextBox">
                        <dxi-validation-rule type="required" message="Mail je nužan."></dxi-validation-rule>
                        <dxi-validation-rule type="email" message="Mail nije ispravnog formata."></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item [colSpan]="1" [label]="{text: 'Lozinka'}" dataField="kor_lozinka" editorType="dxTextBox">
                        <dxi-validation-rule type="required" message="Lozinka je nužna."></dxi-validation-rule>
                    <dxi-validation-rule type="stringLength" [min]="2" message="Lozinka ne može biti jedan znak"></dxi-validation-rule>
                    </dxi-item>
                </dxi-item>

                <!-- 3 Row: Phone, Sector, Permissions -->
                <dxi-item itemType="group" [colCount]="3">
                    <dxi-item [colSpan]="1" [label]="{text: 'Telefon'}" dataField="kor_telefon" editorType="dxTextBox">
                        <!-- <dxi-validation-rule type="required" message="Broj telefona je nužan."></dxi-validation-rule> -->
                    <dxi-validation-rule type="numeric" message="Broj telefona može sadržavati samo brojeve"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item [colSpan]="1" dataField="odjel_idodjel" [label]="{text: 'Odjel'}" editorType="dxSelectBox" 
                            [editorOptions]="{ 
                                searchEnabled:'true', dataSource: odjeliSelectOptions, 
                                valueExpr:'idodjel', displayExpr:'od_naziv', width:200
                            }">
                    </dxi-item>
                    <dxi-item [colSpan]="1" [label]="{text: 'Prava'}">  
                        <odabir-vrste-korisnika (trenutnaVrijednostPromjena)="onRadioButtonPravaPromjena($event)" [trenutnaVrijednost]="noviKorisnik.kor_prava"></odabir-vrste-korisnika>
                    </dxi-item>  
                </dxi-item>

            </dx-form>

            <dxi-item class="edit-korisnik-form__button-group">
                <dx-button
                    text="Spremi"
                    type="success"
                    validationGroup="editKorisnikData"
                    [useSubmitBehavior]="true">
                </dx-button>
                <dx-button
                    text="Odustani"
                    type="danger"
                    (onClick)="closePopup()">
                </dx-button>
            </dxi-item>

        </form>
        
    </dx-scroll-view>

</dx-popup>