import { Component, NgModule, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { UserPanelModule } from '../../user-panel/user-panel.component';
import { ApiService } from '../../../services/api.service';
import { DxButtonGroupModule, DxCheckBoxModule, DxFormComponent, DxFormModule, DxPopupModule, DxScrollViewModule, DxTextAreaModule, DxValidatorModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { AuthService } from '../../../services';
import notify from 'devextreme/ui/notify';
import { IKorisnik, Korisnik, KorisnikDefault } from '../../../models/korisnik.model';
import { OdabirVrsteKorisnikaModule } from '../../odabir-vrste-korisnika/odabir-vrste-korisnika.component';
import { IOdjel } from 'src/app/shared/models/odjel.model';


@Component({
  selector: 'add-korisnik-form',
  templateUrl: 'add-korisnik-form.component.html',
  styleUrls: ['./add-korisnik-form.component.scss']
})

export class AddKorisnikFormComponent implements OnInit {
  @ViewChild("addKorisnikForm", { static: false }) addKorisnikForm: DxFormComponent

  @Input()
  isPopupVisible: boolean = false;
  @Input()
  partnerId!: number;
  
  @Output() openModalChange = new EventEmitter<boolean>();
  @Output() korisnikCreated = new EventEmitter<boolean>();
  
  korisnik: IKorisnik = new KorisnikDefault();
  odjeliSelectOptions: IOdjel[];

  isAdmin: boolean = false;

  radioButtonOptions = [
    { text: 'DA', kor_admin: 1 },
    { text: 'NE', kor_admin: 0 }
  ];
  korisnikAdminEditorOptions: any;

  constructor(
    private _api: ApiService,
    private _authService: AuthService,
  ) {
    this.initRadioButtons();
  }

  ngOnInit(): void {
    if(this._authService.getUserNivoPrava() == 2) this.isAdmin = true;
    this.dohvatiOdjele();
  }

  initRadioButtons(){
    this.korisnikAdminEditorOptions = {
      value: this.korisnik.kor_admin,
      layout:'horizontal',
      items: this.radioButtonOptions,
      valueExpr: 'kor_admin'
    };
  }

  initOdjelSelectBox(){
    
  }

  refresh() {
    this.addKorisnikForm.instance.resetValues();
  }

  closePopup(): void {
    this.refresh();
    this.korisnik.kor_admin = 0;
    this.korisnik.kor_prava = 0;
    this.openModalChange.emit(false);
  }

  openPopup(): void {
    this.openModalChange.emit(true);
  }

  onShown(e){
    this.addKorisnikForm.instance.getEditor("kor_ime").focus();
  }

  form_fieldDataChanged (e) {
    let updatedField = e.dataField;
    let newValue = e.value;
  }

  onFormSubmit(e: any){
    e.preventDefault();
    this.submitForm();
  }

  submitForm(){
    this.dodajKorisnika();
  }

  dodajKorisnika(){
    this._api.dodajKorisnika({
      'ime': this.korisnik.kor_ime,
      'prezime': this.korisnik.kor_prezime,
      'lozinka': this.korisnik.kor_lozinka,
      'idodjel': this.korisnik.odjel_idodjel,
      'email': this.korisnik.kor_email,
      'telefon': this.korisnik.kor_telefon,
      'prava': this.korisnik.kor_prava,
      'admin': this.korisnik.kor_prava,
      'idpartner': this.partnerId || this._authService.getUserPartnerId(),
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res.success == true){
        notify("Uspješno ste dodali korisnika!", "success", 1000);
        this.closePopup();
        this.korisnikCreated.emit(true);
      } else {
        notify("Greška prilikom dodavanja korisnika!", "error", 1000);
      }
    });
  }

  onRadioButtonPravaPromjena(korisnikPrava){
      this.korisnik.kor_prava = korisnikPrava;
  }

  dohvatiOdjele(){
    const partnerId = this._authService.getUserPartnerId();
    const userToken = this._authService.getToken();

    this._api.dohvatiOdjele({
      'idpartner': partnerId,
      'access_token': userToken
    }).subscribe(res=>{
      // console.log(res)
      if(res){
        this.odjeliSelectOptions = res.data;
      } else {
        notify("Greška prilikom dohvata odjela!", "error", 1000);
      }
    });
  };

}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxPopupModule,
    DxFormModule,
    DxScrollViewModule,
    DxButtonGroupModule,
    DxCheckBoxModule,
    DxValidatorModule,
    OdabirVrsteKorisnikaModule
  ],
  declarations: [ AddKorisnikFormComponent ],
  exports: [ AddKorisnikFormComponent ]
})
export class AddKorisnikModule { }
