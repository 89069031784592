import { Component, Input, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthService } from '../../../services';
import { ApiService } from '../../../services/api.service';
import { IPPP } from '../../../models/ppp.model';
import { DxButtonModule, DxDataGridModule } from 'devextreme-angular';
import { IKontakt } from 'src/app/shared/models/kontakt.model';
import { AddKontaktFormComponentModule } from '../../forme/add-kontakt-form/add-kontakt-form.component';
import { EditKontaktFormComponentModule } from '../../forme/edit-kontakt-form/edit-kontakt-form.component';
import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';

@Component({
  selector: 'kontakti-tablica',
  templateUrl: 'kontakti-tablica.component.html',
  styleUrls: ['./kontakti-tablica.component.scss']
})

export class KontaktiTablicaComponent implements OnInit {
  @Input()
  kontakti!: IKontakt[];

  odabraniKontaktId: number;

  isAddKontaktFormVisible: boolean = false;
  isEditKontaktFormVisible: boolean = false;

  constructor(
    private _authService: AuthService,
    private _api: ApiService,
  ) { }

  ngOnInit() { }

  onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift({
    location: 'before',
    template: 'naslovTablice',    
      }
    )
  }

  onKontaktChange(isChanged){
    if(isChanged) this.dohvatiKontakte();
  }

  onAddKontaktPopupChange(isVisible: boolean){
    this.isAddKontaktFormVisible = isVisible;
  }

  onEditKontaktPopupChange(isVisible: boolean){
    this.isEditKontaktFormVisible = isVisible;
  }

  dodajKontakt(){
    this.isAddKontaktFormVisible = true;
  }

  urediKontakt(row){
    const kontakt: IKontakt = row.data; 
    this.odabraniKontaktId = kontakt.idkontakt;
    this.isEditKontaktFormVisible = true;
  }

  openObrisiKontakt(row){
    const kontakt: IKontakt = row.data;
    this.odabraniKontaktId = kontakt.idkontakt;
    this.showDialog();
  }

  dohvatiKontakte(){
    const partnerId = this._authService.getUserPartnerId();
    const userToken = this._authService.getToken();

    this._api.dohvatiKontakte({
      'idpartner': partnerId,
      'access_token': userToken
    }).subscribe(res=>{
      // console.log(res)
      if(res){
        this.kontakti = res.data;
      } else {
        notify("Greška prilikom dohvata kontakata!", "error", 1000);
      }
    });
  };

  showDialog(){
    let myDialog = custom({
      title: "BRISANJE KONTAKTA",
      messageHtml: "<b>Jeste li sigurni da želite obrisati kontakt?</b>",
      buttons: [{
          text: "DA", 
          type: 'danger',
          onClick: (e) => {
              return { buttonText: e.component.option("text") }
          },
          
      }, 
      {
        text: "NE",
        type: 'default',
        onClick: (e) => {
            return { buttonText: e.component.option("text") }
        }}
      ]
  });

  myDialog.show().then((dialogResult) => {
    const korisnikId = this._authService.getUserId();
    const userToken = this._authService.getToken();

      if(dialogResult.buttonText === "DA"){
        this._api.obrisiKontakt({
          'idkontakt': this.odabraniKontaktId,
          'idkorisnik': korisnikId,
          'access_token': userToken
        }).subscribe(res=>{
          // console.log(res)
          if(res.success == true){
            notify("Uspješno ste obrisali kontakt!", "success", 1000);
            this.dohvatiKontakte();
          } else {
            notify("Greška prilikom brisanja kontakta", "error", 1000);
          }
        });
    
        
      }
  });
  }

}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    AddKontaktFormComponentModule,
    EditKontaktFormComponentModule,
  ],
  declarations: [ KontaktiTablicaComponent ],
  exports: [ KontaktiTablicaComponent ]
})
export class KontaktiTablicaComponentModule { }
