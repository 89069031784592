<dx-popup
    title="DODAJ PARTNERA"
    [(visible)]="isPopupVisible" 
    [closeOnOutsideClick]="true"
    [showCloseButton]="true"
    (onHiding)="closePopup()"
    (onShown)="onShown($event)">

    <dx-scroll-view width="100%" height="100%">

        <form (submit)="onFormSubmit($event)">
        <dx-form #addPartnerForm
            *ngIf="partner"
            id="add-partner-form"
            class="add-partner-form"
            [(formData)]="partner"
            [showColonAfterLabel]="true"
            (onFieldDataChanged)="form_fieldDataChanged($event)"
            [colCount]="2"
            validationGroup="AddPartnerData">

            <dxi-item itemType="group" caption="Informacije o partneru:" [colCount]="1">

                <dxi-item [label]="{text: 'Naziv'}" dataField="pa_naziv" editorType="dxTextBox">
                    <dxi-validation-rule type="required" message="Naziv partnera je nužan."></dxi-validation-rule>
                    <dxi-validation-rule type="stringLength" [min]="2" message="Naziv ne može biti jedan znak"></dxi-validation-rule>
                </dxi-item>
    
                <dxi-item [label]="{text: 'Adresa'}" dataField="pa_adresa" editorType="dxTextBox">
                    <dxi-validation-rule type="required" message="Adresa partnera je nužna."></dxi-validation-rule>
                    <dxi-validation-rule type="stringLength" [min]="10" message="Adresa mora sadržavati minimalno 10 znakova"></dxi-validation-rule>
                </dxi-item>
    
                <dxi-item [label]="{text: 'Odgovorna osoba'}" dataField="pa_odgosoba" editorType="dxTextBox">
                <dxi-validation-rule type="required" message="Odgovorna osoba je nužna."></dxi-validation-rule>
                <dxi-validation-rule type="stringLength" [min]="5" message="Minimalno 5 znakova"></dxi-validation-rule>
                </dxi-item>
    
                <dxi-item [label]="{text: 'Telefon'}" dataField="pa_telefon" editorType="dxTextBox">
                    <dxi-validation-rule type="numeric" message="Broj telefona može sadržavati samo brojeve"></dxi-validation-rule>
                </dxi-item>
    
                <dxi-item [label]="{text: 'Mail'}" dataField="pa_email" editorType="dxTextBox">
                    <dxi-validation-rule type="email" message="Mail nije ispravnog formata.">
                    </dxi-validation-rule>
                </dxi-item>
    
                <dxi-item [label]="{text: 'Web'}" dataField="pa_web" editorType="dxTextBox">
                    <dxi-validation-rule type="pattern" pattern="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})" message="Format linka nije ispravan!"></dxi-validation-rule>
                </dxi-item>

            </dxi-item>
            
            <dxi-item itemType="group" [colCount]="1">
            <dxi-item *ngIf="partner.moduli.length > 0" itemType="group" caption="Aktivni moduli:">
                
                <dxi-item *ngFor="let modul of partner.moduli" editorType="dxCheckBox">
                    <dx-check-box [text]="modul.mo_naziv" 
                        [value]="modul.aktivan === 1" (onOptionChanged)="onModuleCheckboxChange(modul)">
                    </dx-check-box>
                </dxi-item>

            </dxi-item>

            <dxi-item itemtype="group">
                <label class="image-upload-container btn btn-bwm" caption="Slika">
                    <span class="boxSpan">Dodaj sliku: <i class="dx-icon-image dx-icon-custom-style"></i></span>
                    <input #imageInput
                           type="file"
                           accept="image/*"
                           multiple="multiple"
                           (change)="processFile(imageInput)">
                </label>
                <div class="img-container">
                    <div class="img-preview-container">
                      <div class="img-preview"
                          *ngFor="let slika of triSlike; let i = index"
                           [ngStyle]="{'background-image': 'url('+ slika + ')'}"
                           (click)="openImage()">
                           <i class="dx-icon-remove" style="float:right;cursor: pointer;font-size: 24px;" (click)="remove(slika,i)"></i>
                      </div>
                    </div>
                </div>
            </dxi-item>
        </dxi-item> 

            <dxi-item class="add-partner-form__button-group">
                <dx-button
                    validationGroup="AddPartnerData"
                    text="Spremi"
                    type="success"
                    [useSubmitBehavior]="true">
                </dx-button>
                <dx-button
                    text="Odustani"
                    type="danger"
                    (onClick)="closePopup()">
                </dx-button>
            </dxi-item>

        </dx-form>
        </form>

    </dx-scroll-view>

</dx-popup>