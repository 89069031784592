<form class="login-form" (submit)="onSubmit($event)">

  <div class="login-header">
    <div class="title">MyPlaceInfo</div>
  </div>

  <dx-form [formData]="formData" [disabled]="loading" (onEditorEnterKey)="onEditorEnterKey($event)">
    <dxi-item dataField="email" editorType="dxTextBox" #inputName 
    [editorOptions]="{ stylingMode: 'filled', mode: 'email' }"> 
      <!-- <dxi-validation-rule type="required" message="Korisničko ime je obavezno polje"></dxi-validation-rule> -->
      <dxo-label [visible]="true" text="E-mail:"></dxo-label>
    </dxi-item>
    <dxi-item dataField="password" editorType="dxTextBox">
      <div *dxTemplate class="lozinka">
      <dx-text-box [(value)]="password" [(mode)]="passwordMode" stylingMode="filled" ></dx-text-box>
      <div class="buttoni">
        <dx-button *ngIf="passwordMode == 'password'" icon="doesnotcontain" (onClick)="otkrijLozinku()" hint="Prikaži lozinku"></dx-button>
        <dx-button *ngIf="passwordMode == 'text'" icon="startswith" (onClick)="otkrijLozinku()" hint="Sakrij lozinku"></dx-button>
      </div>
      </div>
      <dxi-validation-rule type="required" ></dxi-validation-rule>
      <dxo-label [visible]="true" text="Lozinka:"></dxo-label>
      </dxi-item>
    <dxi-item itemType="button">
      <dxo-button-options width="100%" type="default" [useSubmitBehavior]="true" [template]="'signInTemplate'">
      </dxo-button-options>
    </dxi-item>
    <ng-container *dxTemplate="let item of 'signInTemplate'">
      <span class="dx-button-text">
        <ng-container *ngIf="loading; else notLoading">
          <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
        </ng-container>
        <ng-template #notLoading>Prijava</ng-template>
      </span>
    </ng-container>

    <dxi-item>
      <div class="link">
        <a routerLink="/reset-password">Reset lozinke</a>
      </div>
    </dxi-item>
    
  </dx-form>
  <!-- <span style="float: right;">03.08.2022.</span> -->
  
</form>