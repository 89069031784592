import { Component, NgModule, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { UserPanelModule } from '../../user-panel/user-panel.component';
import { ApiService } from '../../../services/api.service';
import { DxButtonGroupModule, DxCheckBoxModule, DxFormComponent, DxFormModule, DxMapModule, DxPopupModule, DxScrollViewModule, DxTextAreaModule, DxValidatorModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { AuthService } from '../../../services';
import { DEFAULT_PRIJAVA_OPTONS, IPPP, IPrijavaOption, PPPPrijava } from 'src/app/shared/models/ppp.model';
import { ImageSnippet } from 'src/app/shared/models/common.model';
import notify from 'devextreme/ui/notify';
import { GooglePlaceDirective, GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapComponentComponentModule } from '../../google-map-component/google-map-component.component';
import { IVrstaProblema } from 'src/app/shared/models/vrste-problema.model';
import { VrsteProblemaTablicaComponentModule } from '../../tablice/vrste-problema-tablica/vrste-problema-tablica.component';


@Component({
  selector: 'add-prijava-form',
  templateUrl: 'add-prijava-form.component.html',
  styleUrls: ['./add-prijava-form.component.scss']
})

export class AddPrijavaFormComponent implements OnInit {
  @ViewChild("addPrijavaForm", { static: false }) addPrijavaForm: DxFormComponent;
  mapMarkers = [];
  keys={};

  @ViewChild("placesRef") placesRef : GooglePlaceDirective;

  @Input()
  isPopupVisible: boolean = false;
  @Input()
  partnerId!: number;
  
  @Output() openModalChange = new EventEmitter<boolean>();
  @Output() prijavaCreated = new EventEmitter<boolean>();
  
  prijava: IPPP;
  
  selectedVrsta: IVrstaProblema;
  vrproblema_idproblem: number;
  problemiSelectOptions: IVrstaProblema[];
  popupImageVisible: boolean=false;
  slikeZaPrikaz: string[];
  slike: any=[];
  triSlike:any=[];
  noveSlike:any=[];
  obslike: any[]=[];
  slikeFiles: any=[];
  // prilikom kreiranja prijave zapisuje se status: ZAPRIMILJENO te je dropdown s opcijama skriven
  // prijavaStatusOptions: IPrijavaOption[] = DEFAULT_PRIJAVA_OPTONS;
  adresaIzKarte: string;
  latIzKarte: number;
  lngIzKarte: number;

  constructor(
    private _api: ApiService,
    private _authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.initPrijavaValues();
    // this.keys["google"]="AIzaSyAdaQWo5L-aKfiD7jz7ufgo4s8EMlgT7m4"
    this.keys["google"]="AIzaSyAInEYFG0_ykzrvxDU8Qmf7c39MRO_qXCk"
    this.apiDohvatiVrsteProblema();
  }

  initPrijavaValues(){
    this.prijava = new PPPPrijava();
  }

  refresh() {
    this.addPrijavaForm.instance.resetValues();
  }

  closePopup(): void {
    this.refresh();
    this.initPrijavaValues();
    this.openModalChange.emit(false);
  }

  openPopup(): void {
    this.openModalChange.emit(true);
  }

  form_fieldDataChanged (e) {
    let updatedField = e.dataField;
    let newValue = e.value;
  }

  onFormSubmit(e: any){
    e.preventDefault();
    this.submitForm();
  }

  submitForm(){
    this.dodajPrijavu();
  }

  dodajPrijavu(){
    const request = this.buildRequestFormData();
    this._api.dodaj3P(request).subscribe(res=>{
      if(res.success == true){
        notify("Uspješno ste dodali prijavu!", "success", 1000);
        this.closePopup();
        this.prijavaCreated.emit(true);
      } else {
        notify("Greška prilikom dodavanja prijave!", "error", 1000);
      }
    });
  }

  buildRequestFormData(){
    let formData:FormData = new FormData();
    formData.append('naziv', this.prijava.pr_naziv);
    formData.append('opis', this.prijava.pr_opis);
    formData.append('adresa', this.adresaIzKarte);
    formData.append('vrsta', this.prijava.pr_vrsta.toString());
    formData.append('lat', this.latIzKarte.toString());
    formData.append('lon', this.lngIzKarte.toString());
    formData.append('idproblem', this.prijava.problem_idproblem.toString());
    formData.append('idgost', null);
    formData.append('idpartner', this._authService.getUserPartnerId().toString());
    formData.append('datoteka', this.noveSlike[0]);
    formData.append('access_token', this._authService.getToken().toString());
    return formData;
  }

  processFile(imageInput: any) {
    for(let i=0;i<imageInput.files.length;i++){
      const file: File = imageInput.files[i];
      const reader = new FileReader();


      reader.addEventListener('load', (event: any) => {
        if(this.triSlike.length===3){
          notify("Možete odabrati samo tri fotografije!",'error')
        }else{
          this.triSlike.unshift(new ImageSnippet(event.target.result, file));
          this.noveSlike.push(file);
          this.triSlike[0]=this.triSlike[0].src;
        }
      });

      reader.readAsDataURL(file);
    }
  }

  openImage(){
    this.slike=[];
    this.popupImageVisible=true;
    for(let i=0;i<this.triSlike.length;i++){
      this.slike.push(this.triSlike[i])
      this.slike.join(",");
    }
    this.slikeZaPrikaz=this.slike;
  }

  remove(slika,i){
    for(let j=0;j<this.slikeFiles.length;j++){
      if(slika===this.slikeFiles[j].putanja){
        this.obslike.push(this.slikeFiles[j].idslikadogadjaj);
        this.slikeFiles.splice(j,1);
      }
    }
    this.noveSlike.splice(i,1);
    this.triSlike.splice(i,1);
  }

  onShown(e){
    this.addPrijavaForm.instance.getEditor("pr_naziv").focus();
  }

  prihvatiAdresu($event){
    this.adresaIzKarte = $event;
  }
  prihvatiLat($event){
    this.latIzKarte = $event;
  }
  prihvatiLng($event){
    this.lngIzKarte = $event;
  }

  apiDohvatiVrsteProblema(){
    this._api.dohvatiVrsteProblema({
      'idpartner': this._authService.getUserPartnerId(),
      'idkorisnik': this._authService.getUserId(),
      'access_token': this._authService.getToken()
    }).subscribe(res=>{
      if(res){
        this.problemiSelectOptions = res.data;
      } 
    });
  }

  options = {
    componentRestricions : {
      country: ['HR'],
      fields:['place_id','geometry','name', 'street_number'],
    }
  };


}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxPopupModule,
    DxFormModule,
    DxScrollViewModule,
    DxButtonGroupModule,
    DxCheckBoxModule,
    DxValidatorModule,
    DxTextAreaModule,
    DxMapModule,
    GooglePlaceModule,
    GoogleMapsModule,
    GoogleMapComponentComponentModule,
    VrsteProblemaTablicaComponentModule
  ],
  declarations: [ AddPrijavaFormComponent ],
  exports: [ AddPrijavaFormComponent ]
})
export class AddPrijavaFormComponentModule { }
