import { Component, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { IUredjaj } from 'src/app/shared/models/uredjaj.model';
import { AuthService } from 'src/app/shared/services';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-uredjaji',
  templateUrl: './uredjaji.component.html',
  styleUrls: ['./uredjaji.component.scss']
})
export class UredjajiComponent implements OnInit {
  public uredjaji: IUredjaj[];

  constructor(
    private _api: ApiService,
    private _authService: AuthService
  ) { }

  ngOnInit(): void {
    this.dohvatiUredjaje();
  }

  dohvatiUredjaje(){
    const partnerId = this._authService.getUserPartnerId();
    const userToken = this._authService.getToken();

    // this._api.dohvatiUredjaje({
    //   'idpartner': partnerId,
    //   'access_token': userToken
    // }).subscribe(res=>{
    //   // console.log(res)
    //   if(res.success == true){
    //     this.uredjaji = res.data;
    //   } else {
    //     notify("Greška prilikom uedjaja!", "error", 1000);
    //   }
    // });
  };
  
}
