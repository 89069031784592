<dx-popup
    title="UREDI PARTNERA"
    [(visible)]="isPopupVisible" 
    [closeOnOutsideClick]="true"
    [showCloseButton]="true"
    (onHiding)="closePopup()">

    <dx-scroll-view width="100%" height="100%">

        <form (submit)="onFormSubmit($event)">
        <dx-form
            #editPartnerForm
            *ngIf="noviPartner"
            id="edit-partner-form"
            class="edit-partner-form"
            [(formData)]="noviPartner"
            [showColonAfterLabel]="true"
            (onFieldDataChanged)="form_fieldDataChanged($event)"
            [colCount]="2"
            validationGroup="EditPartnerData">

            <dxi-item itemType="group" caption="Informacije o partneru:" [colCount]="1">

                <dxi-item [label]="{text: 'Naziv'}" dataField="pa_naziv" [editorOptions]="{value: noviPartner.pa_naziv}" editorType="dxTextBox">
                    <dxi-validation-rule type="required" message="Naziv je nužan.">
                    </dxi-validation-rule>
                </dxi-item>
    
                <dxi-item [label]="{text: 'Adresa'}" dataField="pa_adresa" [editorOptions]="{value: noviPartner.pa_adresa}" editorType="dxTextBox">
                    <dxi-validation-rule type="required" message="Adresa je nužna.">
                    </dxi-validation-rule>
                </dxi-item>
    
                <dxi-item [label]="{text: 'Odgovorna osoba'}" dataField="pa_odgosoba" [editorOptions]="{value: noviPartner.pa_odgosoba}" editorType="dxTextBox">
                </dxi-item>
    
                <dxi-item [label]="{text: 'Telefon'}" dataField="pa_telefon" [editorOptions]="{value: noviPartner.pa_telefon}" editorType="dxTextBox">
                </dxi-item>
    
                <dxi-item [label]="{text: 'Mail'}" dataField="pa_email" [editorOptions]="{value: noviPartner.pa_email}" editorType="dxTextBox">
                    <dxi-validation-rule type="email" message="Mail je nužan.">
                    </dxi-validation-rule>
                </dxi-item>
    
                <dxi-item [label]="{text: 'Web'}" dataField="pa_web" [editorOptions]="{value: noviPartner.pa_web}" editorType="dxTextBox">
                </dxi-item>

            </dxi-item>

            <dxi-item itemType="group" [colCount]="1">
            <dxi-item *ngIf="noviPartner.moduli.length > 0" itemType="group" caption="Aktivni moduli:">

                <dxi-item *ngFor="let modul of noviPartner.moduli" editorType="dxCheckBox">
                    <dx-check-box [text]="modul.mo_naziv" 
                        [value]="modul.aktivan === 1" (onOptionChanged)="onModuleCheckboxChange(modul)">
                    </dx-check-box>
                </dxi-item>

            </dxi-item>

            <dxi-item itemtype="group">
                <label class="image-upload-container btn btn-bwm" caption="Slika">
                    <span class="boxSpan">Dodaj sliku: <i class="dx-icon-image dx-icon-custom-style"></i></span>
                    <input #imageInput
                           type="file"
                           accept="image/*"
                           multiple="multiple"
                           (change)="processFile(imageInput)">
                </label>
                <div class="img-container">
                    <div *ngIf="triSlike[0]" class="img-preview-container">
                      <div class="img-preview"
                          *ngFor="let slika of triSlike; let i = index"
                           [ngStyle]="{'background-image': 'url('+ slika + ')'}"
                           (click)="openImage()">
                           <i class="dx-icon-remove" style="float:right;cursor: pointer;font-size: 24px;" (click)="remove(slika,i)"></i>
                      </div>
                    </div>
                </div>
            </dxi-item>
        </dxi-item>

            <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
                <korisnici-tablica [partnerId]="noviPartner.idpartner" [korisnici]="noviPartner.korisnici"></korisnici-tablica>
            </dxi-item>

            <dxi-item class="edit-partner-form__button-group">
                <dx-button
                    validationGroup="EditPartnerData"
                    text="Spremi"
                    type="success"
                    (onClick)="submitForm()">
                </dx-button>
                <dx-button
                    text="Odustani"
                    type="danger"
                    (onClick)="closePopup()">
                </dx-button>
            </dxi-item>

        </dx-form>
        </form>

    </dx-scroll-view>

</dx-popup>