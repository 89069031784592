import { Component, Input, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthService } from '../../../services';
import { ApiService } from '../../../services/api.service';
import { DxButtonModule, DxCheckBoxModule, DxDataGridModule } from 'devextreme-angular';
import { IObavijest } from 'src/app/shared/models/obavijesti.model';
import { AddObavijestFormComponentModule } from '../../forme/add-obavijest-form/add-obavijest-form.component';
import { EditObavijestFormComponentModule } from '../../forme/edit-obavijest-form/edit-obavijest-form.component';
import * as moment from 'moment';
import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';


@Component({
  selector: 'obavijesti-tablica',
  templateUrl: 'obavijesti-tablica.component.html',
  styleUrls: ['./obavijesti-tablica.component.scss']
})

export class ObavijestiTablicaComponent implements OnInit {
  @Input()
  obavijesti!: IObavijest[];

  odabranaObavijestId: number;

  isAddObavijestFormVisible: boolean = false;
  isEditObavijestFormVisible: boolean = false;

  constructor(
    private _authService: AuthService,
    private _api: ApiService,
  ) { }

  ngOnInit() { }

  onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift({
    location: 'before',
    template: 'naslovTablice',    
      }
    )
  }

  formatDateCell(row){
    const date = moment(row.ob_datum);
    return date.isValid() ? date.format('DD.MM.YYYY.') : "";
  }


  onObavijestChange(isChanged){
    if(isChanged) this.dohvatiObavijesti();
  }

  onAddObavijestPopupChange(isVisible: boolean){
    this.isAddObavijestFormVisible = isVisible;
  }

  onEditObavijestPopupChange(isVisible: boolean){
    this.isEditObavijestFormVisible = isVisible;
  }

  dohvatiObavijesti(){
    const partnerId = this._authService.getUserPartnerId();
    const korisnikId = this._authService.getUserId();
    const userPrava = this._authService.getUserNivoPrava();
    const userToken = this._authService.getToken();

    this._api.dohvatiObavjesti({
      'idpartner': partnerId,
      'idkorisnik': userPrava == -1 ? korisnikId : null,
      'access_token': userToken
    }).subscribe(res=>{
      if(res){
        this.obavijesti = res.data;
      } else {
        notify("Greška prilikom dohvata obavijesti!", "error", 1000);
      }
    });
  }

  dodajObavijest(){
    this.isAddObavijestFormVisible = true;
  }

  urediObavijest(row){
    const obavijest: IObavijest = row.data; 
    this.odabranaObavijestId = obavijest.idobavijest;
    this.isEditObavijestFormVisible = true;
  }

  openObrisiObavijest(row){
    const obavijest: IObavijest = row.data;
    this.odabranaObavijestId = obavijest.idobavijest;
    this.showDialog();
  }

  showDialog(){
    let myDialog = custom({
      title: "BRISANJE OBAVIJESTI",
      messageHtml: "<b>Jeste li sigurni da želite obrisati obavijest?</b>",
      buttons: [{
          text: "DA", 
          type: 'danger',
          onClick: (e) => {
              return { buttonText: e.component.option("text") }
          },
          
      }, 
      {
        text: "NE",
        type: 'default',
        onClick: (e) => {
            return { buttonText: e.component.option("text") }
        }}
      ]
    });

    myDialog.show().then((dialogResult) => {
      const korisnikId = this._authService.getUserId();
      const userToken = this._authService.getToken();

        if(dialogResult.buttonText === "DA"){
          this._api.obrisiObavijest({
            'idobavijest': this.odabranaObavijestId,
            'idkorisnik': korisnikId,
            'access_token': userToken
          }).subscribe(res=>{
            // console.log(res)
            if(res.success){
              notify("Uspješno ste obrisali obavijest!", "success", 1000);
              this.dohvatiObavijesti();
            } else {
              notify("Greška prilikom brisanja obavijesti", "error", 1000);
            }
          });
        }
      });
  }
     
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    AddObavijestFormComponentModule,
    EditObavijestFormComponentModule,
    DxCheckBoxModule
  ],
  declarations: [ ObavijestiTablicaComponent ],
  exports: [ ObavijestiTablicaComponent ]
})
export class ObavijestiTablicaComponentModule { }
