import { Component, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { IKontakt } from 'src/app/shared/models/kontakt.model';
import { AuthService } from 'src/app/shared/services';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-kontakti',
  templateUrl: './kontakti.component.html',
  styleUrls: ['./kontakti.component.scss']
})
export class KontaktiComponent implements OnInit {
  public kontakti: IKontakt[];

  constructor(
    private _api: ApiService,
    private _authService: AuthService
  ) { }

  ngOnInit(): void {
    this.dohvatiKontakte();
  }

  dohvatiKontakte(){
    const partnerId = this._authService.getUserPartnerId();
    const userToken = this._authService.getToken();

    this._api.dohvatiKontakte({
      'idpartner': partnerId,
      'access_token': userToken
    }).subscribe(res=>{
      // console.log(res)
      if(res){
        this.kontakti = res.data;
      } else {
        notify("Greška prilikom dohvata kontakata!", "error", 1000);
      }
    });
  };

}
