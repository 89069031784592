import { Component, Input, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthService } from '../../../services';
import { ApiService } from '../../../services/api.service';
import { DxButtonModule, DxDataGridModule } from 'devextreme-angular';
import { IUredjaj } from '../../../models/uredjaj.model';

@Component({
  selector: 'uredjaji-tablica',
  templateUrl: 'uredjaji-tablica.component.html',
  styleUrls: ['./uredjaji-tablica.component.scss']
})

export class UredjajiTablicaComponent implements OnInit {
  @Input()
  uredjaji!: IUredjaj[];

  constructor(
    private _authService: AuthService,
    private _api: ApiService,
  ) { }

  ngOnInit() {
  }

  onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift({
    location: 'before',
    template: 'naslovTablice',    
      }
    )
  }

}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule
  ],
  declarations: [ UredjajiTablicaComponent ],
  exports: [ UredjajiTablicaComponent ]
})
export class UredjajiTablicaComponentModule { }
