import { Component, Input, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthService } from '../../../services';
import { ApiService } from '../../../services/api.service';
import { DxButtonModule, DxDataGridModule, DxPopupModule } from 'devextreme-angular';
import { IOdjel } from 'src/app/shared/models/odjel.model';
import { AddOdjelFormComponentModule } from '../../forme/add-odjel-form/add-odjel-form.component';
import { EditOdjelFormComponentModule } from '../../forme/edit-odjel-form/edit-odjel-form.component';
import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';

@Component({
  selector: 'odjeli-tablica',
  templateUrl: 'odjeli-tablica.component.html',
  styleUrls: ['./odjeli-tablica.component.scss']
})

export class OdjeliTablicaComponent implements OnInit {
  @Input()
  odjeli!: IOdjel[];

  odabraniOdjelId: number;

  isAddOdjelFormVisible: boolean = false;
  isEditOdjelFormVisible: boolean = false;
  isPopupDeleteOdjelVisible: boolean = false;

  constructor(
    private _authService: AuthService,
    private _api: ApiService,
  ) { }

  ngOnInit() {
  }

  onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift({
    location: 'before',
    template: 'naslovTablice',    
      }
    )
  }

  onOdjelChange(isChanged){
    if(isChanged) this.dohvatiOdjele();
  }

  onAddOdjelPopupChange(isVisible: boolean){
    this.isAddOdjelFormVisible = isVisible;
  }

  onEditOdjelPopupChange(isVisible: boolean){
    this.isEditOdjelFormVisible = isVisible;
  }

  dohvatiOdjele(){
    const partnerId = this._authService.getUserPartnerId();
    const userToken = this._authService.getToken();

    this._api.dohvatiOdjele({
      'idpartner': partnerId,
      'access_token': userToken
    }).subscribe(res=>{
      // console.log(res)
      if(res){
        this.odjeli = res.data;
      } else {
        notify("Greška prilikom dohvata odjela!", "error", 1000);
      }
    });
  };

  obrisiOdjel(){
    const korisnikId = this._authService.getUserId();
    const userToken = this._authService.getToken();

    this._api.obrisiOdjel({
      
      'idodjel': this.odabraniOdjelId,
      'idkorisnik': korisnikId,
      'access_token': userToken
    }).subscribe(res=>{
      // console.log(res)
      if(res.success == true){
        notify("Uspješno ste obrisali odjel!", "success", 1000);
        this.dohvatiOdjele();

      } else {
        notify("Greška prilikom dohvata odjela!", "error", 1000);
      }
    });
  };


  dodajOdjel(){
    this.isAddOdjelFormVisible = true;
  }

  urediOdjel(row){
    const odjel: IOdjel = row.data;
    this.odabraniOdjelId = odjel.idodjel;
    this.isEditOdjelFormVisible = true;
  }

  openObrisiOdjel(row){
    const odjel: IOdjel = row.data;
    this.odabraniOdjelId = odjel.idodjel;
    this.showDialog();
  }

  showDialog(){
    let myDialog = custom({
      title: "BRISANJE ODJELA",
      messageHtml: "<b>Jeste li sigurni da želite obrisati odjel?</b>",
      buttons: [{
          text: "DA", 
          type: 'danger',
          onClick: (e) => {
              return { buttonText: e.component.option("text") }
          },
          
      }, 
      {
        text: "NE",
        type: 'default',
        onClick: (e) => {
            return { buttonText: e.component.option("text") }
        }}
      ]
  });

  myDialog.show().then((dialogResult) => {
    const partnerId = this._authService.getUserPartnerId();
    const userToken = this._authService.getToken();

      if(dialogResult.buttonText === "DA"){
        this._api.dohvatiKorisnike({
          'idodjel': this.odabraniOdjelId,
          'idpartner': partnerId,
          'access_token': userToken
        }).subscribe(res=>{
          // console.log(res)
          if(res.success == false){
            this.obrisiOdjel();
          } else {
            notify({
              message: "Greška prilikom brisanja odjela, jer su neki korisnici pridruženi tome odjelu, maknite korisnike s ovog odjela kako biste ga obrisali!",
              position: 'center'
            }, "error", 6000);
          }
        });
      }
  });
  }

}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    AddOdjelFormComponentModule,
    EditOdjelFormComponentModule,
    DxPopupModule
  ],
  declarations: [ OdjeliTablicaComponent ],
  exports: [ OdjeliTablicaComponent ]
})
export class OdjeliTablicaComponentModule { }
