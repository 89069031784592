<dx-popup
    title="DODAJ KONTAKT"
    [width]="720"
    [height]="500"
    [(visible)]="isPopupVisible" 
    [closeOnOutsideClick]="true"
    [showCloseButton]="true"
    (onHiding)="closePopup()"
    (onShown)="onShown($event)">

    <dx-scroll-view width="100%" height="100%">

        <form (submit)="onFormSubmit($event)">

            <dx-form 
                #addKontaktForm
                *ngIf="kontakt"
                id="add-kontakt-form"
                class="add-kontakt-form"
                [(formData)]="kontakt"
                [readOnly]="false"
                [showColonAfterLabel]="true"
                (onFieldDataChanged)="form_fieldDataChanged($event)"
                [colCount]="2"
                [showValidationSummary]="true"
                validationGroup="AddKontaktData">

                <dxi-item itemType="group" [colSpan]="1">
                    <dxi-item [label]="{text: 'Naziv'}"  dataField="ko_naziv" editorType="dxTextBox">
                        <dxi-validation-rule type="required" message="Naziv je nužan."></dxi-validation-rule>
                        <dxi-validation-rule type="stringLength" [min]="5" message="Naziv mora sadržavati minimalno 5 znakova"></dxi-validation-rule>
                    </dxi-item>
    
                    <dxi-item [label]="{text: 'Adresa'}"  dataField="ko_adresa" editorType="dxTextBox">
                        <dxi-validation-rule type="required" message="Adresa je nužna."></dxi-validation-rule>
                        <dxi-validation-rule type="stringLength" [min]="10" message="Adresa mora sadržavati minimalno 10 znakova"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [label]="{text: 'Mail'}"  dataField="ko_email" editorType="dxTextBox">
                        <dxi-validation-rule type="email" message="Mail nije ispravnog formata."></dxi-validation-rule>
                    </dxi-item>
                </dxi-item>
    
                <dxi-item itemType="group" [colSpan]="2">
                    <dxi-item [label]="{text: 'Telefon'}"  dataField="ko_telefon" editorType="dxTextBox">
                        <dxi-validation-rule type="numeric" message="Broj telefona može sadržavati samo brojeve"></dxi-validation-rule>
                    </dxi-item>
    
                    <dxi-item [label]="{text: 'Mobitel'}"  dataField="ko_mobitel" editorType="dxTextBox">
                        <dxi-validation-rule type="numeric" message="Broj mobitela može sadržavati samo brojeve"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [label]="{text: 'Web'}"  dataField="ko_web" editorType="dxTextBox">
                    <dxi-validation-rule type="pattern" pattern="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})" message="Format linka nije ispravan!"></dxi-validation-rule>
                    </dxi-item>
                </dxi-item>
            </dx-form>

            <dxi-item class="add-kontakt-form__button-group">
                <dx-button
                    text="Spremi"
                    type="success"
                    validationGroup="AddKontaktData"
                    [useSubmitBehavior]="true">
                </dx-button>
                <dx-button
                    text="Odustani"
                    type="danger"
                    (onClick)="closePopup()">
                </dx-button>
            </dxi-item>
            
        </form>   

    </dx-scroll-view>

</dx-popup>