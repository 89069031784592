import { Component, OnDestroy, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { IKorisnik, KorisnikType } from 'src/app/shared/models/korisnik.model';
import { MenuItemProps, MenuItems } from 'src/app/shared/models/menu.model';
import { IPPP, PPPVrsta } from 'src/app/shared/models/ppp.model';
import { AuthService } from 'src/app/shared/services';
import { ApiService } from 'src/app/shared/services/api.service';
import { DataService } from 'src/app/shared/services/data.service';
import { Subscription } from 'rxjs';
import { Data } from '@angular/router';

@Component({
  selector: 'app-ppp',
  templateUrl: './ppp.component.html',
  styleUrls: ['./ppp.component.scss']
})
export class PppComponent implements OnInit, OnDestroy {
  public selectedPPPMenuItem: MenuItemProps;
  public menuItems: MenuItemProps[];
  public ppp: IPPP[];
  public prijave = [];
  public prijedlozi = [];
  public pohvale = [];

  isPrijedlog: boolean;
  isPohvala: boolean;
  subscription: Subscription;
  subscription2: Subscription;

  constructor(
    private _api: ApiService,
    private _authService: AuthService,
    private data: DataService
  ) { }

  ngOnInit(): void {
    this.subscription = this.data.currentMessage.subscribe(isPrijedlog => {this.isPrijedlog = isPrijedlog});
    this.subscription2 = this.data.currentMessage2.subscribe(isPohvala => {this.isPohvala = isPohvala})
    this.initializeMenu();
    this.dohvatiPPP();
  }

  ngOnDestroy(): void {
    // if(this.subscription){

    //   this.subscription.unsubscribe();
    // }

    // if(this.subscription2){
    //   this.subscription2.unsubscribe();
    // }
  }

  initializeMenu(){
    this.menuItems = new MenuItems().defaultPPPMenuItems;
    this.setPPPMenuItem();
  }

  setPPPMenuItem(){
    // console.log(this.selectedPPPMenuItem)
    if(this.isPrijedlog){
      this.selectedPPPMenuItem = this.menuItems[1];
      this.menuItems[0].isActive = false;
      this.menuItems[1].isActive = true;
      this.menuItems[2].isActive = false;
    }
    else if(this.isPohvala){
      this.selectedPPPMenuItem = this.menuItems[2];
      this.menuItems[0].isActive = false;
      this.menuItems[1].isActive = false;
      this.menuItems[2].isActive = true;     
    }
    else{
      this.selectedPPPMenuItem = this.menuItems.filter((item) => { if(item.isActive) return item;})[0];
    }
    this.data.changeMessage(false);
    this.data.changeMessage2(false);
  }

  handlePPPMenuItemClickBtnClick(selectedmenuItem){
    this.menuItems.map((item) => {
      item.type === selectedmenuItem.type ? item.isActive = true : item.isActive = false 
    });
    this.setPPPMenuItem();
  }

  dohvatiPPP(){
    const userId = this._authService.getUserId();
    const userToken = this._authService.getToken();
    const userType: KorisnikType = this._authService.getUserType();
    const partnerId = this._authService.getUserPartnerId();
    let requestData: any;

    if(userType === KorisnikType.NACELNIK){
      requestData = {
        'idgost': false,
        'idpartner': partnerId,
        'access_token': userToken
      }
    }
    else{
      requestData = {
        'idgost': false,
        'idpartner': partnerId,
        'idkorisnik': userId,
        'access_token': userToken
      }
    }
    this._api.dohvati3P(requestData).subscribe(res=>{
      if(res){
        this.ppp = res.data;
        this.initPPPTypes(this.ppp);
      } else {
        notify("Greška prilikom dohvata podataka!", "error", 1000);
      }
    });
  }

  initPPPTypes(pppList: IPPP []){
    pppList.map(ppp => {
      switch(ppp.pr_vrsta){
        case PPPVrsta.PRIJAVA:
          this.prijave.push(ppp);
          break;
        case PPPVrsta.PRIJEDLOG:
          this.prijedlozi.push(ppp);
          break;
        case PPPVrsta.POHVALA:
          this.pohvale.push(ppp);
          break;
      }
    });
  }

}
