import { Component, NgModule, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { UserPanelModule } from '../../user-panel/user-panel.component';
import { ApiService } from '../../../services/api.service';
import { DxButtonGroupModule, DxCheckBoxModule, DxFormComponent, DxFormModule, DxPopupModule, DxScrollViewModule, DxValidatorModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { AuthService } from '../../../services';
import notify from 'devextreme/ui/notify';
import { IKontakt, Kontakt } from 'src/app/shared/models/kontakt.model';
import { IOdjel, Odjel } from 'src/app/shared/models/odjel.model';

@Component({
  selector: 'add-odjel-form',
  templateUrl: 'add-odjel-form.component.html',
  styleUrls: ['./add-odjel-form.component.scss']
})

export class AddOdjelFormComponent implements OnInit {
  @ViewChild("addOdjelForm", { static: false }) addOdjelForm: DxFormComponent;

  @Input()
  isPopupVisible: boolean = false;
  @Input()
  partnerId!: number;
  
  @Output() openModalChange = new EventEmitter<boolean>();
  @Output() odjelCreated = new EventEmitter<boolean>();
  
  odjel: IOdjel = new Odjel();

  constructor(
    private _api: ApiService,
    private _authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.initOdjelValues();
    this.addOdjelForm.instance.getEditor("od_naziv").focus();
  }

  initOdjelValues(){
    this.odjel = new Odjel();
  }


  refresh() {
    this.addOdjelForm.instance.resetValues();
  }

  closePopup(): void {
    this.refresh();
    this.initOdjelValues();
    this.openModalChange.emit(false);
  }

  openPopup(): void {
    this.openModalChange.emit(true);
  }

  form_fieldDataChanged (e) {
    let updatedField = e.dataField;
    let newValue = e.value;
  }

  onFormSubmit(e: any){
    e.preventDefault();
    this.submitForm();
  }

  submitForm(){
    this.dodajOdjel();
  }

  onShown(e){
    this.addOdjelForm.instance.getEditor("od_oznaka").focus();
  }

  dodajOdjel(){
    const partnerId = this._authService.getUserPartnerId();

    this._api.dodajOdjel({
      'oznaka': this.odjel.od_oznaka,
      'naziv': this.odjel.od_naziv,
      'idpartner': partnerId,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res.success == true){
        notify("Uspješno ste dodali odjel!", "success", 1000);
        this.closePopup();
        this.odjelCreated.emit(true);
      } else {
        notify("Greška prilikom dodavanja prijavu!", "error", 1000);
      }
    });
  }

}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxPopupModule,
    DxFormModule,
    DxScrollViewModule,
    DxButtonGroupModule,
    DxCheckBoxModule,
    DxValidatorModule,
  ],
  declarations: [ AddOdjelFormComponent ],
  exports: [ AddOdjelFormComponent ]
})
export class AddOdjelFormComponentModule { }
