import { Component, NgModule, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { UserPanelModule } from '../../user-panel/user-panel.component';
import { ApiService } from '../../../services/api.service';
import { DxButtonGroupModule, DxCheckBoxModule, DxFormComponent, DxFormModule, DxMapModule, DxPopupModule, DxScrollViewModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import notify from 'devextreme/ui/notify';
import { AuthService } from '../../../services';
import { DEFAULT_PRIJAVA_OPTONS, IPPP, IPrijavaOption, PPPVrsta } from 'src/app/shared/models/ppp.model';
import { ImageSnippet } from 'src/app/shared/models/common.model';
import { GooglePlaceDirective, GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { GoogleMapComponentComponentModule } from '../../google-map-component/google-map-component.component';
import { IVrstaProblema } from 'src/app/shared/models/vrste-problema.model';
import { KorisnikType } from 'src/app/shared/models/korisnik.model';
import { GoogleMapsModule } from '@angular/google-maps';


@Component({
  selector: 'edit-prijava-form',
  templateUrl: 'edit-prijava-form.component.html',
  styleUrls: ['./edit-prijava-form.component.scss']
})

export class EditPrijavaFormComponent implements OnInit {
  @ViewChild("editPrijavaForm", { static: false }) editPrijavaForm: DxFormComponent;
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  mapMarkers = [];
  keys={};
  
  @Input()
  prijavaId!: number;
  @Input()
  isPopupVisible: boolean = false;

  @Output() openModalChange = new EventEmitter<boolean>();
  @Output() prijavaUpdated = new EventEmitter<boolean>();

  novaPrijava: IPPP;
  prijavaStatusOptions: IPrijavaOption[] = DEFAULT_PRIJAVA_OPTONS;
  selectedPrijavaOption: IPrijavaOption;
  adresaIzKarte: string;
  latIzKarte;
  lngIzKarte;
  selectedVrsta: IVrstaProblema;
  vrproblema_idproblem: number;
  problemiSelectOptions: IVrstaProblema[];
  trenutnaAdresa;
  latIzAdrese;
  lngIzAdrese;
  lokacijaIzAdrese;

  isDeleted: boolean = false;
  popupImageVisible: boolean=false;
  slikeZaPrikaz: string[];
  slike: any=[];
  triSlike:any=[];
  noveSlike:any=[];
  obslike: any[]=[];
  slikeFiles: any=[];

  constructor(
    private _api: ApiService,
    private _authService: AuthService,
  ) { 
    this.keys["google"]="AIzaSyAInEYFG0_ykzrvxDU8Qmf7c39MRO_qXCk";
   }

  ngOnInit(): void {
    this.dohvatiPrijavu();
    this.apiDohvatiVrsteProblema();
  }

  closePopup(): void {
    this.openModalChange.emit(false);
  }
  openPopup(): void {
    this.openModalChange.emit(true);
  }

  onFormSubmit(e: any){
    e.preventDefault();
    this.submitForm();
  }

  form_fieldDataChanged (e) {
    let updatedField = e.dataField;
    let newValue = e.value;
  }

  submitForm(){
    this.urediPrijavu();
  }

  onShown(e){
    this.editPrijavaForm.instance.getEditor("pr_naziv").focus();
  }

  dohvatiPrijavu(){
    this._api.dohvatiJedan3P({
      'idprijava': this.prijavaId,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res.success == true){
        this.novaPrijava = res.data[0];
        if(this.novaPrijava.pr_status){
          const selectedElementIndex = this.novaPrijava.pr_status - 1;
          this.selectedPrijavaOption = this.prijavaStatusOptions[selectedElementIndex];
        }
        this.latIzAdrese = this.novaPrijava.pr_lat;
        this.lngIzAdrese = this.novaPrijava.pr_lon;
        this.lokacijaIzAdrese = this.novaPrijava.pr_adresa;
      }
      for(let i=0;i<res.data[0].slike.length;i++){
        this.triSlike[i]=res.data[0].slike[i].putanja
        this.slikeFiles=res.data[0].slike
      }
    });
  }

  urediPrijavu(){
    this._api.uredi3P({
      'idprijava': this.novaPrijava.idprijava,
      'naziv': this.novaPrijava.pr_naziv,
      'opis': this.novaPrijava.pr_opis,
      'adresa': this.lokacijaIzAdrese,
      'lat': this.latIzAdrese,
      'lon': this.lngIzAdrese,
      'status': this.novaPrijava.pr_status,
      'idproblem': this.novaPrijava.problem_idproblem,
      'blokiraj': this.novaPrijava.blokiran,
      'idgost': this.novaPrijava.gost_idgost,
      'idkorisnik': this._authService.getUserId(),
      'obrisi': this.isDeleted,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res.success == true){
        notify("Uspješno ste uredili prijavu!", "success", 1000);
        this.closePopup();
        this.prijavaUpdated.emit(true);
      } else {
        notify("Greška prilikom uređivanja prijave!", "error", 1000);
      }
    });
  }

  apiDohvatiVrsteProblema(){
    this._api.dohvatiVrsteProblema({
      'idpartner': this._authService.getUserPartnerId(),
      'idkorisnik': this._authService.getUserId(),
      'access_token': this._authService.getToken()
    }).subscribe(res=>{
      if(res){
        this.problemiSelectOptions = res.data;
      } 
    });
  }

  processFile(imageInput: any) {
    for(let i=0;i<imageInput.files.length;i++){
      const file: File = imageInput.files[i];
      const reader = new FileReader();

      reader.addEventListener('load', (event: any) => {
        if(this.triSlike.length===3){
          notify("Možete odabrati samo tri fotografije!",'error');
        }else{
          this.triSlike.unshift(new ImageSnippet(event.target.result, file));
          this.noveSlike.push(file);
          this.triSlike[0]=this.triSlike[0].src;
        }
      });

      reader.readAsDataURL(file);
    }
  }

  openImage(){
    this.slike=[];
    this.popupImageVisible=true;
    for(let i=0;i<this.triSlike.length;i++){
      this.slike.push(this.triSlike[i]);
      this.slike.join(",");
    }
    this.slikeZaPrikaz=this.slike;
  }

  remove(slika,i){
    for(let j=0;j<this.slikeFiles.length;j++){
      if(slika===this.slikeFiles[j].putanja){
        this.obslike.push(this.slikeFiles[j].idslikadogadjaj);
        this.slikeFiles.splice(j,1);
      }
    }
    this.noveSlike.splice(i,1);
    this.triSlike.splice(i,1);
    this.isDeleted = true;
  }

  onModuleCheckboxChange(){
    const newValue = this.novaPrijava.blokiran === 0 ? 1 : 0;
    this.novaPrijava.blokiran = newValue;
  };

  prihvatiAdresu($event){
    this.adresaIzKarte = $event;
    if(this.adresaIzKarte == null){
      this.lokacijaIzAdrese = this.lokacijaIzAdrese;
    }else{
      this.lokacijaIzAdrese = this.adresaIzKarte;
    }
  }
  prihvatiLat($event){
    this.latIzKarte = $event;
    if(this.latIzKarte == null){
      this.latIzAdrese = this.latIzAdrese;
    }else{
      this.latIzAdrese = this.latIzKarte;
    }
  }
  prihvatiLng($event){
    this.lngIzKarte = $event;
    if(this.lngIzKarte == null){
      this.lngIzAdrese = this.lngIzAdrese;
    }else{
      this.lngIzAdrese = this.lngIzKarte;
    }
  }
}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxPopupModule,
    DxFormModule,
    DxScrollViewModule,
    DxButtonGroupModule,
    DxCheckBoxModule,
    DxMapModule,
    GooglePlaceModule,
    GoogleMapsModule,
    GoogleMapComponentComponentModule
  ],
  declarations: [ EditPrijavaFormComponent ],
  exports: [ EditPrijavaFormComponent ]
})
export class EditPrijavaFormComponentModule { }
