import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';

import notify from 'devextreme/ui/notify';
import { filter } from 'rxjs/operators';

import { KorisnikType, ModuleType } from '../models/korisnik.model';
import { ApiService } from './api.service';

const defaultPath = '/';
const defaultUser = {
  email: '',
  avatarUrl: ''
};

@Injectable()
export class AuthService {

  prava: number;
  private _user = defaultUser;
  token: string;
  private _loggedIn: boolean = false;
  get loggedIn(): boolean {
    return this._loggedIn;
  }

  private _lastAuthenticatedPath: string = defaultPath;
  set lastAuthenticatedPath(value: string) {
    this._lastAuthenticatedPath = value;
  }

  constructor(
    private router: Router,
    public _api: ApiService
  ) { this.imaToken(); }

  logIn(login: string, password: string) {
    this._api.login({'email': login, 'sifra': password}).subscribe(res=>{
      // console.log(res);
      if(res.success == true){
        sessionStorage.setItem('idkorisnik', res.data[0].idkorisnik);
        sessionStorage.setItem('kor_prava', res.data[0].kor_prava);
        sessionStorage.setItem('kor_ime', res.data[0].kor_ime);
        sessionStorage.setItem('kor_prezime', res.data[0].kor_prezime);
        sessionStorage.setItem('odjel_idodjel', res.data[0].odjel_idodjel);
        sessionStorage.setItem('partner_idpartner', res.data[0].partner_idpartner);
        sessionStorage.setItem('token', res.token.token);
        sessionStorage.setItem('username', res.token.username);
        sessionStorage.setItem('partner', res.data[0].pa_naziv);
        sessionStorage.setItem('moduli', JSON.stringify(res.data[0].moduli));
        
        this._loggedIn = true;
        this.authorize(this.getUserNivoPrava());

      } else {
        notify("Korisnik ne postoji ili nije aktivan!", "error", 1000);
      }
    });
  }

  authorize(nivoPrava: any) {
    switch (nivoPrava) {
      case KorisnikType.ZAPOSLENIK:
        this.router.navigate(['/pocetna']);
        break;
      case KorisnikType.NACELNIK:
        this.router.navigate(['/pocetna']);
        break;
      case KorisnikType.ADMIN:
        this.router.navigate(['/partneri']);
        break;
      case KorisnikType.VANJSKI:
        this.router.navigate(['/obavijesti']);
        break;
      default:
        this.router.navigate(['/login']);
        break;
    }
  }

  async getUser() {
     try {
       // Send request

       return {
         isOk: true,
         data: {
          email: sessionStorage.getItem('kor_punoIme')
          // avatarUrl: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/01.png'
          }  
       };
     }
     catch {
       return {
         isOk: false
       };
     }
  }

  async logOut() {
    this._loggedIn = false;
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  imaToken(){
    this.token = sessionStorage.getItem('token');
      if(this.token != null || this.token != undefined){
         this._loggedIn = true;
      } else {
         this._loggedIn = false;
      }
  }

  getUserPermissions() {
      const permissionNumber = sessionStorage.getItem('kor_admin');
      if (permissionNumber) {
        return permissionNumber;
      }
      return null;
  }

  getToken(){
    return sessionStorage.getItem('token');
  }

  getUsername(){
    return sessionStorage.getItem('username');
  }

  getUserId(){
    const korisnikIdString = sessionStorage.getItem('idkorisnik'); 
    return parseInt(korisnikIdString);
  }

  getUserNivoPrava(){
    return JSON.parse(sessionStorage.getItem('kor_prava'));
  }

  getUserPartnerId(){
    return JSON.parse(sessionStorage.getItem('partner_idpartner'));
  }

  getPartnerNaziv(){
    return sessionStorage.getItem('partner');
  }

  getUserType(){
    const nivoPrava = this.getUserNivoPrava();
    switch(nivoPrava){
      case KorisnikType.ZAPOSLENIK:
        return KorisnikType.ZAPOSLENIK;
      case KorisnikType.NACELNIK:
        return KorisnikType.NACELNIK;
      case KorisnikType.ADMIN:
        return KorisnikType.ADMIN;
      case KorisnikType.VANJSKI:
          return KorisnikType.VANJSKI;
      default:
        return KorisnikType.ZAPOSLENIK;
    }
  }

  getUserModulIds(){
    return JSON.parse(sessionStorage.getItem('moduli'));
  }

}

@Injectable()
export class AuthGuardService implements CanActivate {
  hasAccessToAdminPartneriPage: boolean = false;
  hasAccessToAdminSifarnikVrsteProblemaPage: boolean = false;
  hasAccessToNacelnikPocetnaPage: boolean = false;
  hasAccessToNacelnikPPPPage: boolean = false;
  hasAccessToNacelnikObavijestiPage: boolean = false;
  hasAccessToNacelnikDogadjanjaPage: boolean = false;
  hasAccessToNacelnikKontaktiPage: boolean = false;
  hasAccessToNacelnikOdjeliPage: boolean = false;
  hasAccessToNacelnikVrsteProblemaPage: boolean = false;
  hasAccessToNacelnikKorisniciPage: boolean = false;
  hasAccessToZaposlenikPocetnaPage: boolean = false;
  hasAccessToZaposlenikPPPage: boolean = false;
  hasAccessToVanjskiObavijestiPage: boolean = false;

  previousUrl: string = null;
  currentUrl: string = null;

  constructor(private router: Router, private authService: AuthService) { 
    this.listenForLastDestinationPath();
  }


  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isLoggedIn = this.authService.loggedIn;
    const isAuthForm = [
      'login'
    ].includes(route.routeConfig.path);

    //ADMIN ROUTES
    this.hasAccessToAdminPartneriPage = [
      'partneri'
    ].includes(route.routeConfig.path) && this.authService.getUserType() === KorisnikType.ADMIN;

    this.hasAccessToAdminSifarnikVrsteProblemaPage = [
      'sifarnik-vrste-problema'
    ].includes(route.routeConfig.path) && this.authService.getUserType() === KorisnikType.ADMIN;

    //NACELNIK ROUTES
    this.hasAccessToNacelnikPocetnaPage = [
      'pocetna'
    ].includes(route.routeConfig.path) && this.authService.getUserType() === KorisnikType.NACELNIK;

    this.hasAccessToNacelnikPPPPage = [
      'ppp'
    ].includes(route.routeConfig.path) && this.authService.getUserType() === KorisnikType.NACELNIK && this.authService.getUserModulIds().includes(ModuleType.PPP);

    this.hasAccessToNacelnikObavijestiPage = [
      'obavijesti'
    ].includes(route.routeConfig.path) && this.authService.getUserType() === KorisnikType.NACELNIK && this.authService.getUserModulIds().includes(ModuleType.OBAVIJESTI);

    this.hasAccessToNacelnikDogadjanjaPage = [
      'dogadjanja'
    ].includes(route.routeConfig.path) && this.authService.getUserType() === KorisnikType.NACELNIK && this.authService.getUserModulIds().includes(ModuleType.DOGADJANJA);

    this.hasAccessToNacelnikKontaktiPage = [
      'kontakti'
    ].includes(route.routeConfig.path) && this.authService.getUserType() === KorisnikType.NACELNIK && this.authService.getUserModulIds().includes(ModuleType.KONTAKTI);

    this.hasAccessToNacelnikOdjeliPage = [
      'odjeli'
    ].includes(route.routeConfig.path) && this.authService.getUserType() === KorisnikType.NACELNIK;

    this.hasAccessToNacelnikVrsteProblemaPage = [
      'vrste-problema'
    ].includes(route.routeConfig.path) && this.authService.getUserType() === KorisnikType.NACELNIK;

    this.hasAccessToNacelnikKorisniciPage = [
      'korisnici'
    ].includes(route.routeConfig.path);

    //ZAPOSLENIK
    this.hasAccessToZaposlenikPocetnaPage = [
      'pocetna'
    ].includes(route.routeConfig.path) && this.authService.getUserType() === KorisnikType.ZAPOSLENIK;

    this.hasAccessToZaposlenikPPPage = [
      'ppp'
    ].includes(route.routeConfig.path) && this.authService.getUserType() === KorisnikType.ZAPOSLENIK;

    //VANJSKI
    this.hasAccessToVanjskiObavijestiPage = [
      'obavijesti'
    ].includes(route.routeConfig.path) && this.authService.getUserType() === KorisnikType.VANJSKI;

    if (isLoggedIn && isAuthForm) {
      this.authService.lastAuthenticatedPath = defaultPath;
      this.router.navigate([defaultPath]);
      return false;
    }

    if (!isLoggedIn && !isAuthForm) {
      this.router.navigate(['/login']);
    }

    if (isLoggedIn) {
      if(this.authorizeUser()){
        this.authService.lastAuthenticatedPath = route.routeConfig.path;
      }
      else{
        this.authService.lastAuthenticatedPath = this.currentUrl;
        this.router.navigate([this.currentUrl]);
      }
    }

    return isLoggedIn || isAuthForm;
  }

  authorizeUser(){
    const userType: KorisnikType = this.authService.getUserType();
    let hasAccess: boolean = false;
    switch(userType){
      case KorisnikType.ADMIN:
        if(this.hasAccessToAdminPartneriPage){
          hasAccess = true;
        }
        else if(this.hasAccessToAdminSifarnikVrsteProblemaPage){
          hasAccess = true;
        }
        break;

      case KorisnikType.ZAPOSLENIK:
        if(this.hasAccessToZaposlenikPocetnaPage){
          hasAccess = true;
        }
        else if(this.hasAccessToZaposlenikPPPage){
          hasAccess = true;
        }
        break;

      case KorisnikType.VANJSKI:
        if(this.hasAccessToVanjskiObavijestiPage){
          hasAccess = true;
        }
        break;

      case KorisnikType.NACELNIK:
        if(this.hasAccessToNacelnikPPPPage){
          hasAccess = true;
        }
        else if(this.hasAccessToNacelnikKontaktiPage){
          hasAccess = true;
        }
        else if(this.hasAccessToNacelnikObavijestiPage){
          hasAccess = true;
        }
        else if(this.hasAccessToNacelnikDogadjanjaPage){
          hasAccess = true;
        }
        else if(this.hasAccessToNacelnikOdjeliPage){
          hasAccess = true;
        }
        else if(this.hasAccessToNacelnikPocetnaPage){
          hasAccess = true;
        }
        else if(this.hasAccessToNacelnikKorisniciPage){
          hasAccess = true;
        }
        else if(this.hasAccessToNacelnikVrsteProblemaPage){
          hasAccess = true;
        }
    }
    return hasAccess;
  }

  listenForLastDestinationPath(){
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      });
  }
  
}
