import { Component, Input, NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../../services';
import { ApiService } from '../../../services/api.service';
import { DxButtonModule, DxCheckBoxModule, DxDataGridModule, DxGalleryModule, DxPopupModule } from 'devextreme-angular';
import { IDogadjaj } from 'src/app/shared/models/dogadjaj.model';
import { AddDogadjanjeFormComponentModule } from '../../forme/add-dogadjanje-form/add-dogadjanje-form.component';
import { EditDogadjanjeFormComponentModule } from '../../forme/edit-dogadjanje-form/edit-dogadjanje-form.component';
import * as moment from 'moment';
import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';


@Component({
  selector: 'dogadjanja-tablica',
  templateUrl: 'dogadjanja-tablica.component.html',
  styleUrls: ['./dogadjanja-tablica.component.scss']
})

export class DogadjanjaTablicaComponent implements OnInit {
  @Input()
  dogadjanja!: IDogadjaj[];

  odabraniDogadjajId: number;

  isAddDogadjajFormVisible: boolean = false;
  isEditDogadjajFormVisible: boolean = false;

  slikaZaPrikaz: any;
  popupImageVisible: boolean = false;

  constructor(
    private _authService: AuthService,
    private _api: ApiService,
  ) { }

  ngOnInit() { }

  onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift({
    location: 'before',
    template: 'naslovTablice',    
      }
    )
  };
  
  formatDateCell(row){
    const date = moment(row.do_datum);
    return date.isValid() ? date.format('DD.MM.YYYY.') : "";
  }

  onDogadjajChange(isChanged){
    if(isChanged) this.dohvatiDogadjanja();
  }

  onAddDogadjajPopupChange(isVisible: boolean){
    this.isAddDogadjajFormVisible = isVisible;
  }

  onEditDogadjajPopupChange(isVisible: boolean){
    this.isEditDogadjajFormVisible = isVisible;
  }

  dohvatiDogadjanja(){
    const partnerId = this._authService.getUserPartnerId();
    const userToken = this._authService.getToken();

    this._api.dohvatiDogadjanja({
      'idpartner': partnerId,
      'access_token': userToken
    }).subscribe(res=>{
      if(res){
        this.dogadjanja = res.data;
      } else {
        notify("Greška prilikom dohvata događanja!", "error", 1000);
      }
    });
  }

  dodajDogadjaj(){
    this.isAddDogadjajFormVisible = true;
  }

  urediDogadjaj(row){
    const dogadjaj: IDogadjaj = row.data; 
    this.odabraniDogadjajId = dogadjaj.iddogadjaj;
    this.isEditDogadjajFormVisible = true;
  }

  openObrisiDogadjaj(row){
    const dogadjaj: IDogadjaj = row.data; 
    this.odabraniDogadjajId = dogadjaj.iddogadjaj;
    this.showDialog();
  }

  openImage(e){
    this.slikaZaPrikaz=[]
    for(let i=0;i<e.data.slike.length;i++){
      this.slikaZaPrikaz.push(e.data.slike[i].putanja)
      this.slikaZaPrikaz.join(",")
    }

    if(this.slikaZaPrikaz.length == 0){
      this.popupImageVisible = false;
      notify("Ne postoji slika događanja!", "info", 1000);
    }else this.popupImageVisible=true;
  }

  showDialog(){
    let myDialog = custom({
      title: "BRISANJE DOGAĐANJA",
      messageHtml: "<b>Jeste li sigurni da želite obrisati događanje?</b>",
      buttons: [{
          text: "DA", 
          type: 'danger',
          onClick: (e) => {
              return { buttonText: e.component.option("text") }
          },
          
      }, 
      {
        text: "NE",
        type: 'default',
        onClick: (e) => {
            return { buttonText: e.component.option("text") }
        }}
      ]
  });

  myDialog.show().then((dialogResult) => {
    const korisnikId = this._authService.getUserId();
    const userToken = this._authService.getToken();

      if(dialogResult.buttonText === "DA"){
        this._api.obrisiDogadjanje({
          'iddogadjaj': this.odabraniDogadjajId,
          'idkorisnik': korisnikId,
          'access_token': userToken
        }).subscribe(res=>{
          // console.log(res)
          if(res.success == true){
            notify("Uspješno ste obrisali događanje!", "success", 1000);
            this.dohvatiDogadjanja();
          } else {
            notify("Greška prilikom brisanja događanja", "error", 1000);
          }
        });
    
        
      }
  });
  }

}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxButtonModule,
    AddDogadjanjeFormComponentModule,
    EditDogadjanjeFormComponentModule,
    DxCheckBoxModule,
    DxPopupModule,
    DxGalleryModule
  ],
  declarations: [ DogadjanjaTablicaComponent ],
  exports: [ DogadjanjaTablicaComponent ]
})
export class DogadjanjaTablicaComponentModule { }
