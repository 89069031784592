<dx-popup
    [title]="title"
    [(visible)]="isPopupVisible" 
    [closeOnOutsideClick]="true"
    [showCloseButton]="true"
    (onHiding)="closePopup()"
    (onShown)="onShown($event)">

    <dx-scroll-view width="100%" height="100%">

      <form (submit)="onFormSubmit($event)">

        <dx-form
            #editVrstaProblemaForm
            *ngIf="novaVrstaProblema"
            id="edit-vrsta-problema-form"
            class="edit-vrsta-problema-form"
            [(formData)]="novaVrstaProblema"
            [showColonAfterLabel]="true"
            [readOnly]="false"
            (onFieldDataChanged)="form_fieldDataChanged($event)"
            [showValidationSummary]="true"
            validationGroup="EditVrstaProblemaData">

            <dxi-item itemType="group" [colCount]="2">
              <dxi-item [colSpan]="1" dataField="pr_naziv" [label]="{text: 'Naziv'}" editorType="dxTextBox">
                  <dxi-validation-rule type="required" message="Naziv vrste problema je nužan."></dxi-validation-rule>
              </dxi-item>
            </dxi-item>

            <dxi-item itemType="group" caption="Popis zaduženih osoba">
              <dxi-item>
                  <zaduzene-osobe-tablica (zaduzeneOsobeChange)="onZaduzeneOsobeChange($event)" 
                    [zaduzeneOsobeIds]="zaduzeneOsobeIds" [zaduzeneOsobe]="novaVrstaProblema.zaduzeni">
                  </zaduzene-osobe-tablica>
              </dxi-item>
            </dxi-item>

            
          </dx-form>
          
          <dxi-item class="edit-vrsta-problema-form__button-group">
              <dx-button
                  text="Spremi"
                  type="success"
                  [useSubmitBehavior]="true"
                  validationGroup="EditVrstaProblemaData">
              </dx-button>
              <dx-button
                  text="Odustani"
                  type="danger"
                  (onClick)="closePopup()">
              </dx-button>
          </dxi-item>

      </form>

    </dx-scroll-view>

</dx-popup>