import { IOdgovornaOsoba } from "./odgovorna-osoba.model";

export interface IVrstaProblema {
    idproblem: number,
    pr_naziv: string,
    zaduzeni: IOdgovornaOsoba[],
}

export class VrstaProblema implements IVrstaProblema {
    constructor(
        public idproblem: number = undefined,
        public pr_naziv: string = '',
        public zaduzeni: IOdgovornaOsoba[] = [],
    ) {}
}
