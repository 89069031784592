<div class="content-block odjeli-tablica-container">

  <dx-data-grid
      id="odjeli-datagrid"
      class="odjeli-datagrid"
      [rowAlternationEnabled]="true"
      [hoverStateEnabled]="true"
      [dataSource]="odjeli"
      keyExpr="idodjel"
      [wordWrapEnabled]="true"
      [showRowLines]="true"
      [showBorders]="false"
      [columnAutoWidth]="false"
      [columnHidingEnabled]="false"
      (onToolbarPreparing)="onToolbarPreparing($event)"
      [height]="824">

      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-search-panel [visible]="true" [width]="240" placeholder="Pretraži..."></dxo-search-panel>
      
      <div *dxTemplate="let data of 'naslovTablice'">
        <div class="odjeli-tablica-container__header-content-container">
          <p class="odjeli-tablica-container__header-content-container__title">Popis odjela: {{odjeli && odjeli.length}}</p>
          <dx-button
            icon="add" type="default"
            hint="Dodaj odjel" stylingMode="contained" 
            (onClick)="dodajOdjel()">
        </dx-button>
        </div>
      </div>

      <dxi-column dataField="idodjel" [visible]="false"></dxi-column>
      <dxi-column dataField="od_oznaka" caption="Oznaka"></dxi-column>
      <dxi-column dataField="od_naziv" caption="Naziv odjela"></dxi-column>

      <dxi-column alignment="center" cellTemplate="editTemplate" class="odjeli-datagrid__actions-row"></dxi-column>
        <div *dxTemplate="let row of 'editTemplate'" class="odjeli-datagrid__actions-row__actions">
          <dx-button (onClick)="urediOdjel(row)" icon="edit" stylingMode="contained" type="default"></dx-button>
          <dx-button (onClick)="openObrisiOdjel(row)" icon="trash" stylingMode="contained" type="danger"></dx-button>
        </div>

      <dxo-paging [pageSize]="15"></dxo-paging>
      <dxo-pager
        [visible]="true"
        [allowedPageSizes]="[15, 30, 50]"
        [displayMode]="'full'"
        [showPageSizeSelector]="true"
        [showInfo]="true"
        [showNavigationButtons]="true">
      </dxo-pager>
  </dx-data-grid>

</div>

<add-odjel-form
    [isPopupVisible]="isAddOdjelFormVisible"
    (openModalChange)="onAddOdjelPopupChange($event)"
    (odjelCreated)="onOdjelChange($event)">
</add-odjel-form>

<edit-odjel-form
    *ngIf="isEditOdjelFormVisible && odabraniOdjelId"
    [odjelId]="odabraniOdjelId"
    [isPopupVisible]="isEditOdjelFormVisible"
    (openModalChange)="onEditOdjelPopupChange($event)"
    (odjelUpdated)="onOdjelChange($event)">
</edit-odjel-form>

