<dx-popup
    title="DODAJ OBAVIJEST"
    [width]="650"
    [height]="620"
    [(visible)]="isPopupVisible" 
    [closeOnOutsideClick]="true"
    [showCloseButton]="true"
    (onHiding)="closePopup()"
    (onShown)="onShown($event)">

    <dx-scroll-view width="100%" height="100%">

        <form (submit)="onFormSubmit($event)">

            <dx-form 
                #addObavijestForm
                *ngIf="obavijest"
                id="add-obavijest-form"
                class="add-obavijest-form"
                [(formData)]="obavijest"
                [readOnly]="false"
                [showColonAfterLabel]="true"
                (onFieldDataChanged)="form_fieldDataChanged($event)"
                [colCount]="1"
                [showValidationSummary]="true"
                validationGroup="AddObavijestData">
                    
                <dxi-item itemType="group" [colCount]="2">
                    <dxi-item [colSpan]="1" [label]="{text: 'Datum'}" dataField="ob_datum" editorType="dxDateBox" 
                        [editorOptions]="{type: 'date', displayFormat: 'dd.MM.yyyy.'}" [editorOptions]="{width: '150%'}">
                        <dxi-validation-rule type="required" message="Datum je nužan."></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item [label]="{text: 'Aktivna'}" dataField="ob_aktivna" editorType="dxCheckBox"></dxi-item>
                </dxi-item>

                <dxi-item [label]="{text: 'Tekst obavijesti'}" dataField="ob_tekst" editorType="dxTextArea"
                    [editorOptions]="{height: 150}">
                    <dxi-validation-rule type="required" message="Tekst obavijesti je nužan."></dxi-validation-rule>
                    <dxi-validation-rule type="stringLength" [min]="15" message="Tekst obavijesti mora sadržavati minimalno 15 znakova"></dxi-validation-rule>
                </dxi-item>

                <dxi-item [label]="{text: 'Web'}" dataField="ob_web" editorType="dxTextBox">
                    <dxi-validation-rule type="pattern" pattern="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})" message="Format linka nije ispravan!"></dxi-validation-rule>
                </dxi-item>
                
            </dx-form>
            
            <dxi-item class="add-obavijest-form__button-group">
                <dx-button
                    text="Spremi"
                    type="success"
                    validationGroup="AddObavijestData"
                    [useSubmitBehavior]="true">
                </dx-button>
                <dx-button
                    text="Odustani"
                    type="danger"
                    (onClick)="closePopup()">
                </dx-button>
            </dxi-item>
            
        </form>   

    </dx-scroll-view>

</dx-popup>