<div class="content-block prijedlozi-tablica-container">

      <div class="content-block prijedlozi-tablica-container">

        <dx-data-grid
          id="prijedlozi-datagrid"
          class="prijedlozi-datagrid"
          [rowAlternationEnabled]="true"
          [hoverStateEnabled]="true"
          [dataSource]="prijedlozi"
          keyExpr="idprijava"
          [wordWrapEnabled]="true"
          [showRowLines]="true"
          [showBorders]="false"
          [columnAutoWidth]="false"
          [columnHidingEnabled]="false"
          (onToolbarPreparing)="onToolbarPreparing($event)"
          [height]="774">

          <dxo-load-panel [enabled]="true"></dxo-load-panel>
          <dxo-header-filter [visible]="true"></dxo-header-filter>
          <dxo-filter-row [visible]="false"></dxo-filter-row>
          <dxo-search-panel [visible]="true" [width]="240" placeholder="Pretraži..."></dxo-search-panel>
          
          <div *dxTemplate="let data of 'naslovTablice'">
            <div class="prijedlozi-tablica-container__header-content-container">
              <p class="prijedlozi-tablica-container__header-content-container__title">Popis prijedloga: {{prijedlozi && prijedlozi.length}}</p>
              <dx-button
                icon="add" type="default"
                hint="Dodaj prijavu" stylingMode="contained" 
                (onClick)="dodajPrijedlog()">
              </dx-button>
            </div>
          </div>

          <dxi-column dataField="idprijava" [visible]="false"></dxi-column>
          <dxi-column dataField="pr_naziv" caption="Naslov" [allowFiltering]="false"></dxi-column>
          <dxi-column dataField="pr_opis" caption="Opis" [allowFiltering]="false"></dxi-column>

          <dxi-column dataField="pr_prikaziMob" caption="Prikaz" alignment="center" cellTemplate="approveTemplate" class="partners-datagrid__actions-row"  width="110"  [allowHeaderFiltering]="false"></dxi-column>
            <div *dxTemplate="let row of 'approveTemplate'">
              <dx-check-box [(value)]="row.value" (onValueChanged)="odobri(row)"></dx-check-box>
            </div>

          <dxi-column alignment="center" cellTemplate="editTemplate" class="partners-datagrid__actions-row"></dxi-column>
            <div *dxTemplate="let row of 'editTemplate'" class="partners-datagrid__actions-row__actions">
              <dx-button (onClick)="urediPrijedlog(row)" icon="edit" stylingMode="contained" type="default"></dx-button>
              <dx-button *ngIf="userType != 0" (onClick)="obrisiPrijedlog(row)" icon="trash" stylingMode="contained" type="danger"></dx-button>
            </div>

          <dxo-paging [pageSize]="10"> </dxo-paging>
          <dxo-pager
            [visible]="true"
            [allowedPageSizes]="[10, 30, 50]"
            [displayMode]="'full'"
            [showPageSizeSelector]="true"
            [showInfo]="true"
            [showNavigationButtons]="true">
          </dxo-pager>
        </dx-data-grid>

    </div>

</div>

<add-prijedlog-form
    [isPopupVisible]="isAddPrijedlogFormVisible"
    (openModalChange)="onAddPrijedlogPopupChange($event)"
    (prijedlogCreated)="onPrijedlogChange($event)">
</add-prijedlog-form>

<edit-prijedlog-form
    *ngIf="isEditPrijedlogFormVisible && odabraniPrijedlogId"
    [prijedlogId]="odabraniPrijedlogId"
    [isPopupVisible]="isEditPrijedlogFormVisible"
    (openModalChange)="onEditPrijedlogPopupChange($event)"
    (prijedlogUpdated)="onPrijedlogChange($event)">
</edit-prijedlog-form>