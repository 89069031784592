import { Component, NgModule, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { UserPanelModule } from '../../user-panel/user-panel.component';
import { ApiService } from '../../../services/api.service';
import { DxButtonGroupModule, DxCheckBoxModule, DxFormComponent, DxFormModule, DxMapModule, DxPopupModule, DxScrollViewModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import notify from 'devextreme/ui/notify';
import { AuthService } from '../../../services';
import { IPPP } from 'src/app/shared/models/ppp.model';

@Component({
  selector: 'edit-prijedlog-form',
  templateUrl: 'edit-prijedlog-form.component.html',
  styleUrls: ['./edit-prijedlog-form.component.scss']
})

export class EditPrijedlogFormComponent implements OnInit {
  @ViewChild("editPrijedlogForm", { static: false }) editPrijedlogForm: DxFormComponent;
  
  @Input()
  prijedlogId!: number;
  @Input()
  isPopupVisible: boolean = false;

  @Output() openModalChange = new EventEmitter<boolean>();
  @Output() prijedlogUpdated = new EventEmitter<boolean>();

  noviPrijedlog: IPPP;

  constructor(
    private _api: ApiService,
    private _authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.dohvatiPrijavu();
  }

  closePopup(): void {
    this.openModalChange.emit(false);
  }
  openPopup(): void {
    this.openModalChange.emit(true);
  }

  form_fieldDataChanged (e) {
    let updatedField = e.dataField;
    let newValue = e.value;
  }

  submitForm(){
    this.urediPrijedlog();
  }

  onShown(e){
    this.editPrijedlogForm.instance.getEditor("pr_naziv").focus();
  }

  dohvatiPrijavu(){
    this._api.dohvatiJedan3P({
      'idprijava': this.prijedlogId,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      // console.log(res)
      if(res.success == true){
        this.noviPrijedlog = res.data[0];
      }
    });
  }

  urediPrijedlog(){
    this._api.uredi3P({
      'idprijava': this.noviPrijedlog.idprijava,
      'naziv': this.noviPrijedlog.pr_naziv,
      'opis': this.noviPrijedlog.pr_opis,
      'adresa': this.noviPrijedlog.pr_adresa,
      'lat': this.noviPrijedlog.pr_lat,
      'lon': this.noviPrijedlog.pr_lon,
      'status': this.noviPrijedlog.pr_status,
      'blokiraj': this.noviPrijedlog.blokiran,
      'idgost': this.noviPrijedlog.gost_idgost,
      'idkorisnik': this._authService.getUserId(),
      // 'slikaMob': this.novaPrijedlog.slika,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res.success == true){
        notify("Uspješno ste uredili prijedlog!", "success", 1000);
        this.closePopup();
        this.prijedlogUpdated.emit(true);
      } else {
        notify("Greška prilikom uređivanja prijedloga!", "error", 1000);
      }
    });
  }

}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxPopupModule,
    DxFormModule,
    DxScrollViewModule,
    DxButtonGroupModule,
    DxCheckBoxModule,
    DxMapModule
  ],
  declarations: [ EditPrijedlogFormComponent ],
  exports: [ EditPrijedlogFormComponent ]
})
export class EditPrijedlogFormComponentModule { }
