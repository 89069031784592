<dx-popup
    [title]="hederText"
    [width]="600"
    [height]="350"
    [(visible)]="isPopupVisible" 
    [closeOnOutsideClick]="true"
    [showCloseButton]="true"
    (onHiding)="closePopup()"
    (onShown)="onShown($event)">

    <dx-scroll-view width="100%" height="100%">

        <form (submit)="onFormSubmit($event)">

            <dx-form #addVrstaProblemaForm
                *ngIf="vrstaProblema"
                id="add-vrsta-problema-form"
                class="add-vrsta-problema-form"
                [(formData)]="vrstaProblema"
                [showColonAfterLabel]="true"
                [readOnly]="false"
                (onFieldDataChanged)="form_fieldDataChanged($event)"
                [showValidationSummary]="true"
                validationGroup="AddVrstaProblemaData">
    
                <dxi-item itemType="group" [colCount]="2">
                    <dxi-item [colSpan]="1" dataField="Vrsta problema" editorType="dxTextBox" [editorOptions]="{width: '220%'}">
                        <dxi-validation-rule type="required" message="Naziv vrste problema je nužan."></dxi-validation-rule>
                    </dxi-item>
                </dxi-item>
    
                
            </dx-form>
            
            <dxi-item class="add-vrsta-problema-form__button-group">
                <dx-button
                    text="Spremi"
                    type="success"
                    validationGroup="AddVrstaProblemaData"
                    [useSubmitBehavior]="true">
                </dx-button>
                <dx-button
                    text="Odustani"
                    type="danger"
                    (onClick)="closePopup()">
                </dx-button>
            </dxi-item>

        </form>

    </dx-scroll-view>

</dx-popup>