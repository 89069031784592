import { IKorisnik } from "./korisnik.model";
import { IModul } from "./modul.model";

export interface IPartner {
    idpartner: number,
    pa_naziv: string,
    pa_adresa: string,
    pa_odgosoba: string,
    pa_telefon: string,
    pa_email: string,
    pa_web: string,
    kor_ime: string,
    kor_prezime: string,
    pa_aktivan: number,
    moduli: IModul[],
    korisnici: IKorisnik[],
}

export class Partner implements IPartner {
    constructor(
        public idpartner: number = undefined,
        public pa_naziv: string = '',
        public pa_adresa: string = '',
        public pa_odgosoba: string = '',
        public pa_telefon: string = '',
        public pa_email: string = '',
        public pa_web: string = '',
        public kor_ime: string = '',
        public kor_prezime: string = '',
        public pa_aktivan: number = 0,
        public moduli: IModul[] = [],
        public korisnici: IKorisnik[] = [],
    ) {}
}
