import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AppInfoService {

   any;
  constructor(
    private _authService: AuthService
  ) {}


  public get title() {
    const partner = "MyPlaceInfo | " + this._authService.getPartnerNaziv();
    return partner;
  }

  public get currentYear() {
    return new Date().getFullYear();
  }
}
