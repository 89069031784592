import { Component } from '@angular/core';
import { custom } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  templateUrl: 'profile.component.html',
  styleUrls: [ './profile.component.scss' ]
})

export class ProfileComponent {
  employee: any;
  colCountByScreen: object;

  constructor(
    public _api: ApiService,
  ) {
    this.employee = {
      ID: 7,
      FirstName: 'Sandra',
      LastName: 'Johnson',
      Prefix: 'Mrs.',
      Position: 'Controller',
      Picture: 'images/employees/06.png',
      BirthDate: new Date('1974/11/15'),
      HireDate: new Date('2005/05/11'),
      /* tslint:disable-next-line:max-line-length */
      Notes: 'Sandra is a CPA and has been our controller since 2008. She loves to interact with staff so if you`ve not met her, be certain to say hi.\r\n\r\nSandra has 2 daughters both of whom are accomplished gymnasts.',
      Address: '4600 N Virginia Rd.'
    };
    this.colCountByScreen = {
      xs: 1,
      sm: 2,
      md: 3,
      lg: 4
    };
  }

  promjenaLozinke(data:any){
    let encodedMessage = "Poveznicu za postavljanje nove lozinke poslat ćemo vam na e-mail.";
    let myDialog = custom({
        title: "RESET LOZINKE",
        messageHtml: encodedMessage,
        buttons: [{
            text: "POŠALJI",
            onClick: (e) => {
                return { buttonText: e.component.option("text") }
            }
        },{
          text: "ODUSTANI",
          onClick: (e) => {
              return { buttonText: e.component.option("text") }
          }
      }]
    });
    myDialog.show().then((dialogResult:any) => {
        if(dialogResult.buttonText === 'POŠALJI'){
            this._api.zatraziSifru({'email': data.ko_email}).subscribe(res=>{
                if(res.success == true){
                  sessionStorage.clear();
                  localStorage.clear();
                  window.location.reload();
                  notify("Poslali smo poveznicu za poništavanje vaše lozinke. Provjerite svoj inbox.", 'success', 2500);
                } else {
                  notify("Došlo je do greške kod promjene lozinke!", "error", 1000);
                }
            });
        } 
    });
  }
}
