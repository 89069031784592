import { Component, NgModule, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { UserPanelModule } from '../../user-panel/user-panel.component';
import { ApiService } from '../../../services/api.service';
import { DxButtonGroupModule, DxCheckBoxModule, DxFormComponent, DxFormModule, DxMapModule, DxPopupModule, DxScrollViewModule, DxValidatorModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import notify from 'devextreme/ui/notify';
import { AuthService } from '../../../services';
import { IPPP } from 'src/app/shared/models/ppp.model';
import { IObavijest, Obavijest } from 'src/app/shared/models/obavijesti.model';
import { IDogadjaj } from 'src/app/shared/models/dogadjaj.model';
import { IKontakt } from 'src/app/shared/models/kontakt.model';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { GoogleMapComponentComponentModule } from '../../google-map-component/google-map-component.component';


@Component({
  selector: 'edit-kontakt-form',
  templateUrl: 'edit-kontakt-form.component.html',
  styleUrls: ['./edit-kontakt-form.component.scss']
})

export class EditKontaktFormComponent implements OnInit {
  @ViewChild("editKontaktForm", { static: false }) editKontaktForm: DxFormComponent;
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  mapMarkers = [];
  keys={};

  @Input()
  kontaktId!: number;
  @Input()
  isPopupVisible: boolean = false;

  @Output() openModalChange = new EventEmitter<boolean>();
  @Output() kontaktUpdated = new EventEmitter<boolean>();

  noviKontakt: IKontakt;

  constructor(
    private _api: ApiService,
    private _authService: AuthService,
  ) {  }

  ngOnInit(): void {
    this.dohvatiKontakt();
  }

  closePopup(): void {
    this.openModalChange.emit(false);
  }
  openPopup(): void {
    this.openModalChange.emit(true);
  }

  form_fieldDataChanged (e) {
    let updatedField = e.dataField;
    let newValue = e.value;
  }

  onFormSubmit(e){
    e.preventDefault();
    this.submitForm();
  }

  submitForm(){
    this.urediKontakt();
  }

  onShown(e){
    this.editKontaktForm.instance.getEditor("ko_naziv").focus();
  }

  dohvatiKontakt(){
    this._api.dohvatiKontakt({
      'idkontakt': this.kontaktId,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res){
        const kontakt: IKontakt = res.data[0];
        this.noviKontakt = kontakt;
      }
    });
  }

  urediKontakt(){
    this._api.urediKontakt({
      'idkontakt': this.noviKontakt.idkontakt,
      'naziv': this.noviKontakt.ko_naziv,
      'adresa': this.noviKontakt.ko_adresa,
      'telefon': this.noviKontakt.ko_telefon,
      'mobitel': this.noviKontakt.ko_mobitel,
      'mail': this.noviKontakt.ko_email,
      'web': this.noviKontakt.ko_web,
      'lat': '1.00',
      'lon': '1.00',
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res.success == true){
        notify("Uspješno ste dodali kontakta!", "success", 1000);
        this.closePopup();
        this.kontaktUpdated.emit(true);
      } else {
        notify("Greška prilikom dodavanja kontakta!", "error", 1000);
      }
    });
  }

}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxPopupModule,
    DxFormModule,
    DxScrollViewModule,
    DxButtonGroupModule,
    DxCheckBoxModule,
    DxMapModule,
    DxValidatorModule,
    GoogleMapComponentComponentModule
  ],
  declarations: [ EditKontaktFormComponent ],
  exports: [ EditKontaktFormComponent ]
})
export class EditKontaktFormComponentModule { }
