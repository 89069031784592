import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { ValidationCallbackData } from 'devextreme/ui/validation_rules';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import notify from 'devextreme/ui/notify';

import { ApiService } from 'src/app/shared/services/api.service';


@Component({
  selector: 'app-change-passsword-form',
  templateUrl: './change-password-form.component.html'
})
export class ChangePasswordFormComponent implements OnInit {

  loading = false;
  formData: any = {};
  recoveryCode: string = '';

  constructor(
    public _api: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.recoveryCode = params.get('recoveryCode') || '';
    });
  }

  async onSubmit(e: Event) {

    e.preventDefault();
    const { password } = this.formData;
    this.loading = true;

    this._api.resetSifru({'sifra': password, 'token': this.recoveryCode}).subscribe(res=>{
      // console.log(res);
      if(res.success == true){
        this.loading = false;
        this.router.navigate(['/login']);
        notify("Uspješno promijenjena lozinka!", 'success', 2500);
      } else {
        this.loading = false;
        notify("Dogodila se greška! Pokušajte ponovo!", "error", 1000);
      }
    });

  }

  confirmPassword = (e: ValidationCallbackData) => {
    return e.value === this.formData.password;
  }

}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule
  ],
  declarations: [ ChangePasswordFormComponent ],
  exports: [ ChangePasswordFormComponent ]
})
export class ChangePasswordFormModule { }