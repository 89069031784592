import { Component, NgModule, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { UserPanelModule } from '../../user-panel/user-panel.component';
import { ApiService } from '../../../services/api.service';
import { DxButtonGroupModule, DxFormComponent, DxFormModule, DxPopupModule, DxScrollViewModule, DxValidatorModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { IVrstaProblema, VrstaProblema } from '../../../models/vrste-problema.model';
import { AuthService } from '../../../services';
import notify from 'devextreme/ui/notify';
import { TipVrsteProblema } from 'src/app/shared/models/common.model';


@Component({
  selector: 'add-vrste-problema-form',
  templateUrl: 'add-vrste-problema-form.component.html',
  styleUrls: ['./add-vrste-problema-form.component.scss']
})

export class AddDefaultVrsteProblemaFormComponent implements OnInit {
  @ViewChild("addVrstaProblemaForm", { static: false }) addVrstaProblemaForm: DxFormComponent;
  
  @Input()
  isPopupVisible!: boolean;
  @Input()
  type!: TipVrsteProblema;

  
  @Output() openModalChange = new EventEmitter<boolean>();
  @Output() vrstaProblemaCreated = new EventEmitter<boolean>();
  
  hederText: string;
  vrstaProblema: IVrstaProblema = new VrstaProblema();

  constructor(
    private _api: ApiService,
    private _authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.hederText = this.type === TipVrsteProblema.VRSTA_PROBLEMA ? 'Dodaj vrstu problema' : 'Dodaj defaultnu vrstu problema'; 
  }

  onFormSubmit(e: any){
    e.preventDefault();
    this.submitForm();
  }

  refresh() {
      this.addVrstaProblemaForm.instance.resetValues();
  }

  closePopup(): void {
    this.refresh();
    this.openModalChange.emit(false);
  }

  openPopup(): void {
    this.openModalChange.emit(true);
  }

  form_fieldDataChanged(e) {
    let updatedField = e.dataField;
    let newValue = e.value;

    if(updatedField === "Vrsta problema"){
      this.vrstaProblema.pr_naziv = newValue;
    }
  }

  submitForm(){
    this.dodajVrstuProblema();
  }

  onShown(e){
    this.addVrstaProblemaForm.instance.getEditor("Vrsta problema").focus();
  }

  dodajVrstuProblema(){
    if(this.type === TipVrsteProblema.DEFAUL_VRSTA_PROBLEMA){
      this.apiDodajDefaultVrstuProblema();
    }
    else{
      this.apiDodajVrstuProblema();
    }
  }

  apiDodajDefaultVrstuProblema(){
    this._api.dodajDefaultVrstuProblema({
      'naziv': this.vrstaProblema.pr_naziv,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      // console.log(res)
      if(res.success == true){
        notify("Uspješno ste dodali vrstu problema!", "success", 1000);
        this.closePopup();
        this.vrstaProblemaCreated.emit(true);
      } else {
        notify("Greška prilikom dodavanja vrste problema!", "error", 1000);
      }
    });
  }

  apiDodajVrstuProblema(){
    this._api.dodajVrstuProblema({
      'naziv': this.vrstaProblema.pr_naziv,
      // 'korisnici': [this._authService.getUserId()],
      'idpartner': this._authService.getUserPartnerId(),
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      // console.log(res)
      if(res.success == true){
        notify("Uspješno ste dodali vrstu problema!", "success", 1000);
        this.closePopup();
        this.vrstaProblemaCreated.emit(true);
      } else {
        notify("Greška prilikom dodavanja vrste problema!", "error", 1000);
      }
    });
  }

}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxPopupModule,
    DxFormModule,
    DxScrollViewModule,
    DxButtonGroupModule,
    DxValidatorModule
  ],
  declarations: [ AddDefaultVrsteProblemaFormComponent ],
  exports: [ AddDefaultVrsteProblemaFormComponent ]
})
export class AddDefaultVrsteProblemaFormModule { }
