import { Component, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { Observable } from 'rxjs';
import { IPartner } from 'src/app/shared/models/partneri.model';
import { AuthService } from 'src/app/shared/services';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-partneri',
  templateUrl: './partneri.component.html',
  styleUrls: ['./partneri.component.scss']
})
export class PartneriComponent implements OnInit {
  partneri: Observable<IPartner[]>;
  odabraniPartnerId: number;

  isAddPartnerFormVisible: boolean = false;
  isEditPartnerFormVisible: boolean = false;
  slikaZaPrikaz: any;
  popupImageVisible: boolean = false;
  aktivanField_calculatedField

  constructor(
    private _api: ApiService,
    private _authService: AuthService
  ) { }

  ngOnInit(): void {
    this.dohvatiPartnere();
  }

  status_calculateCellValue (rowData: IPartner) {
    if(rowData.pa_aktivan === 1){
      return `AKTIVAN`;
    }
    else{
      return `NEAKTIVAN`;
    }
  }

  onToolbarPreparing(e) { 
    e.toolbarOptions.items.unshift({
    location: 'before',
    template: 'naslovTablice',    
      }
    )
  }

  dohvatiPartnere(){
    this._api.dohvatiPartnere({'access_token': this._authService.getToken()}).subscribe(res=>{
      // console.log(res)
      if(res){
        this.partneri = res.data;
      } else {
        notify("Greška prilikom dohvata partnera!", "error", 1000);
      }
    });
  }

  onPartnerChange(isChanged){
    if(isChanged) this.dohvatiPartnere();
  }

  onAddPartnerPopupChange(isVisible: boolean){
    this.isAddPartnerFormVisible = isVisible;
  }

  onEditPartnerPopupChange(isVisible: boolean){
    this.isEditPartnerFormVisible = isVisible;
  }

  dodajPartnera(){
    this.isAddPartnerFormVisible = true;
  }

  urediPartnera(row){
    const partner: IPartner = row.data; 
    this.odabraniPartnerId = partner.idpartner;
    this.isEditPartnerFormVisible = true;
  }

  onActivateDeactivateClick(partner: IPartner, aktiviraj){
    this.aktivirajDeaktivirajPartnera(partner, aktiviraj);
  }

  aktivirajDeaktivirajPartnera(partnerId, aktiviraj){
    this._api.aktivitajDeaktivirakPartnera({
      'idpartner': partnerId,
      'aktivan': aktiviraj ? 0 : 1,
      'access_token': this._authService.getToken(),
    }).subscribe(res=>{
      if(res.success == true){
        notify(`Uspješno ste ${aktiviraj ? 'deaktivirali' : 'aktivirali'} partnera!`, "success", 1000);
        this.onPartnerChange(true);
      } else {
        notify(`Greška prilikom ${aktiviraj ? 'deaktivacije' : 'aktivacije'} partnera!`, "error", 1000);
      }
    });
  }
  openImage(e){
    this.slikaZaPrikaz=[]
    this.slikaZaPrikaz.push(e.data.slika.putanja)
    this.slikaZaPrikaz.join(",")

    if(this.slikaZaPrikaz.length == 0){
      this.popupImageVisible = false;
      notify("Ne postoji slika događanja!", "info", 1000);
    }else this.popupImageVisible=true;
  }


}
